import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {

  committeeChairEmail = '';
  formLink = '';
  showCodes = false;

  constructor() {
    this.formLink = 'assets/2023/2023_hotel.pdf';
  }

  ngOnInit() {

    // check if we're AFTER Jan 5, 2025 at 7AM PST when the codes should be visible
    // 1704380400000
    if (moment().isAfter(moment(1704466800000))) {
      this.showCodes = true;
    }
  }

}
