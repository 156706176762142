<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025ParkingForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-5 col-xs-12">
      <h3 align="left">GENERAL PARKING</h3>
      <p align="left">The entrance to the Show Site in Bayfront Festival Park will be accessed by an unpaved
parking lot providing ample parking for exhibitors and spectators. No fee will be charged for daily parking in the designated
General Parking area located in the back rows of the larger Lot B. One parking space per vehicle and blocking off or saving
spaces by any means in the General Parking area is strictly forbidden and violation of this policy will be enforced.
Handicapped Parking, with a visible and appropriate handicapped parking sticker, will be available. Vehicles may not be left
in the General Parking area overnight or outside of show hours of 6:00 am - 10:00 pm. Exhibitors will have access to drive
to the grooming tent to unload before parking their vehicles.</p>
      <h3 align="left">RESERVED PARKING</h3>
      <p align="left">If you wish to have a Reserved Parking spot for your car or van for
your daily use with in/out privileges, Reserved Vehicle Parking spots (approx. 16’ wide x 18’ long) will be available in the rows
closest to the Show Site in the larger Lot B. Vehicles may not be left in the Reserved Vehicle Parking area overnight or outside
of show hours of 6:00 am - 10:00 pm. The cost for a Reserved Parking spot is $85 for the week.</p>
	  <h3 align="left">RESERVED LARGE VEHICLE PARKING</h3>
		<p>There will be a limited number of Reserved Large Vehicle
spots, (approx. 18’ wide x 36’ long) to accommodate larger vehicles, such as box trucks that are over 18’, available in the
smaller Lot B for a cost of $150 each for the week. One vehicle per spot (no secondary vehicles) and RVs are not permitted to
park in the Large Vehicle Parking area</p>
      <p><strong>NCA reserves the right to forcibly enter parked vehicles (locked or unlocked) if a dog inside is believed to be in distress.</strong></p>
<p align="left"><em>Deadline: {{ deadline }} </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
    </div>
    <div class="col-md-7 col-xs-12">
      <h2 class="mt-3 float-none">Online Parking Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-10 col-sm-12 col-xs-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="parkForm" [formGroup]="parkForm" #lf="ngForm">
              <p>Yes, I want to reserve parking for the 2025 National Specialty</p>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Arrival Day/Date" formControlName="arrival">
                  <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('arrival').hasError(validation.type) && (parkForm.get('arrival').dirty || parkForm.get('arrival').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Departure Day/Date" formControlName="departure">
                  <mat-error *ngFor="let validation of accountValidationMessages.departure">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('departure').hasError(validation.type) && (parkForm.get('departure').dirty || parkForm.get('departure').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mt-3 mb-0 d-none">
                <mat-form-field class="inputField-full-width">
                  <input matInput id="num" type="number" class=" ms-4 text-center product-quantity" value="0" min="0"
                         formControlName="num"
                         placeholder="Number of Newfoundlands entered in the show">
                </mat-form-field>
              </div>
              <div class="form-row d-none">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="buddy" placeholder="Name of Parking Buddy">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput formControlName="type" placeholder="Type of Vehicle:">
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput formControlName="length" placeholder="Length of Vehicle:">
                </mat-form-field>
              </div>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-parking">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product" data-name="ReservedSpaces" data-sku="106000">
                    <td data-th="Product">
                      <div class="row">
                        <div class="col-sm-10">
                          <h4 class="nomargin">Standard Parking Space</h4>
                          <p>18’ X 18’</p>
                        </div>
                      </div>
                    </td>
                    <td id="standardPrice" class="object-price">{{ standardCost | currency }}</td>
                    <td data-th="Quantity">
                      <input id="standardQuant" type="number" class="form-control text-center product-quantity"
                             value="0" min="0" (change)="changeQuantity()" formControlName="standardQuantity">
                    </td>
                    <td data-th="Subtotal" class="text-center product-subtotal" id="reservedSub">{{ standardTotalCost |
                      currency }}
                    </td>
                  </tr>
                  <tr class="product" data-name="LargerSpaces" data-sku="106200">
                    <td data-th="Product">
                      <div class="row">
                        <div class="col-sm-10">
                          <h4 class="nomargin">Larger Parking Space</h4>
                          <p>18’ X 36’</p>
                        </div>
                      </div>
                    </td>
                    <td id="addPrice" class="object-price">{{ largerCost | currency }}</td>
                    <td data-th="Quantity">
                      <input id="largerQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="largerQuantity">
                    </td>
                    <td data-th="Subtotal" class="text-center product-subtotal" id="addSub">{{ largerTotalCost | currency
                      }}
                    </td>
                  </tr>

                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
