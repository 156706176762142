import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-super',
  templateUrl: './show-super.component.html',
  styleUrls: ['./show-super.component.scss']
})
export class ShowSuperComponent implements OnInit {

  committeeChairEmail = 'britone@centurytel.net';
  committeeChairName = 'Steve Britton'

  constructor() { }

  ngOnInit() {
  }

}
