<div class="modal-header">
  <h2>Please wait while your order is placed</h2>
</div>
<div class="wrapper">
  <div class="full-size-spinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>
</div>
