import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, AfterViewInit {


  constructor() {
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
  }
}
