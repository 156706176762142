import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-working-dog-forum',
  templateUrl: './working-dog-forum.component.html',
  styleUrls: ['./working-dog-forum.component.scss']
})
export class WorkingDogForumComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  committeeChairEmail2 = 'newf@carolina.rr.com';
  private unsubscribe$ = new Subject<void>();
  wdForm: FormGroup;
  showSpinner: boolean;
  topicString: string;
  alreadyGotOne: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;

  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ],
    isMember: [
      { type: 'required', message: 'Please indicate if you are an NCA member or not'}
    ],
    numberInParty: [
      { type: 'min', message: 'The number in your party must be greater than 0' }
    ]
  };

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private router: Router,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.showSpinner = false;
    this.wdForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      rcName: [{value: '', disabled: false}],
      exhibitor: [{value: false, disabled: false}],
      testCommittee: [{value: false, disabled: false}],
      waterJudge: [{value: false, disabled: false}],
      draftJudge: [{value: false, disabled: false}],
      topics: [{value: '', disabled: false}],
      numberInParty: [{value: 1, disabled: false}, Validators.min(1)],
      isMember: ['', Validators.required],
      isRCMember: ['']
    });
    this.api.get('/committees/55')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  sendForm() {
    if (this.wdForm.valid) {
      this.topicString = '';
      this.updateTopicsString();
      this.alreadyGotOne = false;
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 55,
        formData: {
          fullName: this.wdForm.controls.name.value,
          fullAddress: this.wdForm.controls.address.value,
          emailAddress: this.wdForm.controls.emailAddress.value,
          regionalClub: this.wdForm.controls.rcName.value,
          topics: this.wdForm.controls.topics.value,
          ncaMember: this.wdForm.controls.isMember.value,
          regionalClubMember: this.wdForm.controls.isRCMember.value,
          numberInParty: this.wdForm.controls.numberInParty.value,
          interests: this.topicString
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  private updateTopicsString() {
    this.makeTopicStringPart('exhibitor', 'Exhibitor');
    this.makeTopicStringPart('testCommittee', 'Test Committee Member');
    this.makeTopicStringPart('waterJudge', 'NCA Water Rescue Judge');
    this.makeTopicStringPart('draftJudge', 'Draft Test Judge');
  }

  private makeTopicStringPart(controlName, areaName) {
    if (this.wdForm.controls[controlName].value) {
      if (this.alreadyGotOne) {
        this.topicString += ', ';
      }
      this.topicString += areaName;
      this.alreadyGotOne = true;
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.wdForm.reset();
      this.myForm.resetForm();
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Working Dog Forum coordinator.' +
        ' Thank you for attending.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      console.log('invalid quantity added');
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  gotoTrickDog() {
    this.router.navigateByUrl('/shop/cgc');
  }

  gotoCGC() {
    this.router.navigateByUrl('/shop/cgc');
  }

  gotoCarting() {
    this.router.navigateByUrl('/shop/carting');
  }

  gotoDraft() {
    this.router.navigateByUrl('/shop/draft');
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
