import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ShopComponent } from './shop/shop.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ShoppingModule } from './shopping/shopping.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { CartService } from './services/cart.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './services/rollbar.service';
import { ShopFooterComponent } from './shop-footer/shop-footer.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { GoogleAnalyticsEventsService } from './services/google-analytics-events.service';
import { ProductService } from './services/product.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { PagerService } from './services/pagerService';
import { VersionCheckService } from './services/versioncheck.service';
import { CommitteeService } from './services/committee.service';
import { SponsorModalComponent } from './sponsor-modal/sponsor-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShipConfirmComponent } from './ship-confirm/ship-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ShopComponent,
    LoginComponent,
    CheckoutComponent,
    ThankyouComponent,
    ErrorModalComponent,
    ShopFooterComponent,
    InfoModalComponent,
    SponsorModalComponent,
    ShipConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    ShoppingModule,
    NgxPaginationModule,
    StoreModule.forRoot(reducers),
    NgbModule
  ],
  providers: [ApiService, CartService, PagerService, VersionCheckService,
    CommitteeService, ProductService, GoogleAnalyticsEventsService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
