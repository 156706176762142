<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1 disabled" aria-disabled="true" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11">
      <h3>ACTIVITIES FOR JUNIORS</h3>
      <p>The Juniors Committee is excited to host special activities just for Juniors throughout the week. Join in with your fellow Juniors and attend as many activities as possible. An exciting schedule of activities is planned with details to follow. Get updates and news on Juniors activities and up-to-the-minute information: TEXT Juniors to 360-564-5808</p>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>
        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-8 col-sm-12">
            <form id="juniorsForm" [formGroup]="juniorsForm" #lf="ngForm">
              <p class="mt-4">Yes, I am interested in attending the Junior Activities at the 2025 National Specialty<br/>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="juniorsForm.get('name').hasError(validation.type) && (juniorsForm.get('name').dirty || juniorsForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="juniorsForm.get('address').hasError(validation.type) && (juniorsForm.get('address').dirty || juniorsForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="juniorsForm.get('emailAddress').hasError(validation.type) && (juniorsForm.get('emailAddress').dirty || juniorsForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Phone number at show" formControlName="phone">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="AKC Junior Handler #" formControlName="juniorHandlerNumber">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-12 col-sm-12">
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="tuesday" name="tuesday" formControlName="tuesday">
                    <label for="tuesday" class="form-check-label">I plan to attend on Tuesday, May 14, 2025</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="thursday" name="thursday" formControlName="thursday">
                    <label for="thursday" class="form-check-label">I plan to attend on Thursday, May 16, 2025</label>
                  </div>

                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-12 col-sm-12 text-center">
                  <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action me-5">Send Form</button>Vendor signup deadline is April 1st, 2022
                </div>
              </div>

            </form>
            <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
              Committee Chair</a>&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

