<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>International and Concierge Packages</h3>
        <p>This year we are pleased to provide our international guests, who reside outside the North American continent, 2 all-inclusive packages for the week, one for those traveling without dogs and one for those who will be bringing dogs along with them for the show. We are pleased to also offer this service for other attendees who may have difficulty navigating the online store or order forms. </p>
        <p>Our Concierge Team will fill out all the forms for our guests and make sure they are ready to enjoy the week with us.</p>
        <p>All packages will include registration, tickets to all social and educational events, reserved ringside seating in a special  seating section, and a catalog. If attending with entered dogs, a package is available with a reserved grooming space. A limited number of crates, grooming tables and dryers will be made available for use on a first come, first served basis. Online orders only. </p>
        <p><em>Pre-order Deadline: {{ deadline }}. </em></p>
        <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
          Please email the Committee Chair</a>
        </p>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card [item]="item" [showOptions]="false"></app-product-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
