<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025HotelInfo.pdf">Reservation Information</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>Hotel and Site Information</h3>
      <h5 *ngIf="!showCodes" class="mt-4 mb-3">Due to a technical issue at the reservation desk at the Pier B Hotel we will be holding the opening of hotel reservations until January 5 at 10 AM eastern, 7 pacific. We apologize for any inconvenience.</h5>
      <p align="left">LOCATION</p>
      <div class="text-center">
        <img src="assets/2025/hotelMap2025.jpg" alt="hotel map" height="640">
      </div>
      <p id="resform"><br/>
      HOTEL ROOM RESERVATIONS</p>
      <p align="left"> While we will not have a designated "host hotel" in 2025, we have secured a block of rooms with discounted room rates at 4 hotels within 3 miles of the show site, Bayfront Festival Park, located at 350 Harbor Drive, Duluth, MN. </p>

      <p>If you would like to take advantage of securing a room at one of the four hotels where the NCA has rooms blocked you may do so starting January 4th, 2025 by contacting the hotel of your choice directly.  Please note that if you call for reservations at The Raddison, The Suites or Edgewater Hotel &amp; Waterpark prior to the January 4th, 2025 date, the discounted rate will not be honored. Additionally, you will not be able to make a reservation at Pier B Resort prior to January 4th,2024 as the entire hotel has been reserved for the NCA National Specialty. Please note that the NCA’s room blocks exceed the number of rooms that were utilized in recent years.</p>
	  <div *ngIf="showCodes">
	   <p><strong>The Room Code for all facilities is: Big Dogs 2024</strong></p>
      <p>The hotels with rooms blocked for guests of the NCA National Specialty are as follows and rates are inclusive of the 14.38% tax (additional fees may apply):</p>
      <p>Pier B Resort (800 West Railroad Street) 0.5 miles from show site Room rate: $218.05 two queen or one king bed classic view or $242.26 two queen or one king bed harbor view  ($60 per dog non-refundable pet fee/2 dog limit) Reservations call (218) 481-8888</p>
	  <p>Radisson Hotel Duluth - Harborview (505 W Superior Street) 0.6 miles from show site Room rate: $193.30 two queen or one king bed ($50 per dog non-refundable pet fee/2 dog limit) Reservations call (218) 481-1185 </p>
	  <p>The Suites Hotel At Waterfront Plaza (325 S Lake Avenue) 0.5 miles from show site Room rate: $136.11 single- $279.09 suite ($40 per night pet fee) Reservations call (218) 727-4663</p>
      <p>Edgewater Hotel and Waterpark (2400 London Road) 3.0 miles from show site Room rates $160.12 two queen beds ($50 per night pet fee – not to exceed $200) Reservations call (218) 728-3601 </p>
	   </div>
      <p>Additional options for pet friendly lodging within 5 miles of the show site are available at the following hotels WITHOUT NCA room blocks. Reservations may be made directly with these hotels at any time. Please note that there are other events in the area the end of the week so rooms outside the NCA room block might be limited. Please confirm the current pet policy prior to making your reservations at any of the hotels listed below.</p>
	  <p>Hampton Inn - Canal Park (310 Canal Park Dr - 0.53 miles from show site) 218-720-3000<br>
		Sheraton Duluth (301 East Superior St - 0.93 miles from show site) 218-414-7774<br>
		Tru by Hilton - Mall Area (503 Clearwood Dr - 1.73 miles from show site) 218-722-0222<br>
		Residence Inn by Marriott (517 W Central Entrance - 2.48 miles from show site) 218-279-2885<br>
		Days Inn - Lakewalk (2211 London Rd - 2.52 miles from show site) 218-728-5141<br>
		Hampton Inn and Suites - Mall Area (1002 Mall Dr - 2.78 miles from show site) 218-481-7707<br>
		Super 8 (4100 W Superior St - 2.95 miles from show site) 218-628-2241<br>
		AmericInn by Wyndham (901 Joshua Ave - 3.11 miles from show site) 218-723-8607<br>
		Best Western Bridgeview (415 Hammond Ave, Superior, WI - 3.14 miles from show site) 715-392-8174<br>
		Days Inn and Suites - Mall Area (909 Cottonwood Ave - 3.21 miles from show site) 218-727-3110<br>
		Cobblestone Hotel and Suites Superior Duluth (1102 Tower Ave, Superior, WI -3.64 miles from site) 715-319-3050<br>
		LaQuinta Inn and Suites (1805 Maple Grove Rd - 3.68 miles from show site) 218-722-0700<br>
		Hampton Inn - Superior (66 E 2nd St, Superior, WI - 3.92 miles from show site) 715-718-2723<br>
		Econo Lodge - Miller Hill Area (4197 Haines Rd - 4.28 miles from show site) 218-722-5522<br>
	  Country Inn and Suites - North (4257 Haines Rd - 4.47 miles from show site) 218-451-4917</p>
	  <p>As Duluth is a prime vacation destination, there are a number of private rental options available via websites such as <a href="https://www.airbnb.com">www.airbnb.com</a> or <a href="https://www.vrbo.com">www.vrbo.com</a> as well. </p>
      <p align="left">THE SHOW SITE </p>
      <p align="left">&nbsp; </p>
      <p align="left">&nbsp;
        </p>
      <p align="left">AIR TRAVEL &amp; AIRPORT TRANSPORTATION</p>
    </div>
  </div>
</div>
