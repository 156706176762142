<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12">
      <h3>OPENING CEREMONIES</h3>
      <p>Tuesday afternoon’s Opening Ceremony will
begin with a parade of NCA Regional Clubs. Each Regional Club is asked to participate by providing representatives and their Newfs
to parade along the shores of Lake Superior providing a breathtaking backdrop. While carts are optional, we encourage clubs to get
creative with their representation, keeping in mind the nautical theme of Heading To The Lake. Large flags with each club’s logo will
be provided and displayed on the show grounds in tribute throughout the week. For our international guests that would like to
participate, please contact Susan Wagner  so that she can coordinate flags and poles.</p>
    </div>
  </div>
</div>
