<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2023/VintageMerchForm.pdf" download="">Download Vintage Items Order Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>SPECIALTY VINTAGE ITEMS</h3>

        <p>SPECIALTY Vintage items for sale</p>
        <p>Terms & Conditions<br>
          Every effort will be made to display accurate stock availability in the online store but stock levels are subject to change without prior notification from our vendors. Orders will be processed and shipped as quickly as possible but it should be noted that delivery is not guaranteed by any specific date.
          Please remember that we do not have the resources or infrastructure of Am*zon or other retail businesses. We will do our best as a volunteers, to get you your merchandise as quickly as possible. We deeply appreciate your patience as we navigate the challenges that COVID, the holiday season and weather-related interruptions may cause. These continue to be unprecedented times and we ask for your understanding and support when placing an order.<br>
          Susan Wagner<br>
          Chair – 2023 NCA National Specialty & Logo Merchandise Coordinator
        </p>
        <p><strong>All items ordered may either be picked up at the National Specialty show site upon arrival in Frankenmuth free of shipping/handling fees or they are available to be shipped to you for a flat rate. The last date to purchase logo merchandise for delivery prior to/at the National Specialty is April 1, 2022. Please note that there will be limited styles and sizes available for sale at the show site during the week so pre-order is the way to get the items you want!</strong></p>
        <p><em>Pre-order Deadline: {{ deadline }}. </em></p>
        <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
          Please email the Committee Chair</a>
        </p>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #modalImageBrowser let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div style="flex-direction: row;display: inline-flex;width: 100%;">
      <h2 class="modal-title">Product Image Browser</h2>
      <button type="submit" (click)="d('Close click')" class="close"><span>X</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of images">
          <div class="picsum-img-wrapper text-center">
            <img [src]="image" alt="Random slide">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="d('Close click')" class="btn btn-round-outline">Close</button>
    </div>
  </div>
</ng-template>
