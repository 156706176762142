<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025GroomingForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-5 col-xs-12">
      <h3>RESERVED GROOMING</h3>
      <p align="left">A limited number of reserved grooming spaces will be available on the grass,
inside a tent (with sides) located adjacent to the conformation ring. Each assigned 5' wide x 10' deep spot will include
enough room for two crates and one grooming table and includes access to electrical power. (Some sharing of electrical
power may be necessary.) The cost is $90 per week for each space. ONE additional adjacent crate space (approximately
30"x 10'), not including electrical access, may be reserved for $30 for the week. Please note high intensity lights,
generators, mats, or canopies are not permitted inside the grooming tent area.</p>
      <p align="left">GROOMING PERMITTED ONLY IN DESIGNATED AREAS.<br>
      When reserving grooming space, we encourage you to plan ahead and “buddy-up” with friends. By sharing with friends and
coordinating the use of the reserved space and electrical resources during the show, you will make it possible for more people
		  to reserve space to groom and conserve resources at the same time.</p>
     <p><strong> PLEASE: If mailing your reservation, all forms requesting adjacent spaces next to other exhibitors, MUST be mailed in the same envelope. If using the online store for your reservation, use the special pull-down box to note your “next to” request. Please make your plans with others well in advance of mailing or transmitting your forms. The show committee does not guarantee requests when forms are mailed separately or not noted on the online store drop down.</strong></p>
      <p align="left">Other non-reserved grooming space, without electricity, will be available on a first come - first served basis. Please note that
privately owned generators are not permitted inside Bayfront Festival Park.</p>
      <p align="left">Please note that dogs cannot be left in the grooming tent overnight. Crate tags for emergency contact information for each grooming space will be issued and must be attached to a crate and visible at all times.</p>
      <p align="left">The first postmark date accepted for reserved grooming space is February 29th if mailing or March 1st online. If using an expedited delivery service, please waive the signature.
      </p>
      <p><em>Deadline: reservations &amp; cancellations – {{ deadline }}.</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
    </div>
    <div class="col-md-7 col-xs-12">
      <h2 class="mt-3 float-none">Online Grooming Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="groomForm" [formGroup]="groomForm" #lf="ngForm">
              <p>Yes, I want to reserve grooming space for the 2025 National Specialty</p>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Please place me next to" formControlName="nextTo">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Days the space is needed (Mon - Sat)" formControlName="days">
                </mat-form-field>
              </div>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product" data-name="ReservedSpaces" data-sku="106000">
                    <td data-th="Product">
                      <div class="row">
                        <div class="col-sm-10">
                          <h4 class="nomargin">Outdoor Space</h4>
                          <p>5' (across) x 10' (deep) space with electrical access; room for 1 table &amp; no more than 2 crates</p>
                        </div>
                      </div>
                    </td>
                    <td id="reservedPrice" class="object-price">{{ reservedCost | currency }}</td>
                    <td data-th="Quantity">
                      <input id="reservedQuant" type="number" class="form-control text-center product-quantity"
                             value="0" min="0" (change)="changeQuantity()" formControlName="resQuantity">
                    </td>
                    <td data-th="Subtotal" class="text-center product-subtotal" id="reservedSub">{{ reservedTotalCost | currency }}
                    </td>
                  </tr>
                  <tr *ngIf="!canBuyAdditional">
                    <td colspan="4">
                      <p class="text-danger" *ngIf="!canBuyAdditional">Additional space cannot be purchased unless purchased along with a reserved space at the same time.</p>
                    </td>
                  </tr>
                  <tr class="product" data-name="AddSpaces" data-sku="106200">
                    <td data-th="Product">
                      <div class="row">
                        <div class="col-sm-10">
                          <h4 class="nomargin">Additional Outdoor Space</h4>
                          <p>30" (across) x 10' (deep) space with no additional electrical connection - (1 PER PERSON - Limited availability)</p>
                        </div>
                      </div>
                    </td>
                    <td id="addPrice" class="object-price">{{ additionalCost | currency }}</td>
                    <td data-th="Quantity">
                      <input id="addQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" max="1" (change)="changeQuantity()" formControlName="addQuantity">
                    </td>
                    <td data-th="Subtotal" class="text-center product-subtotal" id="addSub">{{ additionalTotalCost | currency }}
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10">
      <h3>DOG BATHING</h3>
      <p>Washing stations with warm water will be set up in Bayfront Festival Park and available for use beginning
Sunday, May 12th after 3:00 p.m. Please note that no bathing or grooming of dogs is permitted in any hotel room.</p>
    </div>
  </div>
</div>
