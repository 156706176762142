import { CustomerState } from '../models/customer';
import * as customerReducer from '../reducers/customer';
import * as cartReducer from '../reducers/cart';
import { CartState } from '../models/cart';
import { getCart } from './cart';

export interface AppState {
  customerState: CustomerState;
  cartState: CartState;
}
export const reducers = {
  customerState: customerReducer.reducer,
  cartState: cartReducer.reducer
};
