<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025LovettForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-5 col-sm-12">
      <h3>Mike &amp; Sandee Lovett Memorial Working Challenge
      </h3>
      <p>he Great Lakes Newfoundland Club is again offering a special Lovett Memorial Working Dog Award Award   to honor the legacy of Sandee and Mike Lovett and their commitment to people working with their dogs. To earn this special award, you and your dog must enter and qualify in three working events offered at the 2025 National Specialty - Draft, Specialty Carting, Obedience or Rally. You may enter your Newf in any class that they are eligible for from novice to advanced! You may also register multiple dogs to participate in this Working Challenge.</p>
      <p align="left">If you would like to be eligible to receive this special award, you must enter a draft test (s) and/or Off-Lead Specialty Carting, an obedience and rally trial (s) as you normally would AND complete the registration form below. The Registration Deadline: April 24, 2025. Contact: Jenny Zablotny.</p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
    </div>
    <div class="col-md-7">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="modal-body">
            <form id="meetingForm" [formGroup]="lovettForm" #lf="ngForm">
              <h4>Yes, I want to enter the Mike &amp; Sandee Lovett Memorial Working Challenge at the 2025 National Specialty</h4>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="lovettForm.get('name').hasError(validation.type) && (lovettForm.get('name').dirty || lovettForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="lovettForm.get('address').hasError(validation.type) && (lovettForm.get('address').dirty || lovettForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="lovettForm.get('emailAddress').hasError(validation.type) && (lovettForm.get('emailAddress').dirty || lovettForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Phone Number" formControlName="phone">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Dog's Registered Name" formControlName="regName">
                    <mat-error *ngFor="let validation of accountValidationMessages.regName">
                      <mat-error class="error-message"
                                 *ngIf="lovettForm.get('regName').hasError(validation.type) && (lovettForm.get('regName').dirty || lovettForm.get('regName').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Draft Test Class and Day" formControlName="draft">
                    <mat-error *ngFor="let validation of accountValidationMessages.draft">
                      <mat-error class="error-message"
                                 *ngIf="lovettForm.get('draft').hasError(validation.type) && (lovettForm.get('draft').dirty || lovettForm.get('draft').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Rally Class and Day" formControlName="rally">
                    <mat-error *ngFor="let validation of accountValidationMessages.rally">
                      <mat-error class="error-message"
                                 *ngIf="lovettForm.get('rally').hasError(validation.type) && (lovettForm.get('rally').dirty || lovettForm.get('rally').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Obedience Class and Day" formControlName="obedience">
                    <mat-error *ngFor="let validation of accountValidationMessages.obedience">
                      <mat-error class="error-message"
                                 *ngIf="lovettForm.get('obedience').hasError(validation.type) && (lovettForm.get('obedience').dirty || lovettForm.get('obedience').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action me-5">Send Form</button>
                  Signup deadline is {{ deadline }}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
