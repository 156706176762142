import { Injectable, NgZone } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ProductService {

  constructor( private apiService: ApiService,
               private ngZone: NgZone) {
  }

  getItemPrice(product, skuVariant, isMember) {
    let variantExtra = 0.0;
    let memberDiscount = 0.0;
    // get the current base price
    let basePrice = 0.0;

    const curtime = new Date().getTime();
    product.base_prices.forEach((aPrice) => {
      if (((aPrice.endDate === null) || (aPrice.endDate > curtime)) && (curtime >= aPrice.startDate)) {
        basePrice = parseFloat(aPrice.basePrice);
      }
    });
    const foundVariant = this.getVariant(product.variants, skuVariant);
    foundVariant.cost_variants.forEach((aCostVariant) => {
      if (((aCostVariant.endDate === null) || (aCostVariant.endDate > curtime)) && (curtime >= aCostVariant.startDate)) {
        variantExtra = parseFloat(aCostVariant.costVariant);
      }
    });
    foundVariant.member_cost_variants.forEach((aMemberCostVariant) => {
      if (((aMemberCostVariant.endDate === null) || (aMemberCostVariant.endDate > curtime))
        && (curtime >= aMemberCostVariant.startDate)) {
        if (isMember) {
          memberDiscount = parseFloat(aMemberCostVariant.memberVariant);
        }
      }
    });
    return basePrice + variantExtra + memberDiscount;
  }

  wasMemberDiscountApplied(product, skuVariant, isMember) {
    let memberDiscountApplied = false;
    const curtime = new Date().getTime();
    const foundVariant = this.getVariant(product.variants, skuVariant);
    foundVariant.member_cost_variants.forEach((aMemberCostVariant) => {
      if (((aMemberCostVariant.endDate === null) || (aMemberCostVariant.endDate > curtime))
        && (curtime >= aMemberCostVariant.startDate)) {
        if (isMember) {
          memberDiscountApplied = true;
        }
      }
    });
    return memberDiscountApplied;
  }
  isMemberDiscountAvailable(product, skuVariant) {
    let memberDiscountAvailable = false;
    const curtime = new Date().getTime();
    const foundVariant = this.getVariant(product.variants, skuVariant);
    foundVariant.member_cost_variants.forEach((aMemberCostVariant) => {
      if (((aMemberCostVariant.endDate === null) || (aMemberCostVariant.endDate > curtime))
        && (curtime >= aMemberCostVariant.startDate)) {
        memberDiscountAvailable = true;
      }
    });
    return memberDiscountAvailable;
  }

  getVariant(variantArray, skuVariant) {
    let currentVariant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
    variantArray.forEach((variant) => {
      if (variant.skuVariant === skuVariant) {
        currentVariant = variant;
      }
    });
    return currentVariant;
  }
}
