<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-12 me-auto">
      <p class="quick_links">Quick Links:</p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
    <header>
        <h3>SPECIALTY LOGO ITEMS</h3>

        <p>SPECIALTY LOGO ITEMS . . . This year we are pleased to offer a variety of products  in multiple colors featuring the 2025 National Specialty Logo! Please note that colors and sizes are subject to availability which could possibly delay shipping. Every effort will be made to fulfill orders in a timely manner.  </p>
        <p>All orders will be processed and shipped by the vendor. There will not be any on-site pick-up available. All merchandise orders will be shipped. International shipping is available. </p>
        <p><strong> Please note that there will be limited edition merchandise available for sale at the show site during the week featuring the National, NCA and Top 20-10 logos. Those items are NOT available through the online store and will only be available to Specialty attendees</strong>.</p>
        <p>Terms and Conditions<br>
Every effort will be made to display accurate stock availability in the online store but stock levels are subject to change without prior notification from our vendors. Orders will be processed and shipped as quickly as possible but it should be noted that delivery is not guaranteed by any specific date.
We will do our best as volunteers, to get you your merchandise as quickly as possible. We deeply appreciate your patience. </p>

		<p></p>
      <p>Questions? <a class="btn quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
          </div>
          <div *ngIf="noProductsAvailable">
            <p>No products available for sale at this time</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalImageBrowser let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div style="flex-direction: row;display: inline-flex;width: 100%;">
      <h2 class="modal-title">Product Image Browser</h2>
      <button type="submit" (click)="d('Close click')" class="close"><span>X</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of images">
          <div class="picsum-img-wrapper text-center">
            <img [src]="image" alt="Random slide">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="d('Close click')" class="btn btn-round-outline">Close</button>
    </div>
  </div>
</ng-template>
