<div class="modal-header">
  <h2>Customer Shipping Information</h2>
</div>
<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <div class="modal-body">
      <form id="initMemberRegForm" [formGroup]="addForm">
        <ng-container>
          <h4>Please confirm your shipping address is correct, or correct it here</h4>
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="form-floating">
                <input type="text" class="form-control" id="first" name="first" formControlName="first" placeholder="First Name">
                <label for="first">First Name</label>
                <mat-error *ngFor="let validation of accountValidationMessages.first">
                  <mat-error class="mat-error error-message"
                             *ngIf="addForm.get('first').hasError(validation.type) && (addForm.get('first').dirty || addForm.get('first').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <input type="text" class="form-control" id="last" name="last" formControlName="last" placeholder="Last Name">
                <label for="last">Last Name</label>
                <mat-error *ngFor="let validation of accountValidationMessages.last">
                  <mat-error class="mat-error error-message"
                             *ngIf="addForm.get('last').hasError(validation.type) && (addForm.get('last').dirty || addForm.get('last').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="form-floating">
                <input type="text" class="form-control" id="address" name="address" formControlName="address" placeholder="Your shipping address">
                <label for="address">Your shipping address</label>
                <mat-error *ngFor="let validation of accountValidationMessages.address">
                  <mat-error class="mat-error error-message"
                             *ngIf="addForm.get('address').hasError(validation.type) && (addForm.get('address').dirty || addForm.get('address').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="form-floating">
                <input type="text" class="form-control" id="city" name="city" formControlName="city" placeholder="City">
                <label for="city">City</label>
                <mat-error *ngFor="let validation of accountValidationMessages.city">
                  <mat-error class="mat-error error-message"
                             *ngIf="addForm.get('city').hasError(validation.type) && (addForm.get('city').dirty || addForm.get('city').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input type="text" class="form-control" id="state" name="state" formControlName="state" placeholder="State">
                <label for="state">State</label>
                <mat-error *ngFor="let validation of accountValidationMessages.state">
                  <mat-error class="mat-error error-message"
                             *ngIf="addForm.get('state').hasError(validation.type) && (addForm.get('state').dirty || addForm.get('state').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating">
                <input type="text" class="form-control" id="zip" name="zip" formControlName="zip" placeholder="Zipcode/Postal Code">
                <label for="zip">Zipcode/Postal Code</label>
                <mat-error *ngFor="let validation of accountValidationMessages.zip">
                  <mat-error class="mat-error error-message"
                             *ngIf="addForm.get('zip').hasError(validation.type) && (addForm.get('zip').dirty || addForm.get('zip').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="form-floating">
            <select class="form-select" name="country" id="country" formControlName="country">
              <option *ngFor="let country of countryNames" [ngValue]="country">{{country}}</option>
            </select>
            <label for="country">Country</label>
          </div>
          <button type="button" class="btn btn-info quick_links_action mt-2" id="memberConfirm" (click)="confirmAddressAction()" [disabled]="!addForm.valid">Confirm</button>
        </ng-container>
        <hr>
      </form>
    </div>
  </div>
</div>
