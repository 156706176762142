<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025CGCForm.pdf" download="">Download CGC And Trick Dog Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 col-xs-12">
      <h3>CANINE GOOD CITIZEN TESTING</h3>
      <p>The AKC Canine Good Citizen evaluations will be available at the NCA National Specialty on Friday, May 17th starting at 10 a.m. The CGC title certifies that dogs have the training, manners and behaviors to be reliable and well-behaved members of families and communities. It can also be a gateway toward Therapy Dog work.</p>
      <p><em>The pre-registration Deadline: {{ cgcDeadline }}.  </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <h3>TRICK DOG TITLE TESTING</h3>
      <p>Do you and your dog love to be the life of the party by showing off their arsenal of tricks? Then your Newf might be perfectly primed to earn AKC Trick Dog titles! Since the 1920s when trick dogs such as Rin Tin Tin and Lassie won people's hearts, trick dog training has become one of the most exciting new areas in dog training today! </p>
      <p><em>The pre-registration Deadline: {{ tdDeadline }}.  </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <h2 class="mt-3 float-none">Online CGC and Trick Dog Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner || showSpinner2">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner || showSpinner2">
          <div class="col-md-12 col-sm-12">
            <form id="cgcForm" [formGroup]="cgcForm" #lf="ngForm">
              <p>Yes, I want to enter dog(s) in the CGC and Trick Dog Tests at the 2022 National Specialty<br/>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Canine Good Citizen Test</p>
                        </div>
                      </div>
                    </td>
                    <td id="cgcPrice" class="object-price">{{ cgcCost | currency }}</td>
                    <td>
                      <input id="cgcQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="cgcQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ cgcTotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Canine Good Citizen Test - Advanced</p>
                        </div>
                      </div>
                    </td>
                    <td id="cgcaPrice" class="object-price">{{ cgcCost | currency }}</td>
                    <td>
                      <input id="cgcaQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="cgcaQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ cgcaTotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td style="border-bottom: none;">
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Trick Dog Test</p>
                        </div>
                      </div>
                    </td>
                    <td style="border-bottom: none;" id="tdPrice" class="object-price">{{ tdCost | currency }}</td>
                    <td style="border-bottom: none;">
                      <input id="tdQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="tdQuantity">
                    </td>
                    <td style="border-bottom: none;" class="text-center product-subtotal">{{ tdTotalCost | currency }}</td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <mat-form-field class="inputField-full-width">
                        <mat-label>Select Trick Dog Test Level</mat-label>
                        <mat-select formControlName="levelType" placeholder="Testing Level" (selectionChange)="changeLevel($event)">
                          <mat-option *ngFor="let levelType of levelTypes" value="{{ levelType }}">{{ levelType }}</mat-option>
                        </mat-select>
                        <mat-error *ngFor="let validation of accountValidationMessages.levelType">
                          <mat-error class="error-message" *ngIf="cgcForm.get('levelType').hasError(validation.type) && (cgcForm.get('levelType').dirty || cgcForm.get('levelType').touched)">{{validation.message}}</mat-error>
                        </mat-error>
                      </mat-form-field>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
