import {Action} from '@ngrx/store';
import {Customer, CustomerState} from '../models/customer';

export const LOGIN = 'Login';
export const LOGOUT = 'Logout';
export const SETAUTH = 'SetAuth';
export const SETTOKEN = 'SetToken';
export const UPDATECUSTOMER = 'UpdateCustomer';

export class LoginCustomerAction implements Action {
  readonly type = LOGIN;
  constructor(public payload: CustomerState) {}
}

export class SetAuthenticatedAction implements Action {
  readonly type = SETAUTH;
  constructor(public payload: boolean) {}
}

export class SetTokenAction implements Action {
  readonly type = SETTOKEN;
  constructor(public payload: string) {}
}

export class UpdateCustomerAction implements Action {
  readonly type = UPDATECUSTOMER;
  constructor(public payload: Customer) {}
}

export class LogoutCustomerAction implements Action {
  readonly type = LOGOUT;
}

export type All = LoginCustomerAction | LogoutCustomerAction | SetAuthenticatedAction | SetTokenAction | UpdateCustomerAction;
