<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/VIPForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6 table-responsive">
      <h3>
        VIP Packages</h3>
      <h5>Dive into Unforgettable Moments: Become a VIP Sponsor of the Newfoundland National Specialty on Lake Superior!</h5>
      <p>Take advantage of our one-step signup for the most popular reserved items! Become a VIP Sponsor of the Newfoundland National Specialty and position yourself at the heart of a prestigious event that celebrates the regality and beauty of the Newfoundland dog and the thrill of competition.</p>
      <p>Make all your most needed reservations with one click!</p>
      <p>All VIP packages come with your name included on a sponsor banner &amp; recognition at the banquet</p>
      <table class="table table-bordered vip-table">
        <tr>
          <th>Included Products</th>
          <th>Bronze</th>
          <th>Silver</th>
          <th>Gold</th>
        </tr>
        <tr>
          <td>Advanced Hotel Reservations</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Registrations</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>VIP Reserved Parking</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Reserved Seating Chairs</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>2-Seat Golf Cart</td>
          <td></td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Catalogs</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>VIP Table Banquet Seats</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Raffle Tickets</td>
          <td>50</td>
          <td>100</td>
          <td>100</td>
        </tr>
        <tr>
          <td>Top 20/10 Reserved Seating & Catalog</td>
          <td></td>
          <td>1</td>
          <td>2</td>
        </tr>
      </table>
      <p><em>Deadline: {{ deadline }}.</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
    </div>
    <div class="col-md-6">
      <h2 class="mt-3 float-none">Online VIP Package Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12">
            <form id="vipForm" [formGroup]="vipForm" #lf="ngForm">
              <p>Yes, I want to order VIP Packages from the 2025 National Specialty</p>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Bronze Level Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="vipbrice" class="object-price">{{ vipbCost | currency }}</td>
                    <td>
                      <input id="vipbQuantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="vipbQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ vipbTotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Silver Level Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="vipsPrice" class="object-price">{{ vipsCost | currency }}</td>
                    <td>
                      <input id="vipsQuantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="vipsQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ vipsTotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Gold Level Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="vipgPrice" class="object-price">{{ vipgCost | currency }}</td>
                    <td>
                      <input id="vipgQuantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="vipgQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ vipgTotalCost | currency }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
