import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meals',
  templateUrl: './meals.component.html',
  styleUrls: ['./meals.component.scss']
})
export class MealsComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  @ViewChild('lf2', {static: true}) myForm2: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  mealForm: FormGroup;
  movieForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  orderTotal = 0;
  basePrice = 0;
  mealVariants = [];
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  mealsListArray: FormArray;
  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    isMember: [
      { type: 'required', message: 'Please indicate if you are an NCA member or not'}
    ],
    numberInParty: [
      { type: 'min', message: 'The number in your party must be greater than 0' }
    ]
  };
  private infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private router: Router,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = true;
    this.mealForm = this.fb.group({
      meals: this.fb.array([])
    });
    this.mealsListArray = this.mealForm.get('meals') as FormArray;
    this.movieForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      numberInParty: [{value: 1, disabled: false}, Validators.min(1)],
      isMember: [{value: '', disabled: false}, Validators.required]
    });
    this.api.get('/products?category=12&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.productInfo = res.products[0];
                if (this.productInfo.hasOwnProperty('id')) {
                  this.intItemId = this.productInfo.id;
                }
                if (this.productInfo.hasOwnProperty('name')) {
                  this.intItemName = this.productInfo.name;
                }
                if (this.productInfo.hasOwnProperty('variants')) {
                  this.mealVariants = this.productInfo.variants;
                }
                if (this.productInfo.hasOwnProperty('deadline')) {
                  this.deadlineTime = this.productInfo.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (this.productInfo.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = this.productInfo.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.productInfo.hasOwnProperty('soldOut')) {
                  if (this.productInfo.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
              }
              this.mealVariants.forEach((aMeal) => {
                const formGroup: FormGroup = this.fb.group({
                  quantity: this.fb.control(0)
                });
                aMeal.price = this.productService.getItemPrice(this.productInfo, aMeal.skuVariant, this.cartService.isMember);
                aMeal.subTotal = 0;
                this.mealsListArray.push(formGroup);

              });
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of meal products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/27')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity(index) {
    const mealQuant = this.mealsListArray.controls[index].value;
    const theVariant = this.mealVariants[index];
    theVariant.subTotal = mealQuant.quantity * theVariant.price;

    this.orderTotal = 0;
    let loopIndex = 0;
    this.mealsListArray.controls.forEach((aCtl) => {
      const aVariant = this.mealVariants[loopIndex];
      this.orderTotal += aCtl.value.quantity * aVariant.price;
      loopIndex += 1;
    });
  }

  addToCart() {
    if (this.orderTotal === 0) {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the meals you' +
          ' would like and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    let loopIndex = 0;
    this.mealsListArray.controls.forEach((aCtl) => {
      if (aCtl.value.quantity > 0) {
        const aVariant = this.mealVariants[loopIndex];
        const dPoints = {};
        const firstReg: CartItem = {
          productId: this.intItemId, productVarId: aVariant.id,
          quantity: aCtl.value.quantity, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      loopIndex += 1;
    });

    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.mealForm.reset();
      this.myForm.resetForm();
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sendForm() {
    if (this.movieForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 29,
        formData: {
          fullName: this.movieForm.controls.name.value,
          emailAddress: this.movieForm.controls.emailAddress.value,
          ncaMember: this.movieForm.controls.isMember.value,
          numberInParty: this.movieForm.controls.numberInParty.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'You have not filled out the form completely.  Please try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.movieForm.reset();
      this.myForm2.resetForm();
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Movie Night coordinator.' +
        '  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  gotoTop20Page() {
    this.router.navigateByUrl('/shop/top20')
  }
}
