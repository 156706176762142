import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Dog } from '../../models/dog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { FileHolder } from '../../image-upload/image-upload.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { LookupDogComponent } from '../../lookup-dog/lookup-dog.component';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-carting',
  templateUrl: './carting.component.html',
  styleUrls: ['./carting.component.scss']
})
export class CartingComponent implements OnInit, OnDestroy {

  @ViewChild('lookupdog', {static: true}) private lookupDogElement: LookupDogComponent;
  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  theDogSelected = false;
  dogToUse: Dog;
  cartForm: FormGroup;

  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  singleOnLeadPrice = 0.0;
  singleOffLeadPrice = 0.0;
  teamOnLeadPrice = 0.0;
  tTeamOffLeadPrice = 0.0;
  testTypes = [];
  intItemName = '';
  intItemId = -1;
  itemVariants = [];
  isPickingUp = true;
  orderTotal = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  accountValidationMessages = {
    emailAddress: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Enter a valid email'}
    ],
    phone: [
      {type: 'required', message: 'Your phone number is required'}
    ],
    address: [
      {type: 'required', message: 'Your address is required'}
    ],
    testType: [
      {type: 'required', message: 'Please select a test type'}
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.dogToUse = null;
    this.cartForm = this.fb.group({
      phone: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      handler: [''],
      testType: [-1, Validators.required]
    });
    this.api.get('/products?category=17&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.productInfo = res.products[0];
                if (this.productInfo.hasOwnProperty('id')) {
                  this.intItemId = this.productInfo.id;
                }
                if (this.productInfo.hasOwnProperty('name')) {
                  this.intItemName = this.productInfo.name;
                }
                if (this.productInfo.hasOwnProperty('variants')) {
                  this.testTypes = this.productInfo.variants;
                }

                if (this.productInfo.hasOwnProperty('deadline')) {
                  this.deadlineTime = this.productInfo.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (this.productInfo.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = this.productInfo.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.productInfo.hasOwnProperty('soldOut')) {
                  if (this.productInfo.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
                this.singleOnLeadPrice = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                this.singleOffLeadPrice = this.productService.getItemPrice(this.productInfo, 200, this.cartService.isMember);
                this.teamOnLeadPrice = this.productService.getItemPrice(this.productInfo, 400, this.cartService.isMember);
                this.tTeamOffLeadPrice = this.productService.getItemPrice(this.productInfo, 600, this.cartService.isMember);
              }
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of specialty carting products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/41')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
          if (!this.myProductIsChanging) {
            this.theDogSelected = false;
            this.dogToUse = null;
            this.lookupDogElement.showHide(true);
          }
        });
      });
  }

  selectedDog(dogEvent) {
    this.theDogSelected = true;
    this.dogToUse = dogEvent;
    this.lookupDogElement.showHide(false);
  }

  getRegisteredName() {
    if ((this.dogToUse !== null) && (this.dogToUse.registration.length > 0)) {
      return this.dogToUse.registration[0].registered_name;
    } else {
      return 'No Dog Selected';
    }
  }

  addToCart() {
    if ((!this.cartForm.valid) || (this.cartForm.controls.testType.value === -1)) {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please complete the form and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    // get all grooming spaces
    const dPoints = {
      dogInfo: this.dogToUse,
      phone: this.cartForm.controls.phone.value,
      emailAddress: this.cartForm.controls.emailAddress.value,
      handler: this.cartForm.controls.handler.value,
      displayName: 'Dog: ' + this.getRegisteredName()
    };
    const firstReg: CartItem = {
      productId: this.intItemId,
      productVarId: this.cartForm.controls.testType.value,
      quantity: 1,
      dataPoints: JSON.stringify(dPoints)
    };
    itemArray.push(firstReg);
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.cartForm.reset();
      this.myForm.resetForm();
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
