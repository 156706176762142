import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CartService } from '../services/cart.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GoogleAnalyticsEventsService } from '../services/google-analytics-events.service';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() triggerImageBrowserEvent = new EventEmitter();
  @Input() showOptions = true;
  private unsubscribe$ = new Subject<void>();
  intItem: any;
  hasVariants: boolean;
  hasOptions: boolean;
  variantsArray: any[];
  colorArray: any[];
  sizeArray: string[];
  imageArray: any[];
  currentColor: number;
  currentSize: number;
  prodQuantity: string;
  infoModalRef: NgbModalRef;
  optionsArray: any[];
  currentOption: number;
  addingSpinner: boolean;
  myProductIsChanging: boolean;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  variantNotAvailable = true;
  soldOut = false;
  isNew = false;
  isClearance = false;
  maxQty = -1;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  @ViewChild('colorSelector', {static: false})
  private inputColorElement: ElementRef;
  @ViewChild('sizeSelector', {static: false})
  private inputSizeElement: ElementRef;
  memberDiscountAvailable = false;
  memberDiscountApplied = false;
  isMember = false;
  localCart: any;
  hasMultipleImages = false;

  constructor(private modalService: NgbModal,
              private ngZone: NgZone,
              private cartService: CartService,
              private googleService: GoogleAnalyticsEventsService) {
    this.hasVariants = false;
    this.hasOptions = false;
    this.colorArray = [];
    this.sizeArray = [];
    this.imageArray = [];
    this.optionsArray = [];
    this.currentColor = 0;
    this.currentSize = 0;
    this.currentOption = 0;
    this.prodQuantity = '';
    this.addingSpinner = false;
    this.myProductIsChanging = false;
    this.memberDiscountAvailable = false;
    this.memberDiscountApplied = false;
    this.isMember = false;
    this.hasMultipleImages = this.imageArray.length > 1;
  }

  @Input()
  set item(inItem) {
    this.intItem = inItem;
    this.isNew = this.intItem.isNew;
    this.isClearance = this.intItem.clearance;
    this.variantsArray = this.intItem.variants;
    this.hasVariants = this.variantsArray.length > 0;
    this.imageArray = this.intItem.images;
    this.hasMultipleImages = this.imageArray.length > 1;
    this.variantsArray.forEach((variant) => {
      if (variant.color !== null) {
        this.colorArray[variant.color.name] = variant.color.id;
      }
      if (variant.size !== null) {
        this.sizeArray[variant.size.name] = variant.size.id;
      }
      // check for the special case of the Options array
      if ((variant.color === null) && (variant.size === null)) {
        this.hasOptions = true;
        this.optionsArray[variant.skuVariant] = variant;
        if (this.currentOption === 0) {
          this.currentOption = variant.skuVariant;
        }
      }
    });
    if (Object.keys(this.colorArray).length === 0) {
      this.colorArray['No Choices'] = -1;
    }
    if (Object.keys(this.sizeArray).length === 0) {
      this.sizeArray['One Size'] = -1;
    }
    if (this.colorArray !== null) {
      this.currentColor = this.colorArray[Object.keys(this.colorArray)[0]];
    } else {
      this.currentColor = 0;
    }
    if (this.sizeArray !== null) {
      this.currentSize = this.sizeArray[Object.keys(this.sizeArray)[0]];
    } else {
      this.currentSize = 0;
    }
    if (this.intItem.hasOwnProperty('deadline')) {
      this.deadlineTime = this.intItem.deadline;
      this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
    }
    if (this.intItem.hasOwnProperty('availabilityDate')) {
      this.availabilityDateTime = this.intItem.availabilityDate;
      this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
    }
    // now check availability
    if (moment().isBefore(moment(this.availabilityDateTime))) {
      // the product is not available yet
      // check if the environment allows early ordering - testing
      if (environment.testProductMode) {
        this.productNotAvailable = false;
      } else {
        this.comingSoon = true;
      }
    } else if (moment().isAfter(moment(this.deadlineTime))) {
      // the product is no longer available
      this.comingSoon = false;
    } else if (this.intItem.hasOwnProperty('soldOut')) {
      if (this.intItem.soldOut) {
        this.soldOut = true;
      } else {
        this.productNotAvailable = false;
      }
    } else {
      this.productNotAvailable = false;
    }
    // last step, check if the current VARIANT is sold out
    // only check variants if the product is still available
    if (!this.productNotAvailable) {
      this.findAvailableProduct();
    }
  }
  get item() {
    return this.intItem;
  }
  getIconToDisplay() {
    // find image to show
    const variantsWithImages = this.variantsArray.filter(
      (vari) => vari.color !== null && vari.color.id === this.currentColor && vari.images.length > 0);
    if (variantsWithImages.length > 0) {
      return variantsWithImages[0].images[0].path;
    }
    if (this.imageArray.length > 0) {
      return this.imageArray[0].path;
    } else {
      return '';
    }
  }

  getItemPrice() {
    let variantExtra = 0.0;
    let memberDiscount = 0.0;
    // get the current base price
    let basePrice = 0.0;
    this.memberDiscountApplied = false;
    this.memberDiscountAvailable = false;

    const curtime = new Date().getTime();
    this.intItem.base_prices.forEach((aPrice) => {
      if (((aPrice.endDate === null) || (aPrice.endDate > curtime)) && (curtime >= aPrice.startDate)) {
        basePrice = parseFloat(aPrice.basePrice);
      }
    });

    // get variant cost
    const foundVariant = this.getVariant(this.currentColor, this.currentSize);
    foundVariant.cost_variants.forEach((aCostVariant) => {
      if (((aCostVariant.endDate === null) || (aCostVariant.endDate > curtime)) && (curtime >= aCostVariant.startDate)) {
        variantExtra = parseFloat(aCostVariant.costVariant);
      }
    });

    foundVariant.member_cost_variants.forEach((aMemberCostVariant) => {
      if (((aMemberCostVariant.endDate === null) || (aMemberCostVariant.endDate > curtime)) && (curtime >= aMemberCostVariant.startDate)) {
        this.memberDiscountAvailable = true;
        if (this.isMember) {
          memberDiscount = parseFloat(aMemberCostVariant.memberVariant);
          this.memberDiscountApplied = true;
        }
      }
    });

    return basePrice + variantExtra + memberDiscount;
  }

  getThresholdText() {
    const foundVariant = this.getVariant(this.currentColor, this.currentSize);
    if ((foundVariant.inventory > 0) && (this.intItem.limitedThreshold > 0)) {
      this.maxQty = foundVariant.inventory > 0 ? foundVariant.inventory : -1;
      if (foundVariant.inventory < this.intItem.limitedThreshold ) {
        return 'Only ' + foundVariant.inventory + ' left';
      }
    } else {
      return '';
    }
  }
  changeColor(ev) {
    this.currentColor = parseInt(ev.srcElement.value, 10);
    if (!this.productNotAvailable) {
      const foundVariant = this.getVariant(this.currentColor, this.currentSize);
      if (foundVariant.soldOut) {
        this.variantNotAvailable = true;
      } else {
        this.variantNotAvailable = false;
      }
    }
  }

  changeSize(ev) {
    this.currentSize = parseInt(ev.srcElement.value, 10);
    if (!this.productNotAvailable) {
      const foundVariant = this.getVariant(this.currentColor, this.currentSize);
      if (foundVariant.soldOut) {
        this.variantNotAvailable = true;
      } else {
        this.variantNotAvailable = false;
      }
    }
  }

  changeOption(ev) {
    this.currentOption = parseInt(ev.srcElement.value, 10);
    if (!this.productNotAvailable) {
      const foundVariant = this.getVariant(this.currentColor, this.currentSize);
      if (foundVariant.soldOut) {
        this.productNotAvailable = true;
      } else {
        this.productNotAvailable = false;
      }
    }
  }

  addToCart(item) {
    let prodVarId = -1;
    const selectedQty = (isNaN(parseInt(this.prodQuantity, 10))) ? 0 : parseInt(this.prodQuantity, 10);
    if ((selectedQty <= 0) || (selectedQty > 2147483646) ||
      ((this.maxQty != -1) && (selectedQty > this.maxQty))) {
      setTimeout(() => {
        console.log('invalid quantity added');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add product to cart.';
        this.infoModalRef.componentInstance.longErrorMessage = 'Please enter a valid product quantity and try again.';
      }, 500);
      return;
    }
    const foundVariant = this.getVariant(this.currentColor, this.currentSize);
    if (foundVariant.id !== -1) {
      prodVarId = foundVariant.id;
    }
    const product = {
      productId: this.intItem.id,
      productVariantId: prodVarId,
      quantity: this.prodQuantity,
      dataPoints: '{}'
    };
    this.myProductIsChanging = true;
    this.addingSpinner = true;
    this.cartService.addItemToCart(product);
    const resolvedNumber = parseInt(this.prodQuantity, 10);
    let qtyValue = -1;
    if (!isNaN(resolvedNumber)) {
      qtyValue = resolvedNumber;
    }
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItem.name + ':' + prodVarId, qtyValue);
  }

  sortNull() {}

  getVariant(aColor, aSize, useBaseColor = false) {
    let currentVariant = {id: -1, cost_variants: [], member_cost_variants: [], soldOut: 0, inventory: 0};
    if (this.hasOptions) {
      currentVariant = this.optionsArray[this.currentOption];
    } else {
      this.variantsArray.forEach((variant) => {
        if (variant.color !== null) {
          if (variant.color.id !== aColor) {
            return;
          }
          if ( useBaseColor && (currentVariant.id === -1)) {
            currentVariant = variant;
          }
        }
        if (variant.size !== null) {
          if (variant.size.id !== aSize) {
            return;
          }
        }
        currentVariant = variant;
      });
    }
    return currentVariant;
  }

  getQtyValue(event) {
    event.preventDefault();
    const resolvedNumber = parseInt(event.target.value, 10);
    if (!isNaN(resolvedNumber)) {
      this.prodQuantity = resolvedNumber.toString(10);
      event.target.value = this.prodQuantity;
    } else {
      event.target.value = this.prodQuantity = '';
    }

  }

  ngOnInit(): void {
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.addingSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
    this.cartService.getCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.hasOwnProperty('id')) {
          this.localCart = res;
          if (this.localCart.hasOwnProperty('customer')) {
            this.isMember = this.localCart.customer.waId != null;
          } else {
            this.isMember = false;
          }
        } else {
          this.isMember = false;
        }
      });
  }

  ngAfterViewInit() {
    if (this.inputColorElement) {
      this.inputColorElement.nativeElement.value = this.currentColor;
    }
    if (this.inputSizeElement) {
      this.inputSizeElement.nativeElement.value = this.currentSize;
    }
  }

  onOpenImageBrowser()
  {
    if (this.hasMultipleImages) {
      this.triggerImageBrowserEvent.emit(this.imageArray);
    }
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private findAvailableProduct() {
    if (this.hasVariants) {
      let allSoldout = true;
      // find the first color/size variant that is available for purchase
      Object.keys(this.colorArray).forEach((aColor) => {
        Object.keys(this.sizeArray).forEach((aSize) => {
          if (allSoldout) {
            const foundVariant = this.getVariant(this.colorArray[aColor], this.sizeArray[aSize]);
            if (!foundVariant.soldOut) {
              allSoldout = false;
              this.currentSize = this.sizeArray[aSize];
              this.currentColor = this.colorArray[aColor];
              this.variantNotAvailable = false;
            }
          }
        });
      });
      this.productNotAvailable = allSoldout;
    } else {
      if (this.intItem.soldOut) {
        this.variantNotAvailable = true;
      } else {
        this.variantNotAvailable = false;
      }
    }

  }
}
