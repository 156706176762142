<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025RegistrationForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="wrapper">
      <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>
      <div class="row loaded-content" [class.blurred]="showSpinner">
        <div class="col-md-4">
          <header>
            <h3>REGISTRATION</h3>
          </header>
          <p align="left">Register for the National Specialty to receive important information regarding events and
            schedules, a commemorative bag, logo pin, mug, and official name tag. Your name tag is your ticket to the Welcome
            Reception and other National Specialty events. The registration fee for the 2025 National Specialty is $40 per adult. The
            registration fee for Juniors (ages 8-18) is $25 for the first child, $20 for each additional child in the family.<br>
            Registration Bags and event tickets can be picked up at Show Headquarters located at the entrance to Bayfront Festival Park in
            the Family Fun Center starting on Monday, May 13th, Tuesday, May 14th, and Wednesday, May 15th from 9:00 a.m. to 3:00 p.m.
            After that time, registration materials will be available during show hours at Show Headquarters.</p>
          <p class="fw-bold mb-5">
            <span>Deadline: {{ deadline }}.</span>
            <span>Questions? <a class="btn btn-info quick_links_action ms-1"
                                                  href="mailto: {{ committeeChairEmail }}" download="">
      Please email the Committee Chair</a></span>
          </p>
          <h3>WELCOME RECEPTION</h3>
          <p>Please join us for hors d&rsquo;oeuvres and a cash bar, supported by the NCA Charitable Trust, held in the Bayfront Ballroom located in the Pier B Resort on Tuesday, May 14th at 5:00 p.m.</p>
<h3>NCA ANNUAL AWARDS </h3>
          <p>This year the business portion of the NCA Annual Meeting will be held prior to the National Specialty via a remote meeting format. Detailed information will be provided to the NCA membership.   Immediately following the Welcome Reception, the NCA Annual Meeting will reconvene to HONOR the accomplishments of the 2023 Top Dogs in Conformation, Obedience, Juniors, Versatile Newfs, Working Achievements, ROMs as well as other special awards and recognitions of NCA members.</p>
<h3>WESTMINSTER WATCH PARTY </h3>
          <p>The celebration of great dogs will continue on after the presentation of its the NCA Annual Awards! Join us as we watch the Westminster Kennel Club group judging live from NY, including the Working Group featuring the Newfoundland Best of Breed winner, on big screen  TVs set up throughout the Bayfront Ballroom located in the Pier B Resort.</p>
        </div>

        <div class="col-md-8 col-sm-8">
          <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
          <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
          <form id="initRegForm" [formGroup]="regForm" #lf="ngForm">
            <hr />
            <h2 class="mb-3 text-center float-none">Registration Form</h2>
            <p class="modal-title text-md-left" id="exampleModalLabel">
            </p>
            <h3>Adult Registration</h3>
            <p style="margin-bottom: 5px;">Enter the name to appear on nametags, kennel name, and state and/or country
              of residence</p>
            <div class="form-row align-items-center d-flex">
              <mat-form-field class="inputField-third-width">
                <input matInput placeholder="Name of First Adult" formControlName="adultHandler1"
                       (change)="calculatePrice()">
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Kennel Name" formControlName="adultKennel1">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="State and/or Country" formControlName="adultState1">
                </mat-form-field>
                <mat-checkbox class="inputField-third-width" name="ah1nca" formControlName="ah1nca">NCA Member?
                </mat-checkbox>
              </div>
            </div>
            <div class="form-row align-items-center d-flex">
              <mat-form-field class="inputField-third-width">
                <input matInput placeholder="Name of Second Adult" formControlName="adultHandler2"
                       (change)="calculatePrice()">
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Kennel Name" formControlName="adultKennel2">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="State and/or Country" formControlName="adultState2">
                </mat-form-field>
                <mat-checkbox class="inputField-third-width" name="ah2nca" formControlName="ah2nca">NCA Member?
                </mat-checkbox>
              </div>
            </div>
            <div class="form-row align-items-center d-flex">
              <mat-form-field class="inputField-third-width">
                <input matInput placeholder="Name of Third Adult" formControlName="adultHandler3"
                       (change)="calculatePrice()">
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Kennel Name" formControlName="adultKennel3">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="State and/or Country" formControlName="adultState3">
                </mat-form-field>
                <mat-checkbox class="inputField-third-width" name="ah3nca" formControlName="ah3nca">NCA Member?
                </mat-checkbox>
              </div>
            </div>

            <h3>Junior Registration</h3>
            <p style="margin-bottom: 5px;">Enter the name to appear on nametags, kennel name, and state and/or country
              or
              residence</p>
            <div class="form-row align-items-center d-flex">
              <mat-form-field class="inputField-third-width">
                <input matInput placeholder="Name of First Junior" formControlName="juniorHandler1"
                       (change)="calculatePrice()">
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Kennel Name" formControlName="juniorKennel1">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="State and/or Country" formControlName="juniorState1">
                </mat-form-field>
                <mat-checkbox class="inputField-third-width" name="jh1nca" formControlName="jh1nca">NCA Member?
                </mat-checkbox>
              </div>
            </div>
            <div class="form-row align-items-center d-flex">
              <mat-form-field class="inputField-third-width">
                <input matInput placeholder="Name of Second Junior" formControlName="juniorHandler2"
                       (change)="calculatePrice()">
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Kennel Name" formControlName="juniorKennel2">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="State and/or Country" formControlName="juniorState2">
                </mat-form-field>
                <mat-checkbox class="inputField-third-width" name="jh2nca" formControlName="jh2nca">NCA Member?
                </mat-checkbox>
              </div>
            </div>
            <div class="form-row align-items-center d-flex">
              <mat-form-field class="inputField-third-width">
                <input matInput placeholder="Name of Third Junior" formControlName="juniorHandler3"
                       (change)="calculatePrice()">
              </mat-form-field>
              <div class="col-md-6">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Kennel Name" formControlName="juniorKennel3">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="State and/or Country" formControlName="juniorState3">
                </mat-form-field>
                <mat-checkbox class="inputField-third-width" name="jh3nca" formControlName="jh3nca">NCA Member?
                </mat-checkbox>
              </div>
            </div>
            <div class="col-md-9 table-responsive">
              <table class="table table-reg" style="text-align: right;">
                <tr>
                  <td>Adult Registrations</td>
                  <td>{{ adultRegCount}}</td>
                  <td>@ {{ adultRegCost | currency }}</td>
                  <td>{{ adultRegTotalCost | currency }}</td>
                </tr>
                <tr>
                  <td>First Junior Registrations</td>
                  <td>{{ juniorRegCount > 0 ? 1 : 0 }}</td>
                  <td>@ {{ firstJuniorRegCost | currency }}</td>
                  <td>{{ firstJuniorRegTotalCost | currency }}</td>
                </tr>
                <tr>
                  <td>Additional Junior Registrations</td>
                  <td>{{ juniorRegCount > 1 ? juniorRegCount - 1 : 0 }}</td>
                  <td>@ {{ addJuniorRegCost | currency }}</td>
                  <td>{{ addJuniorRegTotalCost | currency }}</td>
                </tr>
                <tr style="border-top: 1px solid black;font-weight: bold;">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>Registration Total</td>
                  <td>{{ regCost | currency }}</td>
                </tr>
              </table>
            </div>
            <div class="form-row mt-3 mb-3">
              <mat-form-field class="inputField-half-width">
                <input matInput placeholder="Date of Arrival" formControlName="arrival">
                <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                  <mat-error class="error-message"
                             *ngIf="regForm.get('arrival').hasError(validation.type) && (regForm.get('arrival').dirty || regForm.get('arrival').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <div class="col-md-8 col-sm-12 text-center">
                <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                  to
                  cart
                </button>
                <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}</p>
                <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p class="mt-4">Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
    </div>
  </div>
</div>
