export interface Customer {
  identity?: string;
  firstName?: string;
  lastName?: string;
  addressline1?: string;
  addressline2?: string;
  addressCity?: string;
  addressState?: string;
  addressZip?: string;
  addressCountry?: string;
  phone?: string;
  email?: string;
  cartId?: number;
  waId?: string;
  maintain?: boolean;
}

export interface CustomerAddress {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  city?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
  country?: string;
}

export interface ShippingAddress {
  customer: CustomerAddress;
}
export interface CustomerState {
  customer: Customer;
  authenticated: boolean;
  token: string;
}
