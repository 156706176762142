import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  private infoModalRef: NgbModalRef;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(filter(params => params.intlWarning))
      .subscribe(params => {
        if (params.intlWarning === 'true') {
          setTimeout(() => {
            this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
            this.infoModalRef.componentInstance.infoTitle = 'International Shipping Warning.';
            this.infoModalRef.componentInstance.infoMessage = 'Your order was successfully placed however the address you entered' +
              ' when you registered was a United States address, and the shipping address for your order is outside the United States.' +
              ' Additional shipping charges will be required.  You will be contacted soon with additional' +
              ' information about the charges and how to pay for them before your order will be shipped.';
          }, 500);
        }
      });
  }

}
