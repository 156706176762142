<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-12 me-auto">
      <p class="quick_links">Quick Links:</p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>Post Show Merchandise Items</h3>

        <p>Last chance to get your hands on some of the Limited Edition merchandise from the 2025 National in Duluth.</p>
        <p>Check out these items - unique Trapper Hats and frame quality prints - sales limited to stock on hand - order early!</p>
        <p>Questions? <a class="btn quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
          Please email the Committee Chair</a>
        </p>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
          </div>
          <div *ngIf="noProductsAvailable">
            <p>No products available for sale at this time</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalImageBrowser let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div style="flex-direction: row;display: inline-flex;width: 100%;">
      <h2 class="modal-title">Product Image Browser</h2>
      <button type="submit" (click)="d('Close click')" class="close"><span>X</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of images">
          <div class="picsum-img-wrapper text-center">
            <img [src]="image" alt="Random slide">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="d('Close click')" class="btn btn-round-outline">Close</button>
    </div>
  </div>
</ng-template>
