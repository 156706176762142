import * as Rollbar from 'rollbar';
import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { environment } from '../../environments/environment';

// checkIgnore function: return false to allow processing of message, return true to filter out error
// ignoredMessages: Array of messages to ignore
const rollbarConfig = {
  accessToken: 'bf4209c256e7469993c1c0d8835b17ab',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.rollbarEnabled,
  checkIgnore: function(isUncaught, args, payload) {
    return false;
  },
  ignoredMessages: []
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
