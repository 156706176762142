import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-lovett',
  templateUrl: './lovett.component.html',
  styleUrls: ['./lovett.component.scss']
})
export class LovettComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  deadlineTime = 1713992399000;
  deadline = '';
  private unsubscribe$ = new Subject<void>();

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone) { }

  lovettForm: FormGroup;
  showSpinner: boolean;
  formDisabled: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;

  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ],
    regName: [
      { type: 'required', message: 'Your name is required' }
    ],
    draft: [
      { type: 'required', message: 'Your name is required' }
    ],
    rally: [
      { type: 'required', message: 'Your name is required' }
    ],
    obedience: [
      { type: 'required', message: 'Your name is required' }
    ]
  };

  ngOnInit() {
    this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
    this.showSpinner = true;
    this.formDisabled = true;
    this.lovettForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      regName: [{value: '', disabled: false}, Validators.required],
      draft: [{value: '', disabled: false}, Validators.required],
      rally: [{value: '', disabled: false}, Validators.required],
      obedience: [{value: '', disabled: false}, Validators.required],
      phone: [{value: '', disabled: false}],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]]
    });
    this.api.get('/committees/56')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of bulk water products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
  }

  sendForm() {
    if (this.lovettForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 56,
        formData: {
          fullName: this.lovettForm.controls.name.value,
          fullAddress: this.lovettForm.controls.address.value,
          emailAddress: this.lovettForm.controls.emailAddress.value,
          phone: this.lovettForm.controls.phone.value,
          regName: this.lovettForm.controls.regName.value,
          draft: this.lovettForm.controls.draft.value,
          rally: this.lovettForm.controls.rally.value,
          obedience: this.lovettForm.controls.obedience.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        console.log('invalid lovett');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to submit form.';
        this.infoModalRef.componentInstance.infoMessage = 'Please enter all required fields and try again.';
      }, 500);

    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.lovettForm.reset();
      this.myForm.resetForm();
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Lovett Challenge coordinator.' +
        '  You will contacted as soon as possible.  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
