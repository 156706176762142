import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss']
})
export class DraftComponent implements OnInit {

  committeeChairEmail = 'smeed1@mac.com';

  constructor() { }

  ngOnInit() {
  }

}
