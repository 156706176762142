<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025SeminarForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-8 col-xs-12">
      <h3>EDUCATION SEMINAR</h3>
     <p align="left">The NCA Breeders Education Committee is very pleased to have
Internationally renowned Canine Behaviorist, Suzanne Clothier present “The Enriched Puppy & Cultivating Canines: How to Grow
Your Own Best Friend, an in-depth look at what influences a dog's development from birth to adult” on Wednesday evening, May
15th at 8:00 p.m. This free seminar is for breeders, puppy owners or anyone interested in or already involved in raising puppies.
This evening lecture offers practical ideas for environmental and social enrichment aimed at helping puppies become the best they
can be. Suzanne will provide detailed information on the development of the puppy brain, body, reflexes, and social behavior.
Whether you love knowing more about helping puppies become the best they can be, wonder how to polish up the genetic material
in your breeding program, or just love puppies, this is a fascinating evening full of puppies!<br> This seminar is free and light refreshments will be served. Please let us know if you will attend
		</p>
		<p align="left"><strong>Our presenter is: Susan Clothier</strong></p>
<p align="left">Suzanne Clothier has worked with animals professionally since 1977, with a background
that includes obedience, agility, puppy testing, breeding, Search and Rescue, conformation,
instructing, kennel management and AAT.<br>
Her Relationship Centered Training™ approach blends science and heart to create humane,
effective, and practical solutions for dogs and the people who work with them, whether
professional working dogs or couch warming companions.<br>
A “trainer’s trainer”, Suzanne has long been in demand as a speaker at conferences around
the world. She has taught on a broad variety of topics throughout the US and internationally
for groups as varied as Wolf Park, FEMA (Northeast Region Disaster Dog Teams), Alaskan
Dog Musher Association, Assistance Dogs International NA, Association of Professional Dog
Trainers, and University of MN’s SPEAK! conference. She is a popular speaker at online conferences such as Geek Week! and the
Lemonade Conferences.<br>
An innovative trainer always seeking better ways to understand, assess and improve what we do with and for dogs, Suzanne has
published a variety of books, DVDs, online courses, and training tools. Her groundbreaking book, Bones Would Rain from the Sky:
Deepening Our Relationships With Dogs (Warner, 2002) was twice voted a Top 5 book by the Wall Street Journal and is considered a
classic in the field. Her tools and materials have been put to use by everyday pet owners, trainers, breeders, veterinarians, guide &
service dog organizations, animal assisted therapy, and by academics and research. She serves as a consultant to guide and service
dog organizations around the world, with her RCT materials, innovative assessment tools and Enriched Puppy Protocol being
incorporated into their programs.<br>
Her suite of assessment tools provides a powerful framework for trainers and organizations:<br>
• RAT™ (Relationship Assessment Tool) – handler/dog dynamics, rapid assessment plus ability to track and compare.<br>
• CARAT™ (Clothier Animal Response Assessment Tool) – temperament assessment on 6 categories, 21 dimensions<br>
• FAT™ (Functional Assessment Tool, release in 2022) – global of functionality/welfare assessment across physiological,
social, and cognitive dimensions<br>
A long-time breeder of German Shepherds, 11 generations of her dogs have been successful in multiple dog sports, SAR, therapy
work, and as guide dog breeding stock. Her Enriched Puppy Protocol (EPP) reflects her deep understanding of how to provide ageappropriate
challenges to create puppies that are connected, confident, coordinated, and capable. EPP has been used in multiple
guide and service dog organizations and with private breeders to maximize the critical early puppyhood development.<br>
She lives on a working farm in upstate New York with husband John Rice, and their considerable animal family
</p>
    </div>
    <div class="col-md-4 col-xs-12">
      <h3>Register for Education Seminar</h3>
      <form id="seminarForm" [formGroup]="seminarForm" #lf="ngForm">
        <div class="col-md-12">
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Full Name" formControlName="name">
              <mat-error *ngFor="let validation of accountValidationMessages.name">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('name').hasError(validation.type) && (seminarForm.get('name').dirty || seminarForm.get('name').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Email Address" formControlName="emailAddress">
              <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('emailAddress').hasError(validation.type) && (seminarForm.get('emailAddress').dirty || seminarForm.get('emailAddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mt-3 mb-0">
            <mat-form-field class="inputField-half-width">
              <input matInput id="num" type="number" formControlName="num"
                     placeholder="Number in your party">
              <mat-error *ngFor="let validation of accountValidationMessages.num">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('num').hasError(validation.type) && (seminarForm.get('num').dirty || seminarForm.get('num').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row mb-3">
            <mat-form-field class="inputField-full-width">
              <mat-label>Are you an NCA Member?</mat-label>
              <mat-select formControlName="isMember" placeholder="NCA Member?">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
              <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('isMember').hasError(validation.type) && (seminarForm.get('isMember').dirty || seminarForm.get('isMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
        </div>
      </form>
      <p class="mt-5">Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>

    </div>
  </div>
</div>
