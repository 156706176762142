import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerState, ShippingAddress } from '../models/customer';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RollbarService } from '../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { Store } from '@ngrx/store';
import * as customerReducer from '../reducers/customer';
import * as customerActions from '../actions/customer.actions';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

@Component({
  selector: 'app-ship-confirm',
  templateUrl: './ship-confirm.component.html',
  styleUrls: ['./ship-confirm.component.scss']
})
export class ShipConfirmComponent implements OnInit {
  addForm: FormGroup;
  currentCustomer: CustomerState;
  countryNames: any[];
  countryName: string;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  holdCustomerState: CustomerState;

  accountValidationMessages = {
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    address: [
      { type: 'required', message: 'Your shipping address is required' }
    ],
    city: [
      { type: 'required', message: 'Your city is required' }
    ],
    state: [
      { type: 'required', message: 'Your state or province is required' }
    ],
    zip: [
      { type: 'required', message: 'Your zip code is required' }
    ],
    first: [
      { type: 'required', message: 'Your first name is required' }
    ],
    last: [
      { type: 'required', message: 'Your last name is required' }
    ]
  };

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone,
              private cartService: CartService,
              public store: Store<CustomerState>) { }

  ngOnInit() {
    this.currentCustomer = {customer: {
        waId: '', cartId: 0, lastName: '', firstName: '', identity: '', addressline1: '', addressline2: '',
        addressCity: '', addressState: '', addressZip: '', addressCountry: '', email: '', phone: '', maintain: false
      }, token: '', authenticated: false};
    this.countryNames = ['United States', 'Canada', 'Mexico', 'Australia', 'Austria', 'Belgium', 'Denmark', 'France', 'Germany',
      'Ireland', 'Italy', 'Japan', 'Netherlands', 'New Zealand', 'Switzerland', 'United Kingdom', 'Other'];
    this.countryName = 'United States';

    this.showSpinner = false;
    this.addForm = this.fb.group({
      first: ['', Validators.required],
      last: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      country: ['United States']
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if (!this.currentCustomer.authenticated) {
          this.currentCustomer = customer;
          const memFirstControl = this.addForm.get('first');
          memFirstControl?.setValue(this.currentCustomer.customer.firstName);
          memFirstControl?.updateValueAndValidity()
          const memLastControl = this.addForm.get('last');
          memLastControl?.setValue(this.currentCustomer.customer.lastName);
          memLastControl?.updateValueAndValidity()
          const memAddressControl = this.addForm.get('address');
          memAddressControl?.setValue(this.currentCustomer.customer.addressline1);
          memAddressControl?.updateValueAndValidity()
          const memCityControl = this.addForm.get('city');
          memCityControl?.setValue(this.currentCustomer.customer.addressCity);
          memCityControl?.updateValueAndValidity()
          const memStateControl = this.addForm.get('state');
          memStateControl?.setValue(this.currentCustomer.customer.addressState);
          memStateControl?.updateValueAndValidity()
          const memZipControl = this.addForm.get('zip');
          memZipControl?.setValue(this.currentCustomer.customer.addressZip);
          memZipControl?.updateValueAndValidity()
          const memCountryControl = this.addForm.get('country');
          memCountryControl?.setValue(this.currentCustomer.customer.addressCountry);
          memCountryControl?.updateValueAndValidity()
        }
      });
    });
  }

  confirmAddressAction() {
    if (this.addForm.valid) {
      this.showSpinner = true;
      const aShip: ShippingAddress = {
        customer: {
          firstName: this.addForm.get('first').value,
          lastName:  this.addForm.get('last').value,
          addressLine1:  this.addForm.get('address').value,
          city:   this.addForm.get('city').value,
          stateOrProvince:  this.addForm.get('state').value,
          zipOrPostalCode:  this.addForm.get('zip').value,
          country:  this.addForm.get('country').value
        }
      }
      this.cartService.changeShippingAddress(aShip);
      this.activeModal.close();
      this.store.dispatch(new customerActions.UpdateCustomerAction(this.currentCustomer.customer));
    }
  }

}
