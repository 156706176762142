<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a href="{{ formLink }}" class="btn btn-info quick_links_action ms-1" aria-disabled="false" download="2025_NCA_National_Auction_Form.pdf">Download Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-5 col-sm-12 mx-auto">
      <h3>LIVE AUCTION</h3>

      <p>This year’s fundraising efforts will feature ongoing online auctions via the Galabid website and will culminate with the in-person auction of unique items and services Friday night, May 17th in conjunction with the Banquet being held at The Great Lakes Aquarium adjacent to the show site. Only a few select items will be offered that evening so be sure to attend! We are working with a local shipping company to package and ship items  for you in case your suitcase is full! Donation items for auction and raffle must be received by Thursday, May 16th at the convenient drop-off location at Show Headquarters. </p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ auctionCommitteeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
		 <h3>BROWN BAG RAFFLE</h3>
      <p>Also, back by popular demand, the Brown Bag Raffle will be set up in the lobby of The Great Lakes Aquarium the evening of the
Banquet. Your donations will be appreciated! Donation items for auction and raffle must be received by Thursday, May 16th at
the convenient drop-off location at Show Headquarters. </p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ bbCommitteeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <h3>REGIONAL CLUB BASKETS </h3>
      <p>Some very generous Regional Clubs will be thinking “outside the basket” this year by providing interesting items native to their regions for bidding on throughout the week. Baskets will be awarded daily in Shoppers Row starting on Tuesday, May 14th. If your Regional Club would like to donate a basket, please make arrangements to have it delivered to the show site by Tuesday May 14th, 2025.</p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto:regclubbaskets@ncanationalspecialty.org" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <h3>CHARITABLE TRUST RAFFLES</h3>
      <p>The NCA Charitable Trust  will once again offer special Newfoundland collectibles donated by generous supportors and fanciers&rsquo; estates.   The proceeds  will benefit Newfoundland Rescue, the Newfoundland Health Challenge, and Junior Scholarships and will take place in Shoppers Row located adjacent to the grooming tent. Be sure to stop by and help these important causes. </p>
<p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto:mlprice@mhtc.net" download="">Email
        Rescue Committee Chair</a>&nbsp;
      </p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto:bisk9@earthlink.net" download="">Email
        Health Challenge Committee Chair</a>&nbsp;
      </p>
    </div>
    <div class="col-md-7 col-sm-12 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12">
            <form id="auctionForm" [formGroup]="auctionForm" #lf="ngForm">
              <h4 class="mt-4">Auction and Brown Bag Donation Form for the 2025 National Specialty</h4>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="auctionForm.get('name').hasError(validation.type) && (auctionForm.get('name').dirty || auctionForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="auctionForm.get('address').hasError(validation.type) && (auctionForm.get('address').dirty || auctionForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="auctionForm.get('emailAddress').hasError(validation.type) && (auctionForm.get('emailAddress').dirty || auctionForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Phone number" formControlName="phone">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="What are you donating?" formControlName="donation">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Estimated Value" formControlName="estValue">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <textarea matInput  formControlName="description"
                            placeholder="Description of items"></textarea>
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <textarea matInput  formControlName="story"
                            placeholder="If there is a story about your item that should be shared at auction, please share here"></textarea>
                </mat-form-field>
              </div>
              <p>The donor is responsible for ensuring that the item(s) is delivered to the Auction Chair no later than Thursday, May 15, 2025.</p>
              <p>Please watch for information on Item drop-off locations and times at the show headquarters.</p>
              <p class="text-danger">* Items dropped off must be accompanied by a description card and donor name. </p>
              <div class="form-row mb-3">
                <div class="col-md-12 col-sm-12 text-center">
                  <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action me-5" [disabled]="formDisabled">Send Form</button>Auction donation deadline is April 15th, 2025
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
