import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-cgc',
  templateUrl: './cgc.component.html',
  styleUrls: ['./cgc.component.scss']
})
export class CgcComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  committeeChairEmail2 = 'katsdogs2@aol.com';
  cgcForm: FormGroup;
  showSpinner: boolean;
  showSpinner2: boolean;
  infoModalRef: NgbModalRef;
  errorModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  private unsubscribe2$ = new Subject<void>();
  myProductIsChanging: boolean;
  itemName = '';
  itemName2 = '';
  itemId = -1;
  itemId2 = -1;
  itemVariants = [];
  tditemVariants = [];
  levelTypes = [];
  theSelectedLevel = '';
  cgcCost = 0;
  cgcaCost = 0;
  tdCost = 0;
  cgcTotalCost = 0;
  cgcaTotalCost = 0;
  tdTotalCost = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  productInfo2: any;
  orderTotal = 0;
  cgcDeadline = '';
  tdDeadline = '';
  deadlineTime = 0;
  deadlineTime2 = 0;
  productNotAvailable = true;
  productNotAvailable2 = true;
  soldOut = false;
  soldOut2 = false;
  comingSoon = false;
  comingSoon2 = false;
  availabilityDate = '';
  availabilityDate2 = '';
  availabilityDateTime = 0;
  availabilityDateTime2 = 0;

  accountValidationMessages = {
    levelType: [
      {type: 'required', message: 'Please select a testing level'}
    ]

  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.showSpinner2 = false;
    this.cgcForm = this.fb.group({
      cgcQuantity: [{value: 0, disabled: false}],
      cgcaQuantity: [{value: 0, disabled: false}],
      tdQuantity: [{value: 0, disabled: false}],
      levelType: ['']
    });
    this.levelTypes = [
      'Novice Trick Dog (TKN)',
      'Intermediate Trick Dog (TKI)',
      'Advanced Trick Dog (TKA)',
      'Trick Dog Performer (TKP)',
      'Trick Dog Elite Performer (TKE)'
    ];
    this.api.get('/products?category=32&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.productInfo = res.products[0];
                if (this.productInfo.hasOwnProperty('id')) {
                  this.itemId = this.productInfo.id;
                }
                if (this.productInfo.hasOwnProperty('name')) {
                  this.itemName = this.productInfo.name;
                }
                if (this.productInfo.hasOwnProperty('variants')) {
                  this.itemVariants = this.productInfo.variants;
                }
                if (this.productInfo.hasOwnProperty('deadline')) {
                  this.deadlineTime = this.productInfo.deadline;
                  this.cgcDeadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                  this.tdDeadline = this.cgcDeadline;
                }
                if (this.productInfo.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = this.productInfo.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.productInfo.hasOwnProperty('soldOut')) {
                  if (this.productInfo.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
                this.cgcCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                this.cgcaCost = this.productService.getItemPrice(this.productInfo, 50, this.cartService.isMember);
                this.tdCost = this.productService.getItemPrice(this.productInfo, 100, this.cartService.isMember);
              }
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of cgc test products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/6')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.api.get('/products?category=36&variants=true')
      .pipe(takeUntil(this.unsubscribe2$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.productInfo2 = res.products[0];
                if (this.productInfo2.hasOwnProperty('committee')) {
                  const theCommittee = this.productInfo2.committee;
                  this.committeeChairEmail = theCommittee.contactEmail;
                  this.committeeChairName = theCommittee.contactName;
                }
                if (this.productInfo2.hasOwnProperty('id')) {
                  this.itemId2 = this.productInfo2.id;
                }
                if (this.productInfo2.hasOwnProperty('name')) {
                  this.itemName2 = this.productInfo2.name;
                }
                if (this.productInfo2.hasOwnProperty('variants')) {
                  this.tditemVariants = this.productInfo2.variants;
                }
                if (this.productInfo2.hasOwnProperty('deadline')) {
                  this.deadlineTime2 = this.productInfo2.deadline;
                  this.tdDeadline = moment(this.deadlineTime2).format('dddd, MMMM Do YYYY');
                }
                if (this.productInfo2.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime2 = this.productInfo2.availabilityDate;
                  this.availabilityDate2 = moment(this.availabilityDateTime2).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime2))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable2 = false;
                  } else {
                    this.comingSoon2 = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime2))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.productInfo2.hasOwnProperty('soldOut')) {
                  if (this.productInfo2.soldOut) {
                    this.soldOut2 = true;
                  } else {
                    this.productNotAvailable2 = false;
                  }
                } else {
                  this.productNotAvailable2 = false;
                }
                this.tdCost = this.productService.getItemPrice(this.productInfo2, 0, this.cartService.isMember);
              }
            }
            this.showSpinner2 = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner2 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of cgc test products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeLevel(e) {
    this.theSelectedLevel = this.cgcForm.controls.levelType.value;
  }

  changeQuantity() {
    const cgcQuant = this.cgcForm.controls.cgcQuantity.value;
    this.cgcTotalCost = cgcQuant * this.cgcCost;
    const cgcaQuant = this.cgcForm.controls.cgcaQuantity.value;
    this.cgcaTotalCost = cgcaQuant * this.cgcaCost;
    const tdQuant = this.cgcForm.controls.tdQuantity.value;
    this.tdTotalCost = tdQuant * this.tdCost;
    this.orderTotal = this.cgcTotalCost + this.cgcaTotalCost + this.tdTotalCost;
  }

  addToCart() {
    this.cgcForm.markAllAsTouched();
    const cgcQuant = this.cgcForm.controls.cgcQuantity.value;
    const cgcaQuant = this.cgcForm.controls.cgcaQuantity.value;
    const tdQuant = this.cgcForm.controls.tdQuantity.value;
    if ((cgcQuant + cgcaQuant + tdQuant) === 0) {
      this.cgcForm.controls.levelType.clearValidators();
      this.cgcForm.controls.levelType.updateValueAndValidity();
      setTimeout(() => {
        console.log('no cgc test quantity selected');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'No cgc test quantity selected';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the number of each test you' +
          ' would like and try again.';
      }, 500);
      return;
    }
    if ((tdQuant > 0) && (this.theSelectedLevel === '')) {
      this.cgcForm.controls.levelType.setValidators(Validators.required);
      this.cgcForm.controls.levelType.updateValueAndValidity();
      this.cgcForm.markAllAsTouched();
      setTimeout(() => {
        console.log('no trick dog level selected');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Missing Trick Dog level';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the Trick Dog Test Level you are interested in and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    const dPoints = {
      displayName: ''
    };
    const dPoints2 = {
      displayName: '',
      trickDogLevel: this.theSelectedLevel
    };
    if (cgcQuant > 0) {
      const firstReg: CartItem = {
        productId: this.itemId, productVarId: this.itemVariants[0].id,
        quantity: cgcQuant, dataPoints: JSON.stringify(dPoints)
      };
      itemArray.push(firstReg);
    }
    if (cgcaQuant > 0) {
      const firstReg: CartItem = {
        productId: this.itemId, productVarId: this.itemVariants[1].id,
        quantity: cgcaQuant, dataPoints: JSON.stringify(dPoints)
      };
      itemArray.push(firstReg);
    }
    if (tdQuant > 0) {
      const firstReg: CartItem = {
        productId: this.itemId2, productVarId: this.tditemVariants[0].id,
        quantity: tdQuant, dataPoints: JSON.stringify(dPoints2)
      };
      itemArray.push(firstReg);
    }
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.itemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.cgcForm.reset();
      this.myForm.resetForm();
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe2$.next();
    this.unsubscribe2$.complete();
  }


}
