<app-header (cartChanged)="cartChanged($event)"></app-header>

<router-outlet></router-outlet>
<div [ngClass]="getCartClass()">
  <div class="float-cart">
    <div class="d-flex justify-content-between">
      <h3 class="text-start">Order Details</h3>
      <button type="button" (click)="hideCart()" class="btn btn-info quick_links_action">Hide Cart</button>
    </div>
    <div class="wrapper">
      <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>

      <div class="loaded-content" [class.blurred]="showSpinner">

        <div id="shopCartBody" class="cart-body">
          <div *ngIf="actualCart.suborders.length > 0">
            <table width="100%">
              <tr class="border-bottom border-dark">
                <th width="60%">Product</th>
                <th width="15%" class="text-center">Quantity</th>
                <th width="15%" class="text-end">Cost</th>
                <th width="10%" class="text-capitalize text-lg-center"></th>
              </tr>
            </table>
            <div style="height: 30vh;overflow-y: scroll;">
            <table width="100%" class="cart-table">
              <tbody *ngFor="let subs of actualCart.suborders">
              <tr *ngFor="let anItem of subs.orderitems">
                <td width="60%" class="text-start">{{ getProductDisplayName(anItem) }}</td>
                <td width="15%" class="text-center">{{ anItem.quantity }}</td>
                <td width="15%" class="text-end">{{ anItem.itemCost }}</td>
                <th width="10%" class="text-capitalize text-lg-center remove-button" (click)="deleteProd(anItem)">x</th>
              </tr>
              </tbody>
            </table>
              </div>
          </div>
          <div *ngIf="actualCart.suborders.length > 0">
            <table width="100%">
              <tr class="border-top border-dark">
                <th width="60%" class="text-start">{{ shippingText }}
                  <div [ngClass]="getClassForShipping()">
                    <input type="checkbox" class="ms-2" [checked]="shippingWanted" [disabled]="forceShipping" (change)="changeShipping()">
                  </div>
                </th>
                <th width="15%" class="text-end">Shipping:</th>
                <th width="15%" class="text-end">{{ totalOrderShipping | currency }}</th>
                <th width="10%" class="text-capitalize text-lg-center"></th>
              </tr>
              <tr>
                <th width="60%"></th>
                <th width="15%" class="text-end border-top border-dark">Total:</th>
                <th width="15%" class="text-end border-top border-dark">{{ totalOrderCost | currency }}</th>
                <th width="10%" class="text-capitalize text-lg-center"></th>
              </tr>
            </table>
          </div>
          <div *ngIf="actualCart.suborders.length == 0">No Items in cart</div>
        </div>
      </div>
    </div>
    <div class="text-center checkout-button mt-2 mb-2">
      <button type="button" class="btn btn-info quick_links_action" id="checkoutButton" (click)="checkout()" [disabled]="actualCart.suborders.length === 0">
        Checkout Now
      </button>
    </div>
  </div>
</div>
