import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { NatSpecCommittee } from '../../models/committees';
import { CommitteeService } from '../../services/committee.service';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private service: CommitteeService,
              private ngZone: NgZone
  ) { }

  volunteerForm: FormGroup;
  showSpinner: boolean;
  formDisabled: boolean;
  areaString: string;
  alreadyGotOne: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  committees: NatSpecCommittee[];
  @ViewChild('lf', {static: true}) myForm: NgForm;

  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ],
    isMember: [
      { type: 'required', message: 'Please indicate if you are an NCA member or not'}
    ]
  };

  ngOnInit() {
    this.showSpinner = false;
    this.formDisabled = true;
    this.volunteerForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      daytime: [{value: '', disabled: false}],
      dayphone: [{value: '', disabled: false}],
      isMember: [{value: '', disabled: false}, Validators.required],
      eveningphone: [{value: '', disabled: false}],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      catalogsales: [{value: false, disabled: false}],
      cgc: [{value: false, disabled: false}],
      trickdog: [{value: false, disabled: false}],
      communications: [{value: false, disabled: false}],
      confsteward: [{value: false, disabled: false}],
      grounds: [{value: false, disabled: false}],
      setup: [{value: false, disabled: false}],
      draft: [{value: false, disabled: false}],
      auction: [{value: false, disabled: false}],
      hospitality: [{value: false, disabled: false}],
      banquet: [{value: false, disabled: false}],
      speakeasy: [{value: false, disabled: false}],
      logo: [{value: false, disabled: false}],
      obediencesteward: [{value: false, disabled: false}],
      registration: [{value: false, disabled: false}],
      traffic: [{value: false, disabled: false}],
      trophytable: [{value: false, disabled: false}],
      ringfavors: [{value: false, disabled: false}]
    });
    this.service.getCommittees().subscribe((res) => {
      this.ngZone.run(() => {
        this.committees = res.filter(aCommittee => {
          return aCommittee.isVisible;
        });
      });
    },
      (err) => {
      alert("An error occurred getting the list of committees");
      });
  }

  sendForm() {
    if (this.volunteerForm.valid) {
      this.areaString = '';
      this.updateAreasString();
      this.alreadyGotOne = false;
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 50,
        formData: {
          fullName: this.volunteerForm.controls.name.value,
          fullAddress: this.volunteerForm.controls.address.value,
          emailAddress: this.volunteerForm.controls.emailAddress.value,
          daytimePhone: this.volunteerForm.controls.dayphone.value,
          eveningPhone: this.volunteerForm.controls.eveningphone.value,
          ncaMember: this.volunteerForm.controls.isMember.value,
          areas: this.areaString,
          availableTimes: this.volunteerForm.controls.daytime.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    }
  }

  private updateAreasString() {
    this.makeAreaStringPart('catalogsales', 'Catalog Sales');
    this.makeAreaStringPart('cgc', 'CGC');
    this.makeAreaStringPart('trickdog', 'Trick Dog');
    this.makeAreaStringPart('communications', 'Communications');
    this.makeAreaStringPart('confsteward', 'Confirmation Steward');
    this.makeAreaStringPart('grounds', 'Grounds');
    this.makeAreaStringPart('setup', 'Setup');
    this.makeAreaStringPart('draft', 'Draft Test');
    this.makeAreaStringPart('auction', 'Auction');
    this.makeAreaStringPart('hospitality', 'Hospitality');
    this.makeAreaStringPart('banquet', 'Banquet');
    this.makeAreaStringPart('speakeasy', 'Speakeasy Lounge');
    this.makeAreaStringPart('logo', 'Logo Item Sales');
    this.makeAreaStringPart('obediencesteward', 'Obedience Steward');
    this.makeAreaStringPart('registration', 'Registration');
    this.makeAreaStringPart('traffic', 'Traffic Control');
    this.makeAreaStringPart('trophytable', 'Trophy Table');
    this.makeAreaStringPart('ringfavors', 'Ring Favors');
  }

  private makeAreaStringPart(controlName, areaName) {
    if (this.volunteerForm.controls[controlName].value) {
      if (this.alreadyGotOne) {
        this.areaString += ', ';
      }
      this.areaString += areaName;
      this.alreadyGotOne = true;
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.volunteerForm.reset();
      this.myForm.resetForm();
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Volunteer coordinator.' +
        '  You will contacted as soon as possible to find the best fit for your help.  Thank you for volunteering.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      console.log('invalid quantity added');
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }
}
