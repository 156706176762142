<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1 disabled" aria-disabled="true" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12">
      <h3>MEETING ROOMS
      </h3>
      <p>If you need a meeting room during the Specialty for a group or committee, you must reserve the room in advance. Include the date and meeting time, number of people attending, and room setup instructions.   </p>
      <p class="fw-bold">Meeting room signup deadline is April 15th 1st, 2025</p>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="modal-body">
            <form id="meetingForm" [formGroup]="meetingForm" #lf="ngForm">
              <p>Yes, I want to reserve a meeting room at the 2025 National Specialty<br/>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="meetingForm.get('name').hasError(validation.type) && (meetingForm.get('name').dirty || meetingForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="meetingForm.get('address').hasError(validation.type) && (meetingForm.get('address').dirty || meetingForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="meetingForm.get('emailAddress').hasError(validation.type) && (meetingForm.get('emailAddress').dirty || meetingForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Daytime Phone" formControlName="dayphone">
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Evening Phone" formControlName="eveningphone">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Are you an NCA Member?</mat-label>
                  <mat-select formControlName="isMember" placeholder="NCA Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                    <mat-error class="error-message" *ngIf="meetingForm.get('isMember').hasError(validation.type) && (meetingForm.get('isMember').dirty || meetingForm.get('isMember').touched)">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
             <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Group or Committee" formControlName="group">
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Anticipated Number of people attending" formControlName="groupSize">
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Day, Date and Time of Meeting" formControlName="dayTime">
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Room Set-up Instructions" formControlName="setup">
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action me-5">Send Form</button>
                </div>
              </div>
            </form>
            <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
              Please email the Committee Chair</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
