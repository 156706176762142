<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025Top20Form.pdf" download="">Download Top 20 Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
    <header>
		<h3>TOP TWENTY-TEN </h3>
		<p>What could shine brighter in the sky than the Northern Lights? Why only the the Top 20/10 Newoundlands of course! Join us on Thursday, May 16th for the
2025 Newfoundland Club of America National Specialty event honoring our Top 20 Conformation Dogs and Top 10 Obedience Dogs from the 2023 show year.<br>
In keeping with tradition, the judging of the Top 20 Conformation Newfs will be held Thursday, May 16th starting at 5:00 pm. The secret panel of judges will include a Newfoundland breeder, a professional handler of Newfoundlands and an AKC judge approved for the breed. Each judge will utilize the score sheet created by the NCA to award the prestigious Judges Choice rosette.<br>
The Aurora Borealis Ball, a symphony of majestic colors and the twinkle of lights set against the backdrop of the night-lit landmark of the Ariel Lift Bridge, will be held outdoors in Bayfront Festival Park under the Paulucci Pavilion accompanied by classical musicians playing contemporary musical selections. People are encouraged to dress in the colors of the Northern Lights, rich purples, and vivid greens with a dash of sparkle thrown in!<br>
A reception with a cash bar and hors d’oeuvres will start at 7:30 pm to be followed at dusk with the grand finale of the Top 20/10 Newfs taking a center stage under the spotlights. People will have an opportunity to vote for their favorite shining star which will be crowned the People’s Choice.<br>
Tickets: $35 for upper tier seating including program</p>
		<p align="left"></p>
		<p align="left"></p>
		    <p>Top 20/10 Logo Items . . . This year we are pleased to offer a variety of products featuring the Top 20/10 "Aurora Borealis Ball" artwork! Please note that colors and sizes are subject to availability which could possibly delay shipping. Every effort will be made to fulfill orders in a timely manner.</p>
        <p>All orders will be processed and shipped by the vendor. There will not be any on-site pick-up available. All merchandise orders will be shipped. International shipping is available. </p>
        <p><strong> Please note that there will be limited edition merchandise available for sale at the show site during the week featuring the National and Top 20-10 logos. Those items are NOT available through the online store and will only be available to Specialty attendees</strong>.</p>
        <p>&nbsp;</p>
        <p>Terms and Conditions<br>
Every effort will be made to display accurate stock availability in the online store but stock levels are subject to change without prior notification from our vendors. Orders will be processed and shipped as quickly as possible but it should be noted that delivery is not guaranteed by any specific date.
We will do our best as volunteers, to get you your merchandise as quickly as possible. We deeply appreciate your patience.</p>

<div class="row d-none">
  <div class="col-md-8 col-sm-12 d-none">
    <form id="topdogForm" [formGroup]="topdogForm" #lf="ngForm">
      <p class="mt-4">Yes, I am interested in attending the Top Dog Celebration at the 2022 National Specialty<br/>
      <div class="form-row">
        <mat-form-field class="inputField-full-width">
          <input matInput placeholder="Full Name" formControlName="name">
          <mat-error *ngFor="let validation of accountValidationMessages.name">
            <mat-error class="error-message"
                       *ngIf="topdogForm.get('name').hasError(validation.type) && (topdogForm.get('name').dirty || topdogForm.get('name').touched)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field class="inputField-full-width">
          <input matInput placeholder="Email Address" formControlName="emailAddress">
          <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
            <mat-error class="error-message"
                       *ngIf="topdogForm.get('emailAddress').hasError(validation.type) && (topdogForm.get('emailAddress').dirty || topdogForm.get('emailAddress').touched)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row mb-3">
        <div class="col-md-12 col-sm-12 text-center">
          <button type="button" (click)="sendForm()" class="btn btn-info me-5">Send Form</button>Please RSVP by April 1st, 2022
        </div>
      </div>
    </form>
  </div>
</div>
		<p>&nbsp;</p>

      <p>Questions about the Top 20 Celebration? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ celebrationCommitteeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
      <p>Questions about the Top 20 Merchandise? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
           <br />
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner2">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner2">
          <div class="card prodCard" *ngFor="let item of celebItems">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [showOptions]="false" [item]="item"></app-product-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
          </div>
          <div *ngIf="noProductsAvailable">
            <p>No products available for sale at this time</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #modalImageBrowser let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div style="flex-direction: row;display: inline-flex;width: 100%;">
      <h2 class="modal-title">Product Image Browser</h2>
      <button type="submit" (click)="d('Close click')" class="close"><span>X</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of images">
          <div class="picsum-img-wrapper text-center">
            <img [src]="image" alt="Random slide">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="d('Close click')" class="btn btn-round-outline">Close</button>
    </div>
  </div>
</ng-template>
