import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Dog } from '../../models/dog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import * as moment from 'moment-timezone';
import { CartItem } from '../../models/cart';
import { FileHolder } from '../../image-upload/image-upload.component';
import { LookupDogComponent } from '../../lookup-dog/lookup-dog.component';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-living-legends',
  templateUrl: './living-legends.component.html',
  styleUrls: ['./living-legends.component.scss']
})
export class LivingLegendsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalImageBrowser', { static: true }) modalImageBrowser?: NgbModal;
  @ViewChild('lookupdog', {static: true}) private lookupDogElement: LookupDogComponent;
  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  theDogSelected = false;
  dogToUse: Dog;
  legendsForm: FormGroup;

  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  itemVariants = [];
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  productPickupCost = 0;
  productMailCost = 0;
  orderTotal = 0;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  noProductsAvailable = false;
  noMerchAvailable = false;
  items= [];
  merchitems = [];
  images = [];
  uploadFailed = false;
  adUploaded = false;
  adPath = '';
  pdfSrc = '';
  uploadedPDF = false;
  uploadSrc = '';
  uploadResponse = '';
  accountValidationMessages = {
    emailAddress: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Enter a valid email'}
    ],
    phone: [
      {type: 'required', message: 'Your phone number is required'}
    ],
    address: [
      {type: 'required', message: 'Your address is required'}
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.dogToUse = null;
    this.legendsForm = this.fb.group({
      phone: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      pickup: [''],
      isPickingUp: ['']
    });
    this.api.get('/products?category=45&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.merchitems = res.products;
                const prod = res.products[0];
              } else {
                // no products to show:
                this.showSpinner = false;
                this.noMerchAvailable = true;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of logo merchandise.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/products?category=15&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.products.length > 0) {
              this.productInfo = res.products[0];
              if (this.productInfo.hasOwnProperty('id')) {
                this.intItemId = this.productInfo.id;
              }
              if (this.productInfo.hasOwnProperty('name')) {
                this.intItemName = this.productInfo.name;
              }
              if (this.productInfo.hasOwnProperty('variants')) {
                this.itemVariants = this.productInfo.variants;
              }
              if (this.productInfo.hasOwnProperty('deadline')) {
                this.deadlineTime = this.productInfo.deadline;
                this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
              }
              if (this.productInfo.hasOwnProperty('availabilityDate')) {
                this.availabilityDateTime = this.productInfo.availabilityDate;
                this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
              }
              // now check availability
              if (moment().isBefore(moment(this.availabilityDateTime))) {
                // the product is not available yet
                // check if the environment allows early ordering - testing
                if (environment.testProductMode) {
                  this.productNotAvailable = false;
                } else {
                  this.comingSoon = true;
                }
              } else if (moment().isAfter(moment(this.deadlineTime))) {
                // the product is no longer available
                if (this.cartService.isMaintainer) {
                  this.productNotAvailable = false;
                  this.comingSoon = false;
                } else {
                  this.comingSoon = false;
                }
              } else if (this.productInfo.hasOwnProperty('soldOut')) {
                if (this.productInfo.soldOut) {
                  this.soldOut = true;
                } else {
                  this.productNotAvailable = false;
                }
              } else {
                this.productNotAvailable = false;
              }
              this.productPickupCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
              this.productMailCost = this.productService.getItemPrice(this.productInfo, 1, this.cartService.isMember);
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of living legends products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
          if (!this.myProductIsChanging) {
            this.theDogSelected = false;
            this.dogToUse = null;
            this.lookupDogElement.showHide(true);
          }
        });
      });
    this.api.get('/committees/25')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });

  }

  ngAfterViewInit() {
    window.addEventListener('dragover', (e: DragEvent) => {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e: DragEvent) => {
      e.preventDefault();
    }, false);
  }

  onUploadFinished(file: FileHolder) {
    // check the response
    if (file.serverResponse.response.status === 200) {
      this.uploadFailed = false;
      this.adUploaded = true;
      this.adPath = JSON.parse(file.serverResponse.response.body).filename;
//      this.adPath = file.serverResponse.response.body.replace(/\n/g, '');
      if (file.file.name.endsWith('.pdf')) {
        this.pdfSrc = file.src;
        this.uploadedPDF = true;
      } else {
        this.uploadedPDF = false;
        this.uploadSrc = file.src;
      }
    } else {
      this.adUploaded = false;
      this.adPath = '';
      this.uploadResponse = file.serverResponse.response.error;
      this.uploadFailed = true;
    }
  }

  selectedDog(dogEvent) {
    this.theDogSelected = true;
    this.dogToUse = dogEvent;
    this.lookupDogElement.showHide(false);
  }

  getRegisteredName() {
    if ((this.dogToUse !== null) && (this.dogToUse.registration.length > 0)) {
      return this.dogToUse.registration[0].registered_name;
    } else {
      return 'No Dog Selected';
    }
  }

  getRegistrationNumber() {
    if ((this.dogToUse !== null) && (this.dogToUse.registration.length > 0)) {
      return this.dogToUse.registration[0].registry_id;
    } else {
      return 'nodog';
    }
  }

  addToCart() {
    if (!this.legendsForm.valid) {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please complete the form and try again.';
      }, 500);
      return;
    }
    if (this.adPath === '') {
      setTimeout(() => {
        console.log('missing uploaded image');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please Upload an image of your dog and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    // get all grooming spaces
    const dPoints = {
      dogInfo: this.dogToUse,
      phone: this.legendsForm.controls.phone.value,
      emailAddress: this.legendsForm.controls.emailAddress.value,
      pickup: this.legendsForm.controls.pickup.value,
      toBePickedUp: this.legendsForm.controls.isPickingUp.value,
      imagePath: this.adPath,
      displayName: 'Dog: ' + this.getRegisteredName()
    };
    let varId = this.itemVariants[0].id;
    if (this.legendsForm.controls.isPickingUp.value === 'mailed') {
      varId = this.itemVariants[1].id;
    }
    const firstReg: CartItem = {
      productId: this.intItemId, productVarId: varId,
      quantity: 1, dataPoints: JSON.stringify(dPoints)
    };
    itemArray.push(firstReg);
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.myForm.resetForm();
      this.legendsForm.reset();
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openImageBrowser(variantImageArray) {
    this.images = variantImageArray.map((variantImage) => variantImage.path);
    this.modalService.open(this.modalImageBrowser, { windowClass: 'modal-preview', centered: true });
  }

}
