<div class="container">
  <div class="row">
    <div class="col-md-10">
      <h3>
        Thank You!</h3>
      <p>Your purchases for the 2025 NCA National Specialty have been processed. You should receive an email soon that details your purchases. If you forgot something (or decided you need more great logo swag) don't despair! We will remember your login information if you need to stop back again, just start your shopping and ordering experience at the <a routerLink="/shop" routerLinkActive="active">2024 National main page.</a>  </p>
      <p>Stay up-to-date with all the National Specialty News on social media - <a href="https://www.facebook.com/groups/ncanationalspecialty/" target="_blank">Facebook Group</a></p>
      <p>Want to help out? (We can absolutely use your help!) Send in a <a routerLink="/shop/directory" routerLinkActive="active">volunteer form</a></p>
      <p>Thinking about joining the NCA and having an even bigger role in the fun? Check out our membership site at <a href="https://members.ncanewfs.org" target="_blank">https://members.ncanewfs.org</a></p>

    </div>
  </div>
</div>
