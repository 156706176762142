import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  parkForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  parkTypes = [];
  standardCost = 0;
  largerCost = 0;
  standardTotalCost = 0;
  largerTotalCost = 0;
  orderTotal = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  accountValidationMessages = {
    arrival: [
      { type: 'required', message: 'The Date/time of your arrival is required' }
    ],
    departure: [
      { type: 'required', message: 'The Date/time of your departure is required' }
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.parkForm = this.fb.group({
      arrival: [{value: '', disabled: false}],
      departure: [{value: '', disabled: false}],
      num: [{value: 0, disabled: false}],
      type: [{value: '', disabled: false}],
      length: [{value: '', disabled: false}],
      standardQuantity: [{value: 0, disabled: false}],
      largerQuantity: [{value: 0, disabled: false}],
      buddy: [{value: '', disabled: false}]
    });
    this.api.get('/products?category=8&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.productInfo = res.products[0];
                if (this.productInfo.hasOwnProperty('id')) {
                  this.intItemId = this.productInfo.id;
                }
                if (this.productInfo.hasOwnProperty('name')) {
                  this.intItemName = this.productInfo.name;
                }
                if (this.productInfo.hasOwnProperty('variants')) {
                  this.parkTypes = this.productInfo.variants;
                }
                if (this.productInfo.hasOwnProperty('deadline')) {
                  this.deadlineTime = this.productInfo.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (this.productInfo.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = this.productInfo.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.productInfo.hasOwnProperty('soldOut')) {
                  if (this.productInfo.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
                this.standardCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                this.largerCost = this.productService.getItemPrice(this.productInfo, 100, this.cartService.isMember);
              }
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of handler parking products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/18')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }


  changeQuantity() {
    const standardQuant = this.parkForm.controls.standardQuantity.value;
    this.standardTotalCost = standardQuant * this.standardCost;
    const largerQuant = this.parkForm.controls.largerQuantity.value;
    this.largerTotalCost = largerQuant * this.largerCost;
    this.orderTotal = this.standardTotalCost + this.largerTotalCost;
  }

  addToCart() {
    const standardQuant = this.parkForm.controls.standardQuantity.value;
    const largerQuant = this.parkForm.controls.largerQuantity.value;
    if (standardQuant + largerQuant === 0) {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please select the type of parking space you would like and try again.';
      }, 500);
      return;
    }
    const body = {items: []};
    const itemArray = body.items;

    // get all grooming spaces
    const dPoints = {arrival: this.parkForm.controls.arrival.value,
      departure: this.parkForm.controls.departure.value,
      'Number of Newfoundlands entered': this.parkForm.controls.num.value,
      'Vehicle Type': this.parkForm.controls.type.value,
      'Vehicle Length': this.parkForm.controls.length.value,
      'Parking Buddy': this.parkForm.controls.buddy.value,
      displayName: ''
    };
    if (standardQuant > 0) {
      const firstReg: CartItem = {productId: this.intItemId, productVarId: this.parkTypes[0].id,
        quantity: standardQuant, dataPoints: JSON.stringify(dPoints)};
      itemArray.push(firstReg);
    }
    if (largerQuant > 0) {
      const secondReg: CartItem = {productId: this.intItemId, productVarId: this.parkTypes[1].id,
        quantity: largerQuant, dataPoints: JSON.stringify(dPoints)};
      itemArray.push(secondReg);
    }

    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addMultipleItemsToCart(body);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
    setTimeout(() => {
      this.parkForm.reset();
      this.myForm.resetForm();
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
