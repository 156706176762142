import {Action} from '@ngrx/store';
import { Cart, CartState } from '../models/cart';

export const EMPTY = 'Empty';
export const UPDATECART = 'UpdateCart';

export class EmptyCartAction implements Action {
  readonly type = EMPTY;
  constructor(public payload: CartState) {}
}

export class UpdateCartAction implements Action {
  readonly type = UPDATECART;
  constructor(public payload: Cart) {}
}

export type All = EmptyCartAction | UpdateCartAction;
