import { CartState } from '../models/cart';
import * as cartActions from '../actions/cart.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export const initialState: CartState = {
  theCart: { cartId: 0, contents: {}}, cartState: 0
};

export function reducer(state = initialState, action: cartActions.All): CartState {
  switch (action.type) {
    case cartActions.EMPTY: {
      return Object.assign({}, state, {
        theCart: { cartId: 0, contents: {}}, cartState: 0
      });
    }
    case cartActions.UPDATECART: {
      return Object.assign({}, state, {
        theCart: action.payload
      });
    }
    default: {
      return state;
    }
  }
}

export const getCartState = createFeatureSelector<CartState>('cartState');
export const getCart = createSelector(getCartState, (state: CartState) => state);
