<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025SpecCartingForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>SPECIALTY CARTING</h3>
      <p>Specialty Carting &amp; Wagon Exercises were developed to demonstrate carting at
        specialty shows and are designed for people interested in exhibition carting activities. It is a perfect event for someone not quite
        ready to compete in a full draft test. There are on-leash &amp; off-leash divisions for individual dogs or teams. Exercises include
        harnessing and hitching, basic commands while hitched and maneuvering in a large ring setting. While not a ‘titled’ event,
        each passing entry earns a Certificate and qualifies for the Lovett Challenge. Dogs passing the on-leash division may post enter that day for the off-leash division.
        Specialty Carting will take place on Wednesday, May 15th. Dogs must be 12 months of age to enter. The entry fee for a single
        or team is $25; on-site post entry is $15.</p>
      <p><em>Deadline: {{ deadline }}</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
            availabilityDate }}.</h5>
          <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
          <form [formGroup]="cartForm" id="cartForm" #lf="ngForm">
            <div class="col-md-12">
              <app-lookup-dog #lookupdog (dogFound)="selectedDog($event)" [formDisabled]="productNotAvailable"></app-lookup-dog>
              <p><span class="text-lg-left text-dark">Selected Dog:</span> <span>{{ getRegisteredName() }}</span></p>
              <div *ngIf="theDogSelected">
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Phone Number" formControlName="phone">
                    <mat-error *ngFor="let validation of accountValidationMessages.phone">
                      <mat-error class="error-message"
                                 *ngIf="cartForm.get('phone').hasError(validation.type) && (cartForm.get('phone').dirty || cartForm.get('phone').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Email Address" formControlName="emailAddress">
                    <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                      <mat-error class="error-message"
                                 *ngIf="cartForm.get('emailAddress').hasError(validation.type) && (cartForm.get('emailAddress').dirty || cartForm.get('emailAddress').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Handler's Name" formControlName="handler">
                  </mat-form-field>
                </div>
                <div class="form-row mb-3">
                  <mat-form-field class="inputField-half-width">
                    <mat-label>Select an option</mat-label>
                    <mat-select formControlName="testType" placeholder="Type of Test">
                      <mat-option *ngFor="let testType of testTypes" value="{{ testType.id }}">{{ testType.optionName }}</mat-option>
                    </mat-select>
                    <mat-error *ngFor="let validation of accountValidationMessages.testType">
                      <mat-error class="error-message" *ngIf="cartForm.get('testType').hasError(validation.type) && (cartForm.get('testType').dirty || cartForm.get('testType').touched)">{{validation.message}}</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <div class="col-md-12 text-center">
                    <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">Add to cart</button>
                    <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
                    <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                    <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
