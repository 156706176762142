import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CartService } from './services/cart.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoginComponent } from './login/login.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CustomerState } from './models/customer';
import * as customerReducer from './reducers/customer';
import { environment } from '../environments/environment';
import { CheckoutComponent } from './checkout/checkout.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import * as Rollbar from 'rollbar';
import { RollbarService } from './services/rollbar.service';
import { VersionCheckService } from './services/versioncheck.service';
import { ShipConfirmComponent } from './ship-confirm/ship-confirm.component';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'ncanationalstorenew';
  private cartVisible: boolean;
  actualCart: any;
  private unsubscribe$ = new Subject<void>();
  currentCustomer: CustomerState;
  totalOrderCost: number;
  totalOrderShipping: number;
  shippingWanted: boolean;
  forceShipping: boolean;
  isLoggedIn: boolean;
  checkingOut: boolean;
  checkoutLoginNeeded: boolean;
  loginmodalRef: NgbModalRef;
  checkoutmodalRef: NgbModalRef;
  errorModalRef: NgbModalRef;
  confirmModalRef: NgbModalRef;
  showSpinner: boolean;
  gaId = environment.gaId;
  customerInfo: any;
  shippingText = 'Shipping wanted?';

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private router: Router,
              private modalService: NgbModal,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private cartService: CartService,
              private versionCheck: VersionCheckService) {
    this.cartVisible = false;
    this.actualCart = {suborders: []};
    this.totalOrderCost = 0.0;
    this.totalOrderShipping = 0.0;
    this.shippingWanted = true;
    this.forceShipping = true;
    this.isLoggedIn = false;
    this.checkingOut = false;
    this.showSpinner = false;
    this.checkoutLoginNeeded = false;
    if (this.forceShipping) {
      this.shippingText = '';
    } else {
      this.shippingText = 'Shipping wanted?';
    }
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', this.gaId,
            {
              page_path: event.urlAfterRedirects
            }
          );
        }
      }
    );
  }

  ngOnInit() {
    this.store.select(customerReducer.getCustomer)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        this.currentCustomer = customer;
        this.isLoggedIn = customer.authenticated;
//        this.updateConnection();
        if (this.checkoutLoginNeeded) {
          // we got here because we just logged in after hitting checkout
          this.checkoutLoginNeeded = false;
          this.finishCheckout();
        }
      });
    });
    this.cartService.getCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('id')) {
            this.actualCart = res;
            if (this.actualCart.hasOwnProperty('totalCost')) {
              this.totalOrderCost = this.actualCart.totalCost;
            }
            if (this.actualCart.hasOwnProperty('shippingCost')) {
              this.totalOrderShipping = this.actualCart.shippingCost;
            }
            if (this.totalOrderCost > 0.0) {
              this.cartVisible = true;
            }
            if (this.actualCart.hasOwnProperty('ship')) {
              this.shippingWanted = this.actualCart.ship === 1;
            }
          }
        });
      });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          this.showSpinner = res;
        });
      });
    this.versionCheck.initVersionCheck(environment.versioncheckUrl, 1000 * 60 * 60); // check every hour
  }

  updateConnection() {
    (window as any).Upscope('updateConnection', {
      // Set the user ID below. If you don't have one, set to undefined.
      uniqueId: this.currentCustomer.customer.waId,

      // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
      identities: [this.currentCustomer.customer.firstName, this.currentCustomer.customer.lastName,
        this.currentCustomer.customer.email]
    });

  }

  cartChanged(data) {
    this.cartVisible = !this.cartVisible;
  }

  deleteProd(anItem) {
    this.cartService.removeProduct(anItem.id);
  }

  getCartClass() {
    if (this.cartVisible) {
      return 'floating-cart';
    } else {
      return 'floating-cart hidden';
    }
  }

  gotoShop() {
    this.router.navigateByUrl('/shop');
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getClassForShipping() {
    if (this.forceShipping) {
      return 'd-none';
    } else {
      return 'd-inline-block';
    }
  }
  changeShipping() {
    if (this.forceShipping) {
      this.shippingWanted = true;
      this.shippingText = '';
    } else {
      this.shippingWanted = !this.shippingWanted;
      this.shippingText = 'Shipping wanted?';
    }
    this.cartService.changeShipping(this.shippingWanted);
  }

  hideCart() {
    this.cartVisible = false;
  }

  getProductDisplayName(anItem) {
    if (anItem.product_variant !== null) {
      return anItem.product.name + ' - ' + anItem.product_variant.displayName;
    } else if (anItem.dataPoints !== '{}') {
      const dpJson = JSON.parse(anItem.dataPoints);
      return anItem.product.name + ' - ' + dpJson.displayName;
    } else {
      return anItem.product.name;
    }
  }

  checkout() {
    if (!this.isLoggedIn) {
      this.checkoutLoginNeeded = true;
      this.loginmodalRef = this.modalService.open(LoginComponent, {size: 'lg'});
    } else if (this.currentCustomer.customer.maintain || (this.totalOrderShipping > 0.0)) {
      this.checkoutLoginNeeded = true;
      this.confirmModalRef = this.modalService.open(ShipConfirmComponent, {size: 'lg'});
    } else {
      this.finishCheckout()
    }
  }

  finishCheckout() {
    if (this.checkingOut) {
      return;
    }
    this.cartService.checkOrder()
      .subscribe((res) => {
        this.ngZone.run(() => {
          const retString = res.status;
          if (retString === 'success') {
            this.finishCheckout2();
          } else {
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'submit your order. Error Message was: ' + retString;
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
            this.cartService.forceCartRefresh();
          }
        });
      });
  }

  finishCheckout2() {
    this.checkingOut = true;
    if (this.currentCustomer.customer.maintain) {
      this.checkoutmodalRef = this.modalService.open(CheckoutComponent, {size: 'lg'});
      this.cartService.checkoutMaintain((this.totalOrderCost * 100), this.shippingWanted)
        .subscribe((data) => {
            this.ngZone.run(() => {
              this.checkoutmodalRef.close();
              if (data.result) {
                this.checkingOut = false;
                const newCartId = data.newCartId;
                this.cartService.cartId = newCartId;
                this.router.navigateByUrl('thanks');
              } else {
                // an error occurred
                this.checkingOut = false;
                setTimeout(() => {
                  this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                  this.errorModalRef.componentInstance.errorMessage = 'submit your order. Error Message was: ' + data.reason;
                  this.errorModalRef.componentInstance.longErrorMessage = '';
                }, 500);
              }
            });
          },
          () => {
            this.ngZone.run((err) => {
              this.checkoutmodalRef.close();
              this.checkingOut = false;
              setTimeout(() => {
                this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                this.errorModalRef.componentInstance.errorMessage = 'submit your order. Error Message was: ' + err.reason;
                this.errorModalRef.componentInstance.longErrorMessage = '';
              }, 500);
            });
          });
      return;
    }
    // NCA Specialty Key
    // let keyStr = 'pk_test_62ItxRAxlpGxVJM2GWP91V4U';
    // NCNE Key
    let keyStr = 'pk_test_51NNLiuBQW3iJo2RYgoJ6MtNw9QRAwrmZ9wIPu9pp0KKjkUmZJBD75C0zcAp33htCbje9dX2EjChpdOKOo8ca9OkY00XMPMgug2';
    if (environment.production) {
      // NCNE Key
      keyStr = 'pk_live_51NNLiuBQW3iJo2RY7yq1s5dMVbuJnJFeWcA0JB72WqK2oC4JSxOrM9GoXXw2WMGR9YhM4uLPmNRT3WH3xlpvqdW800rxgnECdi';
      // NCA Specialty Key
      // keyStr = 'pk_live_GAo20kYe12Yufxcjuj8C7j6T';
    }
    const handler = (window as any).StripeCheckout.configure({
      key: keyStr,
      locale: 'auto',
      allowRememberMe: false,
      shippingAddress: false,
      billingAddress: true,
      token: ( (token, args) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        this.checkoutmodalRef = this.modalService.open(CheckoutComponent, {size: 'lg'});
        this.cartService.checkout((this.totalOrderCost * 100), token, args, this.shippingWanted)
          .subscribe((data) => {
            this.ngZone.run(() => {
              this.checkoutmodalRef.close();
              if (data.result) {
                this.checkingOut = false;
                const newCartId = data.newCartId;
                this.cartService.cartId = newCartId;
                this.router.navigateByUrl('thanks');
              } else {
                // an error occurred
                this.checkingOut = false;
                setTimeout(() => {
                  this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                  this.errorModalRef.componentInstance.errorMessage = 'submit your order. Error Message was: ' + data.reason;
                  this.errorModalRef.componentInstance.longErrorMessage = '';
                }, 500);
              }
            });
          },
            () => {
              this.ngZone.run((err) => {
                this.checkoutmodalRef.close();
                this.checkingOut = false;
                setTimeout(() => {
                  this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                  this.errorModalRef.componentInstance.errorMessage = 'submit your order. Error Message was: ' + err.reason;
                  this.errorModalRef.componentInstance.longErrorMessage = '';
                }, 500);
              });
            });
      })
    });

    handler.open({
      name: 'NCA National Store',
      description: '2025 NCA National Specialty Store',
      amount: this.totalOrderCost * 100,
      closed: () => {
        this.checkingOut = false;
      }
    });
  }
}
