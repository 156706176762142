<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025HonorsForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 col-xs-12">
      <h3>Honors Parade</h3>
      <p>Join us on Friday, May 13th at 2:30 p.m. to applaud Newfoundlands of all ages for their accomplishments. Any living Newfoundland with an AKC, CKC, or NCA title is eligible to participate. Each dog entered will be listed in the show catalog and receive a lovely commemorative medallion. You and/or your dog do not have to be present for the parade to enter, but we encourage all entrants to join in and receive the well-earned applause. The non-refundable entry fee is $35. If entering by mail, please complete a separate entry form for each dog you are entering. Do NOT send Honors Parade entries to the Show Superintendent. </p>
      <p><em>Deadline: {{ deadline }}</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>

      <h2 class="mt-3 float-none">Online Honors Parade Entry Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
            availabilityDate }}.</h5>
          <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>

          <form [formGroup]="honorsForm" id="honorsForm" #lf="ngForm">
            <div class="col-md-12">
              <app-lookup-dog #lookupdog (dogFound)="selectedDog($event)" [formDisabled]="productNotAvailable"></app-lookup-dog>
              <p><span class="text-lg-left text-dark">Selected Dog:</span> <span>{{ getRegisteredName() }}</span></p>
              <div *ngIf="theDogSelected">
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Phone Number" formControlName="phone">
                    <mat-error *ngFor="let validation of accountValidationMessages.phone">
                      <mat-error class="error-message"
                                 *ngIf="honorsForm.get('phone').hasError(validation.type) && (honorsForm.get('phone').dirty || honorsForm.get('phone').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Email Address" formControlName="emailAddress">
                    <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                      <mat-error class="error-message"
                                 *ngIf="honorsForm.get('emailAddress').hasError(validation.type) && (honorsForm.get('emailAddress').dirty || honorsForm.get('emailAddress').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput
                           placeholder="If you cannot attend, provide the name of the person who can pick up your medallion for you"
                           formControlName="pickup">
                  </mat-form-field>
                </div>
                <div class="form-row mb-3">
                  <mat-form-field class="inputField-half-width">
                    <mat-label>Do you plan to walk in the parade with your dog</mat-label>
                    <mat-select formControlName="isWalking" placeholder="Walk?">
                      <mat-option value="yes">Yes</mat-option>
                      <mat-option value="no">No</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <div class="col-md-12 text-center">
                    <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">
                      Add to cart
                    </button>
                    <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                      availabilityDate }}.</p>
                    <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                    <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
