import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dog, FoundDog } from '../models/dog';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { environment } from '../../environments/environment';
import { PagerService } from '../services/pagerService';

@Component({
  selector: 'app-lookup-dog',
  templateUrl: './lookup-dog.component.html',
  styleUrls: ['./lookup-dog.component.scss']
})
export class LookupDogComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  noResultsFoundFromSearch = false;
  lookupForm: FormGroup;
  manDogFormGroup: FormGroup;
  showSpinner: boolean;
  private allItems: FoundDog;
  pager: any = {};
  pagedItems: Dog[];
  infoModalRef: NgbModalRef;
  theSelectedDog: Dog;
  byNameTableShown: boolean;
  byNameTableShownSpinner: boolean;
  searchResultsString = '';
  reviewFound = false;
  manualEntry = false;
  dogSelected = false;
  showTheUI = 'block';
  dobMaxDate = Date.now();
  missingCallName = false;
  missingOwnerName = false;
  missingBreederName = false;

  accountValidationMessages = {
    regIdMan: [
      { type: 'required', message: 'Dog Registration ID is required' }
    ],
    regOrgMan: [
      { type: 'required', message: 'Registration Organization is required' }
    ],
    regDogNameMan: [
      { type: 'required', message: 'Dog Registered Name is required' }
    ],
    regDogCallNameMan: [
      { type: 'required', message: 'Dog Call Name is required' }
    ],
    regTitledNameMan: [
      { type: 'required', message: 'Dog Full Titled Name is required' }
    ],
    regDogDOBMan: [
      { type: 'required', message: 'Dog Date of Birth is required' }
    ],
    regGenderMan: [
      { type: 'required', message: 'Dog Gender is required' }
    ],
    regOwnerNameMan: [
      { type: 'required', message: 'Owner Name is required' }
    ],
    regBreederNameMan: [
      { type: 'required', message: 'Breeder Name is required' }
    ],
    regOwnerAddressStreetMan: [
      { type: 'required', message: 'Owner Street Address is required' }
    ],
    regOwnerCityMan: [
      { type: 'required', message: 'Owner City is required' }
    ],
    regOwnerStateMan: [
      { type: 'required', message: 'Owner State is required' }
    ],
    regOwnerZipMan: [
      { type: 'required', message: 'Owner Zip Code is required' }
    ]
  };

  @Output() dogFound = new EventEmitter();
  @Input() formDisabled: boolean;

  constructor(private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone,
              private  modalService: NgbModal,
              private pagerService: PagerService) { }

  ngOnInit() {
    this.lookupForm = this.fb.group({
      dogNameOrId: [{value: '', disabled: false}],
      titlesString: [{value: '', disabled: false}],
      sireTitlesString: [{value: '', disabled: false}],
      damTitlesString: [{value: '', disabled: false}],
      callName: [{value: '', disabled: false}],
      breederName: [{value: '', disabled: false}],
      ownerName: [{value: '', disabled: false}],
      sireRegId: [{value: '', disabled: false}],
      damRegId: [{value: '', disabled: false}],
      foundDog: [false],
      regIdMan: [''],
      regOrgMan: [''],
      regOtherName: [''],
      regDogNameMan: [''],
      regTitledNameMan: [''],
      regDogCallNameMan: [''],
      regDogDOBMan: [''],
      regGenderMan: [''],
      regSireNameMan: [''],
      regSireRegIdMan: [''],
      regDamNameMan: [''],
      regDamRegIdMan: [''],
      regBreederNameMan: [''],
      regOwnerNameMan: [''],
      regOwnerAddressStreetMan: [''],
      regOwnerCityMan: [''],
      regOwnerStateMan: [''],
      regOwnerZipMan: ['']
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  lookupDog() {
    this.byNameTableShownSpinner = true;
    this.api.getDog('/dogs/search?q=' + this.lookupForm.controls.dogNameOrId.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
          this.ngZone.run(() => {
            this.allItems = res;
            if (this.allItems.total === 0) {
              this.noResultsFoundFromSearch = true;
            } else {
              this.noResultsFoundFromSearch = false;
            }
            // special case handling if you find only 1 dog
            if (this.allItems.dogs.length === 1) {
              this.selectDog(this.allItems.dogs[0]);
            } else {
              this.setPage(1);
              this.reviewFound = false;
            }
            this.manualEntry = false;
            this.removeManualVals();
            this.byNameTableShownSpinner = false;
            this.searchResultsString = 'No Results Found';
            setTimeout( () => {
              if (this.allItems.total > this.allItems.limit) {
                this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
                this.infoModalRef.componentInstance.infoTitle = 'Too Many Results';
                this.infoModalRef.componentInstance.infoMessage = 'Please enter more characters/numbers or enter a more ' +
                  'unique part of the dog\'s name to find fewer search results.  The first 100 results are shown.';
              }
            }, 500);
          });
        },
        error2 => {
          this.ngZone.run(() => {
            this.searchResultsString = 'Please try again.';
            this.reviewFound = false;
            this.manualEntry = false;
            this.removeManualVals();
            this.noResultsFoundFromSearch = true;
            this.byNameTableShownSpinner = false;
          });
        });
  }

  selectDog(aDog) {
    this.theSelectedDog = aDog;
    if (this.theSelectedDog.sire === null) {
      this.theSelectedDog.sire = {
        dog_id: 0,
        sex: 'M',
        date_of_birth: '',
        call_name: '',
        breeder: {
          breeder_name: ''
        },
        owner: {
          owner_name: '',
          owner_address: '',
          owner_address2: '',
          owner_city: '',
          owner_state: '',
          owner_postal_code: ''
        },
        dam: null,
        sire: null,
        titles: '',
        titles_changed: false,
        registration: [
          {
            registry_id: '',
            registered_name: '',
            registry_organization: ''
          }
        ]
      };
    }
    if (this.theSelectedDog.dam === null) {
      this.theSelectedDog.dam = {
        dog_id: 0,
        sex: 'F',
        date_of_birth: '',
        call_name: '',
        breeder: {
          breeder_name: ''
        },
        owner: {
          owner_name: '',
          owner_address: '',
          owner_address2: '',
          owner_city: '',
          owner_state: '',
          owner_postal_code: ''
        },
        dam: null,
        sire: null,
        titles: '',
        titles_changed: false,
        registration: [
          {
            registry_id: '',
            registered_name: '',
            registry_organization: ''
          }
        ]
      };
    }

    this.lookupForm.patchValue({foundDog: true});
    this.lookupForm.patchValue({titlesString: ''});
    this.lookupForm.patchValue({sireTitlesString: ''});
    this.lookupForm.patchValue({damTitlesString: ''});
    if ((this.theSelectedDog.sire !== null) && (this.theSelectedDog.sire.registration.length > 0)) {
      this.lookupForm.patchValue({sireRegId: this.theSelectedDog.sire.registration[0].registry_id});
    } else {
      this.lookupForm.patchValue({sireRedId: ''});
    }
    if ((this.theSelectedDog.dam !== null) && (this.theSelectedDog.dam.registration.length > 0)) {
      this.lookupForm.patchValue({damRegId: this.theSelectedDog.dam.registration[0].registry_id});
    } else {
      this.lookupForm.patchValue({damRegId: ''});
    }
    this.lookupForm.patchValue({callName: this.theSelectedDog.call_name});
    this.lookupForm.patchValue({ownerName: this.theSelectedDog.owner.owner_name});
    this.lookupForm.patchValue({breederName: this.theSelectedDog.breeder.breeder_name});
    this.missingCallName = this.theSelectedDog.call_name === '';
    this.missingOwnerName = this.theSelectedDog.owner.owner_name === '';
    this.missingBreederName = this.theSelectedDog.breeder.breeder_name === '';
    this.manualEntry = false;
    this.removeManualVals();
    this.byNameTableShown = false;
    this.getTitleString();
    this.reviewFound = true;
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.dogs.length, page);

    // get current page of items
    this.pagedItems = this.allItems.dogs.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }


  getTitleString() {
    if (this.theSelectedDog != null) {
      this.byNameTableShownSpinner = true;
      if (this.theSelectedDog.dog_id >= 0) {
        this.api.getDog('/dogs/' + this.theSelectedDog.dog_id + '/titles')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
              this.ngZone.run(() => {
                this.byNameTableShownSpinner = false;
                this.lookupForm.patchValue({titlesString: res.titleString});
                this.theSelectedDog.titles = res.titleString;
                if (res.titleString.length > 0) {
                  this.theSelectedDog.titles_changed = false;
                } else {
                  this.lookupForm.patchValue({titlesString: ''});
                }
              });
            },
            error2 => {
              this.ngZone.run(() => {
                this.byNameTableShownSpinner = false;
                this.lookupForm.patchValue({titlesString: ''});
                this.theSelectedDog.titles = '';
              });
            });
      }
      if ((this.theSelectedDog.sire !== null) && (this.theSelectedDog.sire.dog_id >= 0)) {
        this.api.getDog('/dogs/' + this.theSelectedDog.sire.dog_id + '/titles')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
              this.ngZone.run(() => {
                this.byNameTableShownSpinner = false;
                this.lookupForm.patchValue({sireTitlesString: res.titleString});
                this.theSelectedDog.sire.titles = res.titleString;
                if (res.titleString.length > 0) {
                  this.theSelectedDog.sire.titles_changed = false;
                } else {
                  this.lookupForm.patchValue({sireTitlesString: ''});
                }
              });
            },
            error2 => {
              this.ngZone.run(() => {
                this.byNameTableShownSpinner = false;
                this.lookupForm.patchValue({sireTitlesString: ''});
                this.theSelectedDog.sire.titles = '';
              });
            });
      }
      if ((this.theSelectedDog.dam !== null) && (this.theSelectedDog.dam.dog_id >= 0)) {
        this.api.getDog('/dogs/' + this.theSelectedDog.dam.dog_id + '/titles')
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
              this.ngZone.run(() => {
                this.byNameTableShownSpinner = false;
                this.lookupForm.patchValue({damTitlesString: res.titleString});
                this.theSelectedDog.dam.titles = res.titleString;
                if (res.titleString.length > 0) {
                  this.theSelectedDog.dam.titles_changed = false;
                } else {
                  this.lookupForm.patchValue({damTitlesString: ''});
                }
              });
            },
            error2 => {
              this.ngZone.run(() => {
                this.byNameTableShownSpinner = false;
                this.lookupForm.patchValue({damTitlesString: ''});
                this.theSelectedDog.dam.titles = '';
              });
            });
      }
    }
  }

  getRegisteredName(aDog: Dog) {
    if (aDog.registration.length > 0) {
      return aDog.registration[0].registered_name;
    } else {
      return 'Unknown Dog';
    }
  }

  getSelectedRegisteredName() {
    if (this.theSelectedDog !== null) {
      if (this.theSelectedDog.registration.length > 0) {
        return this.theSelectedDog.registration[0].registered_name;
      } else {
        return 'Unknown Dog';
      }
    } else {
      return 'Unknown Dog';
    }
  }

  getSelectedDogTitles() {
    if (this.theSelectedDog != null) {
      return this.theSelectedDog.titles;
    } else {
      return this.getSelectedRegisteredName();
    }
  }

  getSelectedSireTitles() {
    if ((this.theSelectedDog != null) && (this.theSelectedDog.sire != null)) {
      return this.theSelectedDog.sire.titles;
    } else {
      return this.getSelectedSireRegisteredName();
    }
  }

  getSelectedDamTitles() {
    if ((this.theSelectedDog != null) && (this.theSelectedDog.dam != null)) {
      return this.theSelectedDog.dam.titles;
    } else {
      return this.getSelectedDamRegisteredName();
    }
  }


  getRegistryId(aDog: Dog) {
    if (aDog.registration.length > 0) {
      return aDog.registration[0].registry_id;
    } else {
      return 'Unknown Registry ID for Dog ID: ' + aDog.dog_id;
    }
  }

  getSelectedRegistryId() {
    if (this.theSelectedDog !== null) {
      if (this.theSelectedDog.registration.length > 0) {
        return this.theSelectedDog.registration[0].registry_id;
      } else {
        return 'Unknown Registry ID for Dog ID: ' + this.theSelectedDog.dog_id;
      }
    } else {
      return 'Unknown Registry ID for Dog';
    }
  }

  getSelectedRegistryOrg() {
    if (this.theSelectedDog !== null) {
      if (this.theSelectedDog.registration.length > 0) {
        return this.theSelectedDog.registration[0].registry_organization;
      } else {
        return 'Unknown organization';
      }
    } else {
      return 'Unknown organization';
    }
  }


  getDateOfBirth(aDog: Dog) {
    if (aDog.date_of_birth !== null) {
      return aDog.date_of_birth;
    } else {
      return '1969-01-01';
    }
  }

  getSelectedDateOfBirth() {
    if (this.theSelectedDog !== null) {
      if (this.theSelectedDog.date_of_birth !== null) {
        return this.theSelectedDog.date_of_birth;
      } else {
        return '1969-01-01';
      }
    } else {
      return '1969-01-01';
    }
  }

  getDogGender(aDog: Dog) {
    if (aDog.sex !== null) {
      return aDog.sex;
    } else {
      return 'Unknown DOB';
    }
  }

  getSelectedDogGender() {
    if (this.theSelectedDog !== null) {
      if (this.theSelectedDog.sex !== null) {
        return this.theSelectedDog.sex;
      } else {
        return 'Unknown Gender';
      }
    } else {
      return 'Unknown Gender';
    }
  }

  getCallName(aDog: Dog) {
    if (aDog.call_name !== null) {
      return aDog.call_name;
    } else {
      return 'Unknown Call Name';
    }
  }

  getSelectedCallName() {
    if (this.theSelectedDog !== null) {
      if (this.theSelectedDog.call_name !== null) {
        return this.theSelectedDog.call_name;
      } else {
        return 'Unknown Call Name';
      }
    } else {
      return 'Unknown Call Name';
    }
  }

  getSireRegisteredName(aDog: Dog) {
    if (aDog.sire !== null) {
      if (aDog.sire.registration.length > 0) {
        return aDog.sire.registration[0].registered_name;
      } else {
        return 'Unknown Dog';
      }
    } else {
      return 'Unknown Dog';
    }
  }

  getSelectedSireRegisteredName() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.sire !== null)) {
      if (this.theSelectedDog.sire.registration.length > 0) {
        return this.theSelectedDog.sire.registration[0].registered_name;
      } else {
        return 'Unknown Dog';
      }
    } else {
      return 'Unknown Dog';
    }
  }

  getSelectedSireRegistryId() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.sire !== null)) {
      if (this.theSelectedDog.sire.registration.length > 0) {
        return this.theSelectedDog.sire.registration[0].registry_id;
      } else {
        return 'Unknown Id';
      }
    } else {
      return 'Unknown Id';
    }
  }

  getDamRegisteredName(aDog: Dog) {
    if (aDog.dam !== null) {
      if (aDog.dam.registration.length > 0) {
        return aDog.dam.registration[0].registered_name;
      } else {
        return 'Unknown Dog';
      }
    } else {
      return 'Unknown Dog';
    }
  }

  getSelectedDamRegisteredName() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.dam !== null)) {
      if (this.theSelectedDog.dam.registration.length > 0) {
        return this.theSelectedDog.dam.registration[0].registered_name;
      } else {
        return 'Unknown Dog';
      }
    } else {
      return 'Unknown Dog';
    }
  }

  getSelectedDamRegistryId() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.dam !== null)) {
      if (this.theSelectedDog.dam.registration.length > 0) {
        return this.theSelectedDog.dam.registration[0].registry_id;
      } else {
        return 'Unknown Id';
      }
    } else {
      return 'Unknown Id';
    }
  }

  getBreederName(aDog: Dog) {
    if ((aDog.breeder !== null) && (aDog.breeder.breeder_name !== null)) {
      return aDog.breeder.breeder_name;
    } else {
      return 'Unknown Breeder';
    }
  }

  getSelectedBreederName() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.breeder !== null)) {
      if (this.theSelectedDog.breeder.breeder_name !== null) {
        return this.theSelectedDog.breeder.breeder_name;
      } else {
        return 'Unknown Breeder';
      }
    } else {
      return 'Unknown Breeder';
    }
  }

  getOwnerName(aDog: Dog) {
    if ((aDog.owner !== null) && (aDog.owner.owner_name !== null)) {
      return aDog.owner.owner_name;
    } else {
      return 'Unknown Owner';
    }
  }

  getSelectedOwnerName() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.owner !== null)) {
      if (this.theSelectedDog.owner.owner_name !== null) {
        return this.theSelectedDog.owner.owner_name;
      } else {
        return 'Unknown Owner';
      }
    } else {
      return 'Unknown Owner';
    }
  }

  getSelectedOwnerAddress() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.owner !== null)) {
      if (this.theSelectedDog.owner.owner_address !== null) {
        return this.theSelectedDog.owner.owner_address;
      } else {
        return 'Unknown Owner Address';
      }
    } else {
      return 'Unknown Owner Address';
    }
  }

  getSelectedOwnerAddress2() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.owner !== null)) {
      if (this.theSelectedDog.owner.owner_address2 !== null) {
        return this.theSelectedDog.owner.owner_address2;
      } else {
        return 'Unknown Owner Address2';
      }
    } else {
      return 'Unknown Owner Address2';
    }
  }

  getSelectedOwnerCity() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.owner !== null)) {
      if (this.theSelectedDog.owner.owner_city !== null) {
        return this.theSelectedDog.owner.owner_city;
      } else {
        return 'Unknown Owner City';
      }
    } else {
      return 'Unknown Owner City';
    }
  }

  getSelectedOwnerState() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.owner !== null)) {
      if (this.theSelectedDog.owner.owner_state !== null) {
        return this.theSelectedDog.owner.owner_state;
      } else {
        return 'Unknown Owner State';
      }
    } else {
      return 'Unknown Owner State';
    }
  }

  getSelectedOwnerZip() {
    if ((this.theSelectedDog !== null) && (this.theSelectedDog.owner !== null)) {
      if (this.theSelectedDog.owner.owner_postal_code !== null) {
        return this.theSelectedDog.owner.owner_postal_code;
      } else {
        return 'Unknown Owner Postal Code';
      }
    } else {
      return 'Unknown Owner Postal Code';
    }
  }

  removeManualVals() {
    const regIdManControl = this.lookupForm.get('regIdMan');
    regIdManControl?.clearValidators();
    regIdManControl?.setValue('');
    regIdManControl?.updateValueAndValidity();
    const regOrgManControl = this.lookupForm.get('regOrgMan');
    regOrgManControl?.clearValidators();
    regOrgManControl?.setValue('');
    regOrgManControl?.updateValueAndValidity();
    const regDogNameManControl = this.lookupForm.get('regDogNameMan');
    regDogNameManControl?.clearValidators();
    regDogNameManControl?.setValue('');
    regDogNameManControl?.updateValueAndValidity();
    const regDogCallNameManControl = this.lookupForm.get('regDogCallNameMan');
    regDogCallNameManControl?.clearValidators();
    regDogCallNameManControl?.setValue('');
    regDogCallNameManControl?.updateValueAndValidity();
    const regTitledNameManControl = this.lookupForm.get('regTitledNameMan');
    regTitledNameManControl?.clearValidators();
    regTitledNameManControl?.setValue('');
    regTitledNameManControl?.updateValueAndValidity();
    const regDogDOBManControl = this.lookupForm.get('regDogDOBMan');
    regDogDOBManControl?.clearValidators();
    regDogDOBManControl?.setValue('');
    regDogDOBManControl?.updateValueAndValidity();
    const regGenderManControl = this.lookupForm.get('regGenderMan');
    regGenderManControl?.clearValidators();
    regGenderManControl?.setValue('');
    regGenderManControl?.updateValueAndValidity();
    const regOwnerNameManControl = this.lookupForm.get('regOwnerNameMan');
    regOwnerNameManControl?.clearValidators();
    regOwnerNameManControl?.setValue('');
    regOwnerNameManControl?.updateValueAndValidity();
    const regBreederNameManControl = this.lookupForm.get('regBreederNameMan');
    regBreederNameManControl?.clearValidators();
    regBreederNameManControl?.setValue('');
    regBreederNameManControl?.updateValueAndValidity();
    const regOwnerAddressStreetManControl = this.lookupForm.get('regOwnerAddressStreetMan');
    regOwnerAddressStreetManControl?.clearValidators();
    regOwnerAddressStreetManControl?.setValue('');
    regOwnerAddressStreetManControl?.updateValueAndValidity();
    const regOwnerCityManControl = this.lookupForm.get('regOwnerCityMan');
    regOwnerCityManControl?.clearValidators();
    regOwnerCityManControl?.setValue('');
    regOwnerCityManControl?.updateValueAndValidity();
    const regOwnerStateManControl = this.lookupForm.get('regOwnerStateMan');
    regOwnerStateManControl?.clearValidators();
    regOwnerStateManControl?.setValue('');
    regOwnerStateManControl?.updateValueAndValidity();
    const regOwnerZipManControl = this.lookupForm.get('regOwnerZipMan');
    regOwnerZipManControl?.clearValidators();
    regOwnerZipManControl?.setValue('');
    regOwnerZipManControl?.updateValueAndValidity();
  }

  getClassForFirst() {
    let disString = '';
    if (this.pager.currentPage === 1) {
      disString = ' disabled';
    }
    return 'page-item' + disString;
  }

  getClassForLast() {
    let disString = '';
    if (this.pager.currentPage === this.pager.totalPages) {
      disString = ' disabled';
    }
    return 'page-item' + disString;
  }

  getClassForPage(page) {
    let activeStr = '';
    if (this.pager.currentPage === page) {
      activeStr = ' active';
    }
    return 'page-item' + activeStr;
  }

  breederNameSet() {
    this.theSelectedDog.breeder.breeder_name = this.lookupForm.controls.breederName.value;
    this.missingBreederName = this.theSelectedDog.breeder.breeder_name === '';
  }

  ownerNameSet() {
    this.theSelectedDog.owner.owner_name = this.lookupForm.controls.ownerName.value;
    this.missingOwnerName = this.theSelectedDog.owner.owner_name === '';
  }

  callNameSet() {
    this.theSelectedDog.call_name = this.lookupForm.controls.callName.value;
    this.missingCallName = this.theSelectedDog.call_name === '';
  }

  titlesChanged() {
    this.theSelectedDog.titles_changed =  true;
    this.theSelectedDog.titles = this.lookupForm.controls.titlesString.value;
  }

  sireTitlesChanged() {
    this.theSelectedDog.sire.titles_changed =  true;
    this.theSelectedDog.sire.titles = this.lookupForm.controls.sireTitlesString.value;
  }

  damTitlesChanged() {
    this.theSelectedDog.dam.titles_changed =  true;
    this.theSelectedDog.dam.titles = this.lookupForm.controls.damTitlesString.value;
  }

  sireIdChanged() {
    this.theSelectedDog.sire.registration[0].registry_id = this.lookupForm.controls.sireRegId.value;
  }

  damIdChanged() {
    this.theSelectedDog.dam.registration[0].registry_id = this.lookupForm.controls.damRegId.value;
  }

  selectThisDog() {
    let proceed = true;
    if (this.theSelectedDog.call_name === '') {
      this.missingCallName = true;
      proceed = false;
    }
    if (this.theSelectedDog.owner.owner_name === '') {
      this.missingOwnerName = true;
      proceed = false;
    }
    if (this.theSelectedDog.breeder.breeder_name === '') {
      this.missingBreederName = true;
      proceed = false;
    }
    if (proceed) {
      this.dogFound.emit(this.theSelectedDog);
      this.reviewFound = false;
      this.manualEntry = false;
      this.dogSelected = false;
      this.lookupForm.reset();
      this.allItems.dogs = [];
      this.allItems.total = 0;
      this.pagedItems = [];
      this.pager = {};
    }
  }

  selectThisDogMan() {
    if (this.lookupForm.valid) {
      this.theSelectedDog = {
        dog_id: 0,
        sex: this.lookupForm.controls.regGenderMan.value,
        date_of_birth: this.lookupForm.controls.regDogDOBMan.value,
        call_name: this.lookupForm.controls.regDogCallNameMan.value,
        breeder: {
          breeder_name: this.lookupForm.controls.regBreederNameMan.value
        },
        owner: {
          owner_name: this.lookupForm.controls.regOwnerNameMan.value,
          owner_address: this.lookupForm.controls.regOwnerAddressStreetMan.value,
          owner_address2: '',
          owner_city: this.lookupForm.controls.regOwnerCityMan.value,
          owner_state: this.lookupForm.controls.regOwnerStateMan.value,
          owner_postal_code: this.lookupForm.controls.regOwnerZipMan.value
        },
        registration: [
          {
            registry_id: this.lookupForm.controls.regIdMan.value,
            registered_name: this.lookupForm.controls.regDogNameMan.value,
            registry_organization: this.lookupForm.controls.regOrgMan.value
          }
        ],
        dam: {
          dog_id: 0,
          sex: 'F',
          date_of_birth: '',
          call_name: '',
          breeder: {
            breeder_name: ''
          },
          owner: {
            owner_name: '',
            owner_address: '',
            owner_address2: '',
            owner_city: '',
            owner_state: '',
            owner_postal_code: ''
          },
          dam: null,
          sire: null,
          titles: this.lookupForm.controls.regDamNameMan.value,
          titles_changed: true,
          registration: [
            {
              registry_id: this.lookupForm.controls.regDamRegIdMan.value,
              registered_name: this.lookupForm.controls.regDamNameMan.value,
              registry_organization: this.lookupForm.controls.regOrgMan.value
            }
          ]
        },
        sire: {
          dog_id: 0,
          sex: 'M',
          date_of_birth: '',
          call_name: '',
          breeder: {
            breeder_name: ''
          },
          owner: {
            owner_name: '',
            owner_address: '',
            owner_address2: '',
            owner_city: '',
            owner_state: '',
            owner_postal_code: ''
          },
          dam: null,
          sire: null,
          titles: this.lookupForm.controls.regSireNameMan.value,
          titles_changed: true,
          registration: [
            {
              registry_id: this.lookupForm.controls.regSireRegIdMan.value,
              registered_name: this.lookupForm.controls.regSireNameMan.value,
              registry_organization: this.lookupForm.controls.regOrgMan.value
            }
          ]
        },
        titles: this.lookupForm.controls.regTitledNameMan.value,
        titles_changed: true
      };
      this.selectThisDog();
    } else {
      this.lookupForm.markAllAsTouched();
    }
  }

  backToSearch() {
    this.reviewFound = false;
  }

  manual() {
    this.reviewFound = true;
    this.manualEntry = true;
    this.lookupForm.controls.regIdMan.setValidators(Validators.required);
    this.lookupForm.controls.regIdMan.updateValueAndValidity();
    this.lookupForm.controls.regOrgMan.setValidators(Validators.required);
    this.lookupForm.controls.regOrgMan.updateValueAndValidity();
    this.lookupForm.controls.regDogNameMan.setValidators(Validators.required);
    this.lookupForm.controls.regDogNameMan.updateValueAndValidity();
    this.lookupForm.controls.regDogCallNameMan.setValidators(Validators.required);
    this.lookupForm.controls.regDogCallNameMan.updateValueAndValidity();
    this.lookupForm.controls.regTitledNameMan.setValidators(Validators.required);
    this.lookupForm.controls.regTitledNameMan.updateValueAndValidity();
    this.lookupForm.controls.regDogDOBMan.setValidators(Validators.required);
    this.lookupForm.controls.regDogDOBMan.updateValueAndValidity();
    this.lookupForm.controls.regGenderMan.setValidators(Validators.required);
    this.lookupForm.controls.regGenderMan.updateValueAndValidity();
    this.lookupForm.controls.regOwnerNameMan.setValidators(Validators.required);
    this.lookupForm.controls.regOwnerNameMan.updateValueAndValidity();
    this.lookupForm.controls.regBreederNameMan.setValidators(Validators.required);
    this.lookupForm.controls.regBreederNameMan.updateValueAndValidity();
    this.lookupForm.controls.regOwnerAddressStreetMan.setValidators(Validators.required);
    this.lookupForm.controls.regOwnerAddressStreetMan.updateValueAndValidity();
    this.lookupForm.controls.regOwnerCityMan.setValidators(Validators.required);
    this.lookupForm.controls.regOwnerCityMan.updateValueAndValidity();
    this.lookupForm.controls.regOwnerStateMan.setValidators(Validators.required);
    this.lookupForm.controls.regOwnerStateMan.updateValueAndValidity();
    this.lookupForm.controls.regOwnerZipMan.setValidators(Validators.required);
    this.lookupForm.controls.regOwnerZipMan.updateValueAndValidity();
  }

  showHide(showIt) {
    this.showTheUI = (showIt ? 'block' : 'none');
  }
}
