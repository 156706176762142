import {CustomerState} from '../models/customer';
import * as customerActions from '../actions/customer.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export const initialState: CustomerState = {
  customer: {identity: '', firstName: '', lastName: '', cartId: 0, waId: '', addressline1: '', addressline2: '',
    addressCity: '', addressState: '', addressZip: '', addressCountry: '', email: '', phone: '', maintain: false},
  authenticated: false,
  token: ''
};

export function reducer(state = initialState, action: customerActions.All): CustomerState {
  switch (action.type) {
    case customerActions.LOGIN: {
      return Object.assign({}, state, {
        customer: action.payload.customer,
        authenticated: action.payload.authenticated,
        token: action.payload.token
      });
    }
    case customerActions.SETAUTH: {
      return Object.assign({}, state, {
        authenticated: action.payload
      });
    }
    case customerActions.SETTOKEN: {
      return Object.assign({}, state, {
        token: action.payload
      });
    }
    case customerActions.UPDATECUSTOMER: {
      return Object.assign({}, state, {
        customer: action.payload
      });
    }
    case customerActions.LOGOUT: {
      return Object.assign({}, state, {
        customer: {identity: '', firstName: '', lastName: '', cartId: 0, waId: '', addressline1: '', addressline2: '',
          addressCity: '', addressState: '', addressZip: '', addressCountry: '', email: '', phone: '', maintain: false},
        authenticated: false,
        token: ''
      });
    }
    default: {
      return state;
    }
  }
}
export const getCustomerState = createFeatureSelector<CustomerState>('customerState');
export const getCustomer = createSelector(getCustomerState, (state: CustomerState) => state);
