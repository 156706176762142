<div
     fileDrop
     [accept]="extensions"
     (fileOver)="onFileOver($event)"
     (fileDrop)="onFileChange($event)"
     [ngClass]="class"
     [ngClass]="{'img-ul-file-is-over': fileOver}"
     [ngStyle]="style?.layout"
>
  <div class="img-ul-file-upload img-ul-hr-inline-group">
    <label *ngIf="fileCounter != max"
      class="img-ul-upload img-ul-button"
      [ngStyle]="style?.selectButton"
      [ngClass]="{'img-ul-disabled': disabled}">
      <span [innerText]="buttonCaption"></span>
      <input
        type="file"
        [disabled]="disabled"
        [accept]="extensions"
        multiple (change)="onFileChange(input.files)"
        #input>
    </label>
    <button *ngIf="fileCounter > 0"
      [disabled]="disabled"
      class="img-ul-clear img-ul-button"
      (click)="deleteAll()"
      [ngStyle]="style?.clearButton"
      [innerText]="clearButtonCaption">
    </button>
    <div class="img-ul-drag-box-msg" [innerText]="dropBoxMessage"></div>
  </div>

  <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p>

  <div *ngIf="preview" class="img-ul-container img-ul-hr-inline-group" [ngStyle]="style?.previewPanel">
    <div class="testing">
      <div *ngIf="!uploadedPDF">
        <div
          class="img-ul-image"
          *ngFor="let file of files"
          (click)="previewFileClicked(file)"
          [ngStyle]="{'background-image': 'url('+ file.src +')'}">
          <div *ngIf="file.pending" class="img-ul-loading-overlay">
            <div class="img-ul-spinning-circle"></div>
          </div>
          <div *ngIf="!file.pending"
               [ngClass]="{'img-ul-disabled': disabled}"
               class="img-ul-x-mark"
               (click)="deleteFile(file)">
            <span class="img-ul-close"></span>
          </div>
        </div>
      </div>
      <div *ngIf="uploadedPDF && files.length > 0" class="img-ul-image">
        <pdf-viewer
          class="img-ul-pdf-image"
          [src]="pdfSrc"
          [original-size]="false"
          (click)="previewPDFFileClicked()">
        </pdf-viewer>
        <div *ngIf="files[0].pending" class="img-ul-loading-overlay">
          <div class="img-ul-spinning-circle"></div>
        </div>
        <div *ngIf="!files[0].pending"
             [ngClass]="{'img-ul-disabled': disabled}"
             class="img-ul-x-mark"
             (click)="deleteFile(files[0])">
          <span class="img-ul-close"></span>
        </div>
      </div>
    </div>
  </div>
</div>
