<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-8">
      <table class="table table-hover display ps-2 pe-2 py-0 w-100 chairs"
             style="cursor: pointer;">
        <tr>
          <td colspan="3"><h3>2025 NCA Show Committee Chairs</h3></td>
        </tr>
        <tr>
          <th>Name</th>
          <th>Contact Name</th>
          <th>Contact Email</th>
        </tr>
        <ng-container *ngFor="let aComm of committees;let index = index;">
          <tr>
            <td>{{ aComm.name }}</td>
            <td>{{ aComm.contactName }}</td>
            <td><a href="mailto:{{ aComm.contactEmail }}">{{ aComm.contactEmail }}</a></td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</div>

