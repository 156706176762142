import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  hcForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  cardioItemName = '';
  cardioItemId = -1;
  cardioVariants = [];
  optItemName = '';
  optItemId = -1;
  optVariants = [];
  costOfOpt = 0;
  cardioProd: any;
  costOfAuscult = 0;
  costOfEcho = 0;
  auscCost = 0;
  echoCost = 0;
  optCost = 0;
  orderTotal = 0;
  isMember = false;
  memberDiscountApplied = false;
  memberDiscountAvailable = true;
  optProd: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  accountValidationMessages = {
    contact: [
      { type: 'required', message: 'Contact information is required' }
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) {
    this.optProd = {};
    this.cardioProd = {};
  }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.hcForm = this.fb.group({
      contact: [{value: '', disabled: false}, Validators.required],
      auscQuantity: [{value: 0, disabled: false}],
      echoQuantity: [{value: 0, disabled: false}],
      optQuantity: [{value: 0, disabled: false}]
    });
    this.api.get('/products?category=7&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.cardioProd = res.products[0];
                if (this.cardioProd.hasOwnProperty('id')) {
                  this.cardioItemId = this.cardioProd.id;
                }
                if (this.cardioProd.hasOwnProperty('name')) {
                  this.cardioItemName = this.cardioProd.name;
                }
                if (this.cardioProd.hasOwnProperty('variants')) {
                  this.cardioVariants = this.cardioProd.variants;
                }
                if (this.cardioProd.hasOwnProperty('deadline')) {
                  this.deadlineTime = this.cardioProd.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (this.cardioProd.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = this.cardioProd.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.cardioProd.hasOwnProperty('soldOut')) {
                  if (this.cardioProd.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
                this.costOfAuscult = this.productService.getItemPrice(this.cardioProd, 0, this.cartService.isMember);
                this.costOfEcho = this.productService.getItemPrice(this.cardioProd, 1, this.cartService.isMember);
              }
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of cardiology products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/4')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.api.get('/products?category=31&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.products.length > 0) {
              this.optProd = res.products[0];
              if (this.optProd.hasOwnProperty('id')) {
                this.optItemId = this.optProd.id;
              }
              if (this.optProd.hasOwnProperty('name')) {
                this.optItemName = this.optProd.name;
              }
              if (this.optProd.hasOwnProperty('variants')) {
                this.optVariants = this.optProd.variants;
              }
              this.costOfOpt = this.productService.getItemPrice(this.optProd, 0, this.cartService.isMember);
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of opthamology products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
          // check the pricing too
          if (this.cardioItemId !== -1) {
            this.costOfAuscult = this.productService.getItemPrice(this.cardioProd, 0, this.cartService.isMember);
            this.costOfEcho = this.productService.getItemPrice(this.cardioProd, 1, this.cartService.isMember);
          }
          if (this.optItemId !== -1) {
            this.costOfOpt = this.productService.getItemPrice(this.optProd, 0, this.cartService.isMember);
          }
        });
      });
  }

  changeQuantity() {
    const auscQuant = this.hcForm.controls.auscQuantity.value;
    this.auscCost = auscQuant * this.costOfAuscult;
    const echoQuant = this.hcForm.controls.echoQuantity.value;
    this.echoCost = echoQuant * this.costOfEcho;
    const optQuant = this.hcForm.controls.optQuantity.value;
    this.optCost = optQuant * this.costOfOpt;
    this.orderTotal = this.auscCost + this.echoCost + this.optCost;
  }

  addToCart() {
    if (this.hcForm.valid) {
      const auscQuant = this.hcForm.controls.auscQuantity.value;
      const echoQuant = this.hcForm.controls.echoQuantity.value;
      const optQuant = this.hcForm.controls.optQuantity.value;
      if ((auscQuant + echoQuant + optQuant) === 0) {
        setTimeout(() => {
          console.log('invalid adtype');
          this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
          this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
          this.infoModalRef.componentInstance.infoMessage = 'Please select the number of each clinic you' +
            ' would like and try again.';
        }, 500);
        return;
      }
      const body = {items: []};
      const itemArray = body.items;

      const dPoints = {
        contact: this.hcForm.controls.contact.value,
        displayName: ''
      };
      if (auscQuant > 0) {
        const firstReg: CartItem = {
          productId: this.cardioItemId, productVarId: this.cardioVariants[0].id,
          quantity: auscQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (echoQuant > 0) {
        const firstReg: CartItem = {
          productId: this.cardioItemId, productVarId: this.cardioVariants[1].id,
          quantity: echoQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      if (optQuant > 0) {
        const firstReg: CartItem = {
          productId: this.optItemId, productVarId: this.optVariants[0].id,
          quantity: optQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      this.myProductIsChanging = true;
      this.showSpinner = true;
      this.cartService.addMultipleItemsToCart(body);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.cardioItemName + ':' + 'multiple', 1);
      setTimeout(() => {
        this.hcForm.reset();
        this.myForm.resetForm();
      });
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
