<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025BanquetForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6 col-xs-12">
      <h3> FOOD &amp; DINING EVENTS</h3><br/>
        <p><strong>Thursday Evening:</strong> Top 20/10 - Aurora Borealis Ball . . . The Aurora Borealis Ball, a symphony of majestic colors
and the twinkle of lights set against the backdrop of the night-lit landmark of the Ariel Lift Bridge, will be held outdoors in Bayfront
Festival Park under the Paulucci Pavilion accompanied by classical musicians playing contemporary musical selections. People are
encouraged to dress in the colors of the Northern Lights, rich purples, and vivid greens with a dash of sparkle thrown in!
A reception with a cash bar and hors d’oeuvres will start at 7:30 pm to be followed at dusk with the grand finale of the Top 20/10
Newfs taking a center stage under the spotlights. </p>
      <div class="text-center mt-1 mb-3">
        <a class="btn btn-info quick_links_action ms-1" (click)="gotoTop20Page()">Buy tickets for the Top 20/10 Ball</a>
      </div>
      <p><em>Contact: .</em><a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>  </p>
      <p><strong>Friday Evening: </strong>Join us for a banquet event like no other as we have reserved the entire Great Lakes Aquarium, located directly adjacent to the show site. This aquarium features animals and habitats found within the Great Lakes Basin and other freshwater ecosystems such as the Amazon River. Please join us to mix and mingle, eat, drink, and CELEBRATE our Newfs. You will enjoy sampling the delicious local cuisine as you taste your way through the “grazing” stations. The stations will consist of hot and cold selections along with many other tasty hors d’oeuvres. A cash bar will be available with seating throughout the second-floor exhibits and galleries.<br>
	  Through the generosity of Cissy Sullivan, we are again pleased to bring the third in the NEWF series of napkin rings. “W” is for WATER and what better place to pick up this exclusive keepsake than at an aquarium!<br>
        Tickets are $75 and include admission to the Great Lakes Aquarium that evening, parking, and a commemorative napkin ring. Tickets must be purchased in advance, and you must be present to receive your napkin rings. </p>
<p><em>Deadline: {{ deadline }}. </em></p>
      <p><em>Contact:</em><a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;</p>
      <p>
        <strong>Saturday Afternoon:</strong> Immediately following Best of Breed judging, raise a glass to celebrate the week’s top winners from the conformation, obedience and rally events as they take a victory lap around the ring. . As available, judges will provide their comments and observations as well as we conclude our week at The Lake. Contacts: Kathy Wortham and Shauna Bryant.
      </p>
    </div>
    <div class="col-md-6 col-sm-12">
      <h2 class="mt-3 float-none">Banquet Tickets Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="modal-body">
            <form id="mealForm" [formGroup]="mealForm" #lf="ngForm">
              <div class="table-responsive">
                <table formArrayName="meals" id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product" *ngFor="let meal of mealVariants;let i = index" [formGroupName]="i">
                    <td data-th="Product">{{ meal.displayName }}</td>
                    <td class="object-price">{{ meal.price | currency}}</td>
                    <td data-th="Quantity">
                      <input type="number" class="form-control text-center product-quantity" value="0" min="0"
                             (change)="changeQuantity(i)" formControlName="quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ meal.subTotal | currency }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row shop_page_content d-none">
    <div class="col-md-10 col-sm-12 col-xs-12">
      <h3>MOVIE NIGHT</h3>
      <p>More information will be posted soon.</p>
      <form id="movieForm" [formGroup]="movieForm" #lf2="ngForm">
        <p>Yes, I will be attending the Movie Night at the 2025 National Specialty<br/>
        <div class="col-md-8 col-sm-12 d-none">
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Full Name" formControlName="name">
              <mat-error *ngFor="let validation of accountValidationMessages.name">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('name').hasError(validation.type) && (movieForm.get('name').dirty || movieForm.get('name').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Email Address" formControlName="emailAddress">
              <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('emailAddress').hasError(validation.type) && (movieForm.get('emailAddress').dirty || movieForm.get('emailAddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mt-3 mb-0">
            <mat-form-field class="inputField-full-width">
              <input matInput id="numberInParty" type="number" formControlName="numberInParty"
                     placeholder="Number in your party">
              <mat-error *ngFor="let validation of accountValidationMessages.numberInParty">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('numberInParty').hasError(validation.type) && (movieForm.get('numberInParty').dirty || movieForm.get('numberInParty').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mb-3">
            <mat-form-field class="inputField-full-width">
              <mat-label>Are you an NCA Member?</mat-label>
              <mat-select formControlName="isMember" placeholder="NCA Member?">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
              <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('isMember').hasError(validation.type) && (movieForm.get('isMember').dirty || movieForm.get('isMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
        </div>
      </form>
      <p><em>Deadline: {{ deadline }}.</em></p>
      <p><em>Questions?</em><a class="btn btn-info quick_links_action ms-1"
                                           href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>
      </p>
    </div>
  </div>
</div>
