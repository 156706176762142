import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-post-show',
  templateUrl: './post-show.component.html',
  styleUrls: ['./post-show.component.scss']
})
export class PostShowComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  pscForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  itemName = '';
  itemId = -1;
  pscCost = 0;
  pscTotalCost = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  orderTotal = 0;
  deadlineTime = 0;
  deadline = '';
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  countryNames: any[];

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.countryNames = ['United States', 'Canada', 'Mexico', 'Australia', 'Austria', 'Belgium', 'Denmark', 'France', 'Germany',
      'Ireland', 'Italy', 'Japan', 'Netherlands', 'New Zealand', 'Switzerland', 'United Kingdom', 'Other'];
    this.pscForm = this.fb.group({
      pscQuantity: [{value: 0, disabled: false}]
    });
    this.api.get('/products?category=13&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            // returned an array
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.productInfo = res.products[0];
                if (this.productInfo.hasOwnProperty('id')) {
                  this.itemId = this.productInfo.id;
                }
                if (this.productInfo.hasOwnProperty('name')) {
                  this.itemName = this.productInfo.name;
                }
                if (this.productInfo.hasOwnProperty('deadline')) {
                  this.deadlineTime = this.productInfo.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (this.productInfo.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = this.productInfo.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (this.productInfo.hasOwnProperty('soldOut')) {
                  if (this.productInfo.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
                this.pscCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
              }
            }
            this.showSpinner = false;
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of post show catalog products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/32')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity() {
    const pscQuant = this.pscForm.controls.pscQuantity.value;
    this.pscTotalCost = pscQuant * this.pscCost;
    this.orderTotal = this.pscTotalCost;
  }

  addToCart() {
    if (this.pscForm.valid) {
      const pscQuant = this.pscForm.controls.pscQuantity.value;
      if ((pscQuant) === 0) {
        setTimeout(() => {
          console.log('invalid adtype');
          this.infoModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
          this.infoModalRef.componentInstance.infoMessage = 'Please select the number of catalogs you' +
            ' would like and try again.';
        }, 500);
        return;
      }
      const body = {items: []};
      const itemArray = body.items;

      const dPoints = {
        displayName: ''
      };
      if (pscQuant > 0) {
        const firstReg: CartItem = {
          productId: this.itemId, productVarId: -1,
          quantity: pscQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      this.myProductIsChanging = true;
      this.showSpinner = true;
      this.cartService.addMultipleItemsToCart(body);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.itemName + ':' + 'multiple', 1);
      setTimeout(() => {
        this.pscForm.reset();
        this.myForm.resetForm();
      });
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
