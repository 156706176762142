import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { ShoppingRoutingModule } from './shopping-routing.module';
import { LogoComponent } from './logo/logo.component';
import { Top20Component } from './top20/top20.component';
import { GolfcartComponent } from './golfcart/golfcart.component';
import { BulkwaterComponent } from './bulkwater/bulkwater.component';
import { RvparkingComponent } from './rvparking/rvparking.component';
import { ProductCardComponent } from '../product-card/product-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MainComponent } from './main/main.component';
import { PacketComponent } from './packet/packet.component';
import { FormsComponent } from './forms/forms.component';
import { HotelComponent } from './hotel/hotel.component';
import { ShowSuperComponent } from './show-super/show-super.component';
import { CatalogAdsComponent } from './catalog-ads/catalog-ads.component';
import { VendorsComponent } from './vendors/vendors.component';
import { OpeningComponent } from './opening/opening.component';
import { MeetingComponent } from './meeting/meeting.component';
import { JuniorsComponent } from './juniors/juniors.component';
import { GroomingComponent } from './grooming/grooming.component';
import { SeatingComponent } from './seating/seating.component';
import { ParkingComponent } from './parking/parking.component';
import { MealsComponent } from './meals/meals.component';
import { HealthComponent } from './health/health.component';
import { SeminarsComponent } from './seminars/seminars.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { WorkingDogForumComponent } from './working-dog-forum/working-dog-forum.component';
import { RescueComponent } from './rescue/rescue.component';
import { HonorsComponent } from './honors/honors.component';
import { LivingLegendsComponent } from './living-legends/living-legends.component';
import { CartingComponent } from './carting/carting.component';
import { DraftComponent } from './draft/draft.component';
import { CgcComponent } from './cgc/cgc.component';
import { PostShowComponent } from './post-show/post-show.component';
import { DirectoryComponent } from './directory/directory.component';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { ImageService } from '../image-upload/image.service';
import { FileDropDirective } from '../services/file-drop.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LookupDogComponent } from '../lookup-dog/lookup-dog.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LovettComponent } from './lovett/lovett.component';
import { InternationalComponent } from './international/international.component';
import { TrophiesComponent } from './trophies/trophies.component';
import { VintageComponent } from './vintage/vintage.component';
import { VipComponent } from './vip/vip.component';
import { VolunteersComponent } from './volunteers/volunteers.component';
import { PostShowMerchComponent } from './post-show-merch/post-show-merch.component';

@NgModule({
  declarations: [
    RegisterComponent,
    LogoComponent,
    Top20Component,
    GolfcartComponent,
    BulkwaterComponent,
    RvparkingComponent,
    ProductCardComponent,
    SpinnerComponent,
    MainComponent,
    PacketComponent,
    FormsComponent,
    HotelComponent,
    ShowSuperComponent,
    CatalogAdsComponent,
    VendorsComponent,
    OpeningComponent,
    MeetingComponent,
    JuniorsComponent,
    GroomingComponent,
    SeatingComponent,
    ParkingComponent,
    MealsComponent,
    HealthComponent,
    SeminarsComponent,
    AuctionsComponent,
    WorkingDogForumComponent,
    RescueComponent,
    HonorsComponent,
    LivingLegendsComponent,
    ImageUploadComponent,
    FileDropDirective,
    CartingComponent,
    DraftComponent,
    CgcComponent,
    PostShowComponent,
    DirectoryComponent,
    LookupDogComponent,
    LovettComponent,
    InternationalComponent,
    TrophiesComponent,
    VintageComponent,
    VipComponent,
    VolunteersComponent,
    PostShowMerchComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ShoppingRoutingModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatRadioModule,
    NgbModule
  ],
  providers: [ImageService, NgbActiveModal],
  exports: [
    SpinnerComponent
  ]
})
export class ShoppingModule { }
