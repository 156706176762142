<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025LivLegForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-6 col-s-12">
      <h3>LIVING LEGENDS</h3>
      <p>The Parade of Legends is a celebration of our beautiful seniors. Each Newfoundland will be introduced with a brief bio and presented with a NCA Living Legend award. This award is presented to dogs/bitches of any NCA member/breeder that has achieved the venerable age of 10 years or more by the date of the National Specialty. Previous honorees are welcome to register and participate. Newfs need not be present to be recognized, but owners who do not pick up their materials at the show will be assessed additional handling and shipping charges. No items will be mailed until all shipping fees are received. The event will be held during the lunch break on Wednesday, May 15th. The fee is $25 or $40 if you wish your medallion, poster, and catalog to be mailed.  There will be a drawing for a gift basket at the conclusion of the parade as well as gift bags for each participant present. No gift bags will be shipped.</p>
      <p><em>Deadline: {{ deadline }}</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
      <h2 class="mt-3 float-none">Online Living Legends Entry Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
          <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
          <form [formGroup]="legendsForm" id="legendsForm" #lf="ngForm">
            <div class="col-md-12">
              <app-lookup-dog #lookupdog (dogFound)="selectedDog($event)" [formDisabled]="productNotAvailable"></app-lookup-dog>
              <p><span class="text-lg-left text-dark">Selected Dog:</span> <span>{{ getRegisteredName() }}</span></p>
              <div *ngIf="theDogSelected">
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Phone Number" formControlName="phone">
                    <mat-error *ngFor="let validation of accountValidationMessages.phone">
                      <mat-error class="error-message"
                                 *ngIf="legendsForm.get('phone').hasError(validation.type) && (legendsForm.get('phone').dirty || legendsForm.get('phone').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Email Address" formControlName="emailAddress">
                    <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                      <mat-error class="error-message"
                                 *ngIf="legendsForm.get('emailAddress').hasError(validation.type) && (legendsForm.get('emailAddress').dirty || legendsForm.get('emailAddress').touched)">
                        {{validation.message}}
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-row">
                  <mat-form-field class="inputField-full-width">
                    <input matInput placeholder="Name of the person who will pick up your medallion"
                           formControlName="pickup">
                  </mat-form-field>
                </div>
                <div class="form-row mb-3">
                  <mat-form-field class="inputField-half-width">
                    <mat-label>Do you want to pickup your medallion or have it mailed to you</mat-label>
                    <mat-select formControlName="isPickingUp" placeholder="Picking Up?">
                      <mat-option value="pickedUp">Picked Up</mat-option>
                      <mat-option value="mailed">Mailed to me</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-row text-center mx-auto col-md-10 d-block">
                  <h3 class="text-start">Upload your Dog Image.</h3>
                  <p class="text-start">Maximum file size = 4MB. Supported file types: JPG, PNG, and PDF.</p>
                  <div>
                    <app-image-upload-custom [max]="1" class="customUploadClass"
                                             [extensions]="['image/jpg','image/png','image/jpeg','application/pdf']"
                                             [url]="'https://images.ncanewfs.org/blob_upload'"
                                             [registrationNumber]="getRegistrationNumber()"
                                             [buttonCaption]="'Select Dog Image'"
                                             (uploadFinished)="onUploadFinished($event)"
                                             [dropBoxMessage]="'Drop an image of your dog here.'"></app-image-upload-custom>
                    <p class="text-danger fw-bold" *ngIf="uploadFailed">{{ uploadResponse }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 text-center">
                    <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">Add to cart</button>
                    <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
                    <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                    <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <div class="col-md-12 col-sm-12 mx-auto">
          <div class="wrapper">
            <div class="overlay" *ngIf="showSpinner">
              <div class="spinner-wrapper">
                <app-spinner></app-spinner>
              </div>
            </div>

            <div class="loaded-content" [class.blurred]="showSpinner">
              <h3 class="text-center">New this year!</h3> <p>If your Living Legend is not able to join us at the ceremony, or if you want to share your pride in sharing your life with one of these special dogs, you can order a special Living Legends t-shirt - wear it to the ceremony and celebrate your seniors!</p>
              <div class="card prodCard" *ngFor="let item of merchitems">
                <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
              </div>
              <div *ngIf="noMerchAvailable">
                <p>No products available for sale at this time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalImageBrowser let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div style="flex-direction: row;display: inline-flex;width: 100%;">
      <h2 class="modal-title">Product Image Browser</h2>
      <button type="submit" (click)="d('Close click')" class="close"><span>X</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of images">
          <div class="picsum-img-wrapper text-center">
            <img [src]="image" alt="Random slide">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="d('Close click')" class="btn btn-round-outline">Close</button>
    </div>
  </div>
</ng-template>
