<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1 disabled d-none" aria-disabled="true" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-md-12">
      <h3>RV CAMPING &amp; PARKING</h3>
      <p>For those who wish to stay in their RV, there are three options for parking available to you.</p>
		<p>1. DECC Lot - (350 Harbor Drive - 0.4 miles from show site across 5th Ave West frontage road) <br>
			8 spots with 30 and 50 amp electric hookup, onsite dump and water Cost of weekly rental is $850 <br>
			8 spots without electric, onsite dump and water Cost of weekly rental is $650<br>
			Reservations through NCA Online Only Starting January 4th, 2025 at 7AM Pacific time/10AM Eastern time
			Sunday to Saturday rental only and includes a 2-seat golf cart with the reservation. </p>
		<p>NEARBY CAMPING</p>
		<p>2. Lakehead Boat Basin (1000 Minnesota Ave - 1.4 miles from show site) 218-722-1757 <a href="https://www.lakeheadboatbasin.com">www.lakeheadboatbasin.com</a><br>
			Full hookups on the pier<br>
			Reservations made directly with the park (218) 722-1757.</p>
		<p>3. Indian Point Campground (7000 Pulaski St - 6.6 miles south of show site) 218-628-4977 <a href="https://www.duluthindianpointcampground.com">www.duluthindianpointcampground.com</a><br>
			Full-service campground - showers/laundry/dump station<br>
			Reservations made directly with the park (218) 628-4977.</p>
		<p class="d-none">DAYTIME RV PARKING</p>

      <p><em>Deadline {{ deadline }}. </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
      <h2 class="mt-3 float-none">Online RV Parking Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-8 col-sm-12">
            <form id="parkForm" [formGroup]="parkForm" #lf="ngForm">
              <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</h5>
              <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
              <p>Yes, I want to order reserved RV Parking for the 2025 National Specialty</p>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Select a type of parking</mat-label>
                  <mat-select formControlName="parkType" placeholder="Type of Parking" (selectionChange)="changeParkingType($event)">
                    <mat-option *ngFor="let parkType of parkTypes" [value]="parkType.id">{{ parkType.optionName }}</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.parkType">
                    <mat-error class="error-message" *ngIf="parkForm.get('parkType').hasError(validation.type) && (parkForm.get('parkType').dirty || parkForm.get('parkType').touched)">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Arrival Day/Date" formControlName="arrival">
                  <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('arrival').hasError(validation.type) && (parkForm.get('arrival').dirty || parkForm.get('arrival').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Departure Day/Date" formControlName="departure">
                  <mat-error *ngFor="let validation of accountValidationMessages.departure">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('departure').hasError(validation.type) && (parkForm.get('departure').dirty || parkForm.get('departure').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="type" placeholder="Type of Vehicle:">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="length" placeholder="Length of Vehicle:">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="addVehicle" placeholder="Additional Vehicle: (Y/N)">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="addVehicleLength" placeholder="Length of Additional Vehicle:">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="name" placeholder="Name of Parking Buddy">
                </mat-form-field>
              </div>
              <div class="form-row mt-3 mb-0">
                <div class="col-md-6">
                  Total Cost: {{ orderTotal | currency }}
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">Add to cart</button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
