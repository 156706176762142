import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RollbarService } from './rollbar.service';
import * as Rollbar from 'rollbar';

@Injectable()
export class ApiService {
  public headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  public formHeaders: HttpHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

  public API_URL = environment.server;
  public DOG_DB_API_URL = environment.dogDbServiceUrl;
  public authToken: string;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private _http: HttpClient,
  ) {
    this.authToken = '';
  }

  public get(path: string): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', this.authToken) :
      this.headers;
    return this._http.get(
      `${this.API_URL}${path}`,
      {headers: authHeaders}
    );
  }

  public getDog(path: string): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', this.authToken) :
      this.headers;
    return this._http.get(
      `${this.DOG_DB_API_URL}${path}`,
      {headers: authHeaders}
    );
  }

  public post(path, body): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', this.authToken) :
      this.headers;
    return this._http.post(
      `${this.API_URL}${path}`,
      JSON.stringify(body),
      {headers: authHeaders}
    );
  }

  public put(path, body): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', this.authToken) :
      this.headers;
    return this._http.put(
      `${this.API_URL}${path}`,
      JSON.stringify(body),
      {headers: authHeaders}
    );
  }

  public putForm(path, payload): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.formHeaders.append('Authorization', this.authToken) :
      this.formHeaders;
    return this._http.put(
      `${this.API_URL}${path}`,
      payload,
      {headers: authHeaders}
    );
  }

  public delete(path: string): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', this.authToken) :
      this.headers;
    return this._http.delete(`${this.API_URL}${path}`, {headers: authHeaders});
  }

  public setHeaders(headers) {
    // console.log('headers' + this.headers + 'headers');
    Object.keys(headers)
      .forEach((header) => this.headers.set(header, headers[header]));
  }

  public setAuthToken(value) {
    this.authToken = value;
    // console.log('set authtoken to: ' + this.authToken);
  }

  private _checkForError(resp: any): Response {
    console.log(resp);
    if (resp.statusText === 'OK') {
      return resp;
    } else {
      const error = new Error(resp.statusText);
      error['response'] = resp;
      console.error(error);
      throw error;
    }
  }
}
