<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <div class="text-center mb-3 mx-4 float-end">
        <img class="logo-center-image" src="/assets/2025/smallFullColorWhiteBgLogo.jpg"/>
      </div>
      <p class="text-center mt-3"><strong>Monday April 28, 2025- Saturday May 3, 2025</strong><br/>
        <strong> Crowne Plaza Hotel ●
          Warwick, Rhode Island</strong></p>
      <p class="text-center"><a class="btn btn-info quick_links_action ms-1" href="https://www.facebook.com/2025National">Follow Us on Facebook™</a></p>
      <p class="text-center"><a class="btn btn-info quick_links_action ms-1" href="	https://lp.constantcontactpages.com/su/1OXWRNe/national">Subscribe to the Daily News</a></p>
      <p class="text-center"><a class="btn btn-info quick_links_action ms-1" href="https://www.facebook.com/groups/ncanationalspecialty">Facebook™ discussion group: NCA
        National Specialty</a></p>
      <p>For up to the minute TEXT Alerts - Text "NEWF" to 360-564-5808 message and data rates may apply.</p>

      <h3>The 2025 NCA National Specialty Rich History - Bright Future</h3><br/>
      <p>The <em>Newfoundland Club of New England</em> is honored to host the 2025 NCA National Specialty, as we return to Warwick, Rhode Island. The Crowne Plaza Hotel is our home base from April 28 – May 3, 2025 where the staff is looking forward to the return of the Newfs for a 6th time since 1992! The hotel is fully accessible, with easy access to T.F. Green Airport and is minutes off I-95. Warwick is 10 minutes south of Providence and located within an easy drive from Boston, Connecticut’s casinos, Mystic Seaport, Rhode Island’s beautiful beaches, and the mansions of Newport. Many tourist locations in this area are dog friendly!
Clubs from up and down the east coast are hosting the draft tests, obedience and rally trials, and NCNE is grateful for their assistance with these events! NCNE members are planning some new events to highlight the Newfs, breeders and those individuals who have been instrumental throughout the history of the breed. From the first draft test on Monday to the crowning of Best of Breed on Saturday, you will share a memorable week with friends old and new as we celebrate our <strong><em>Rich History</em></strong> and look forward to a <strong><em>Bright Future</em></strong> with our beloved Newfoundland dogs!
</p>
      <div class="row mx-auto mb-2">
        <div class="col-md-7">
          <p>We look forward to seeing you Rhode Island!</p>
          <p class="text-end"><strong><em>Donna Thibault and Mary Bylone, Co-Chairs</em></strong></p>
        </div>
      </div>
      <ngb-accordion #acc="ngbAccordion" activeIds="collapseOne">
        <ngb-panel [disabled]="true" title="Schedule of Events (coming soon)">
          <ng-template ngbPanelContent>
            <ngb-accordion #acc="ngbAccordion" activeIds="inner-panel-0">
              <ngb-panel title="Sunday May 12">
                <ng-template ngbPanelContent>
                  3:00 p.m. - Reserved Parking, Reserved Handler Parking, Golf Carts and Reserved Grooming areas available
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Monday May 13">
                <ng-template ngbPanelContent>
                  9:00 a.m. - Draft Test #1 - NCNC<br>
                  9:00 a.m. - 3:00 p.m. - Registration
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Tuesday May 14">
                <ng-template ngbPanelContent>
                  9:00 a.m. - Regional Obedience and Rally Trial – CNC – Ring 2&3<br>
                  9:00 a.m. - Draft Test #2 - NCNC<br>
                  9:00 a.m. - 3:00 p.m. - Registration<br>
                  1:00 p.m. - 4 – 6 mos. Beginner Puppy Judging – Ring 1<br>
                  2:30 p.m. – Pee Wee Handling – Ring 1<br>
                  3:30 p.m. – Opening Ceremony/Regional Club Parade – Ring 1<br>
                  5:00 p.m. - Welcome Reception – Pier B Ballroom<br>
                  6:00 p.m. - NCA Honors and Annual Meeting – Pier B Ballroom<br>
                  7:00 p.m. – Westminster Watch Party – Pier B Ballroom<br>
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Wednesday May 15">
                <ng-template ngbPanelContent>
                  9:00 a.m. - National Obedience and Rally Trials – Ring 2&3<br>
                  9:00 a.m. - Puppy and Veteran Sweepstakes – Ring 1<br>
                  9:00 a.m. - 3:00 p.m. - Registration<br>
                  Lunch Break - Living Legends – Ring 1<br>
                  2:30 p.m. – Working Dog Seminar – Draft Equipment – Ring 2<br>
                  4:00 p.m. - Specialty Carting and Wagon Exercises – Ring 3<br>
                  7:00 p.m. - Breeders Education Program – Pier B Ballroom<br>
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Thursday May 16">
                <ng-template ngbPanelContent>
                  9:00 a.m. – Conformation Judging – Dog classes, Brace and Team – Ring 1<br>
                  5:00 p.m. – Top 20 Judging – Ring 1<br>
                  7:30 p.m. – Top 20/10 Cocktails and Hors d’oeuvres - Pavilion<br>
                  8:30 p.m.(dusk) – Top 20/10 Aurora Borealis Ball - Pavilion<br>
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Friday May 17">
                <ng-template ngbPanelContent>
                  Canine Cardiologist and Ophthalmology exams by appointment<br>
                  9:00 a.m. – Conformation Judging – Bitch classes, Breeders Class – Ring 1<br>
                  10:00 a.m. - CGC Evaluations – Ring 2<br>
                  12:00 p.m. - Trick Dog Testing – Ring 3<br>
                  2:30 p.m. – Honors Parade – Ring 2<br>
                  4:00 p.m. – Rescue Parade – Ring 3<br>
                  6:30 p.m. – Banquet, Raffles and Auction – Great Lakes Aquarium<br>
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Saturday May 18">
                <ng-template ngbPanelContent>
                  8:30 a.m. –– Jr Showmanship - Ring 1<br>
                  9:30 a.m. – Check in for all Best of Breed entries – Ring 1<br>
                  Lunch break at the discretion of the judge<br>
                  Ringside Reception – Conclusion of Breed Judging – Ring 1<br>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </ngb-panel>
        <ngb-panel title="The 2025 National Specialty Judging Panel">
          <ng-template ngbPanelContent>
            <p>Judging Panel: <br>
             Bill Matlock.....4-6 Month Puppies, Pee-Wee Handling<br>
			Katherine Griffin....Sweepstakes Dogs, and Veteran Sweepstakes Dogs<br>
			Carol Bergmann....Sweepstakes Bitches and Veteran Sweepstakes Bitches<br>
			Kimberly Meredith...Dog Classes and Maturity	<br>
			Carl Liepmann...Bitch Classes<br>
			Patricia Trotter...Junior Showmanship and Best of Breed <br>
			Darwin Boles and Lisa Day....Obedience and Rally
            </p>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <div class="d-none"><h2>Show Headquarters and Hospitality</h2>
      <p>Show Headquarters, located in the Family Center at the front entrance to Bayfront Festival Park, is the place to come in the morning for COMPLIMENTARY coffee, tea, pastries, and fresh fruit. It will be a warm place to come inside, meet friends old and new, and stay “connected” with what is going on at the show.</p>
      <p>A wide variety of local food trucks will be onsite to provide breakfast and lunch menus in the Park with seating under the Paulucci Pavilion throughout the week. Many casual and fine dining establishments can be found in the Canal Park district within walking distance of Bayfront Festival Park. Look for dining options in your Registration Bags!</p>
      <h2>Shoppers Row</h2>
      <p>For 2025, we have expanded our shopping opportunities and consolidated everything into one grand location, SHOPPERS ROW, to better serve your shopping experience! All the vendors, charitable raffles, Regional Club Baskets, and any logo item sales will be located under tents adjacent to the grooming tent. We are working with a local shipping company to package and ship items home for you in case your suitcase is full!</p>
      <h2>Deadlines</h2>
      <p>Note: Draft test entries will open and close as published in the draft test premium list. Do not send Draft test
        entries to Show Superintendent</p></div>
    </div>

    <ngb-accordion #acc="ngbAccordion" activeIds="deadline-panel-0" class="d-none">
      <ngb-panel class="d-none" title="December 1, 2022 Deadlines for:">
        <ng-template ngbPanelContent>
          Uniform Trophy Donations<br>
          Premium List Information<br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="January 10, 2023:">
        <ng-template ngbPanelContent>
          First Postmark Date - Hotel Reservations<br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="January 15, 2023:">
        <ng-template ngbPanelContent>
          Catalog Ads Open<br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="February 15, 2023:">
        <ng-template ngbPanelContent>
          Deadline For: Catalog Ads<br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="February 28, 2023:">
        <ng-template ngbPanelContent>
          First Postmark Date for Mail-In Order Forms<br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="March 1, 2023:">
        <ng-template ngbPanelContent>
          Online Store opens for Reservations and Registrations
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="March 15, 2023:">
        <ng-template ngbPanelContent>
          Deadline for: Living Legends, RV Camping/RV Daytime Parking
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="April 1, 2023:">
        <ng-template ngbPanelContent>
          Deadlines for: Volunteer Form<br>
          Reserved Handler Parking<br>
          Golf Cart Reservations<br>
          Special Award Donations<br>
          Post Show Catalog Purchases<br>
          Reserved Grooming Spaces<br>
          Bottled Water Orders<br>
          CGC Test Registration<br>
          Trick Dog Test Registration<br>
          Working Dog Forum RSVP’s<br>
          Specialty Carting and Wagon Exercises Entries<br>
          National Specialty Registrations<br>
          Rescue Ceremony Entries<br>
          Honors Parade Entries<br>
          Poisons and Your Pet Seminar RSVP's<br>
          Breeder’s Education Seminar RSVP’s<br>
          Auction and Brown Bag Raffle Donation Forms<br>
          Meal Reservations<br>
          Top 20/10 RSVP’s<br>
          Vendor Contracts <br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="April 12, 2023:">
        <ng-template ngbPanelContent>
          AKC Conformation, Obedience and Rally Entries close at MB-F before 12:00 EDT (www.infodog.com)<br>
          Mike and Sandee Lovett Memorial Working Challenge Entry<br>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="d-none" title="April 15, 2023:">
        <ng-template ngbPanelContent>
          Last Date to cancel Room Reservations at the Bavarian Inn for full refund.<br>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
