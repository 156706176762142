<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025VendorForm.pdf" download="">Download
          Vendor Signup Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11">
      <h3>VENDORS</h3>
      <p>Our vendors will find themselves in the heart of the action in the area, directly adjacent to the show’s
outdoor grooming tent, which we have named SHOPPERS ROW. Two booth sizes are available: 20’x20’ - $660 or 10’x10’ - $350,
plus a donated auction item valued at $50.00 or greater. Each space includes one table and two chairs. Tent sides are available
for an additional $15/$30 per side. Each paid vendor contract for 2025 is entitled to 3 free ads promoting their goods/services
on the NCA National Specialty Facebook page and may provide marketing materials for inclusion in the 2025 Registration bags
that will include a vendor directory and map.</p>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12">

            <form id="vendorForm" [formGroup]="vendorForm" #lf="ngForm">
              <p class="mt-4">Yes, I am interested in being a vendor at the 2025 National Specialty<br/>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="vendorForm.get('name').hasError(validation.type) && (vendorForm.get('name').dirty || vendorForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="vendorForm.get('address').hasError(validation.type) && (vendorForm.get('address').dirty || vendorForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="vendorForm.get('emailAddress').hasError(validation.type) && (vendorForm.get('emailAddress').dirty || vendorForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Daytime Phone" formControlName="dayphone">
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Evening Phone" formControlName="eveningphone">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Are you an NCA Member?</mat-label>
                  <mat-select formControlName="isMember" placeholder="NCA Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                    <mat-error class="error-message" *ngIf="vendorForm.get('isMember').hasError(validation.type) && (vendorForm.get('isMember').dirty || vendorForm.get('isMember').touched)">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Please share the type of item you will be selling" formControlName="itemType">
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-12 col-sm-12 text-center">
                  <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action me-5" [disabled]="formDisabled">Send Form</button>Vendor signup deadline is April 15th, 2025
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
