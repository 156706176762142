import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-packet',
  templateUrl: './packet.component.html',
  styleUrls: ['./packet.component.scss']
})
export class PacketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
