import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import * as moment from 'moment-timezone';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-vintage',
  templateUrl: './vintage.component.html',
  styleUrls: ['./vintage.component.scss']
})
export class VintageComponent implements OnInit, OnDestroy {

  @ViewChild('modalImageBrowser', { static: true }) modalImageBrowser?: NgbModal;

  items: any[];
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  images = [];

  private unsubscribe$ = new Subject<void>();

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private api: ApiService,
              private cartService: CartService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.showSpinner = true;
    this.api.get('/products?category=1&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.items = res.products;
                const prod = res.products[0];
                this.showSpinner = false;
                if (prod.hasOwnProperty('committee')) {
                  const theCommittee = prod.committee;
                  this.committeeChairEmail = theCommittee.contactEmail;
                  this.committeeChairName = theCommittee.contactName;
                }
                if (prod.hasOwnProperty('deadline')) {
                  this.deadlineTime = prod.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (prod.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = prod.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (prod.hasOwnProperty('soldOut')) {
                  if (prod.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of logo merchandise.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });


  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openImageBrowser(variantImageArray) {
    this.images = variantImageArray.map((variantImage) => variantImage.path);
    this.modalService.open(this.modalImageBrowser, { windowClass: 'modal-preview', centered: true });
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  trackByIndexItem(index, item): any {
    return index;
  }

  downloadForm() {
  }
}
