import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import * as moment from 'moment-timezone';
import { ProductService } from '../../services/product.service';
import { CartItem } from '../../models/cart';
import { CartService } from '../../services/cart.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { SponsorModalComponent } from '../../sponsor-modal/sponsor-modal.component';

@Component({
  selector: 'app-trophies',
  templateUrl: './trophies.component.html',
  styleUrls: ['./trophies.component.scss']
})
export class TrophiesComponent implements OnInit, OnDestroy {

  trophyRegularItems: any[];
  trophyNonRegularItems: any[];
  trophySweepsItems: any[];
  trophyBeginnerPuppyItems: any[];
  trophyObedienceItems: any[];
  trophyRallyItems: any[];
  trophyJuniorsItems: any[];
  private unsubscribe$ = new Subject<void>();
  showSpinner: boolean;
  showSpinner2: boolean;
  showSpinner3: boolean;
  showSpinner4: boolean;
  showSpinner5: boolean;
  showSpinner6: boolean;
  showSpinner7: boolean;
  errorModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  pastDeadline = false;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  addingSpinner: boolean;
  myProductIsChanging: boolean;
  localCart: any;
  isMember = false;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private api: ApiService,
              private cartService: CartService,
              private productService: ProductService,
              private googleService: GoogleAnalyticsEventsService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.addingSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
    this.cartService.getCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.hasOwnProperty('id')) {
          this.localCart = res;
          if (this.localCart.hasOwnProperty('customer')) {
            this.isMember = this.localCart.customer.waId != null;
          } else {
            this.isMember = false;
          }
        } else {
          this.isMember = false;
        }
      });
    this.showSpinner = true;
    this.api.get('/products?category=37&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophyRegularItems = res.products;
                const prod = res.products[0];
                if (prod.hasOwnProperty('deadline')) {
                  this.deadlineTime = prod.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (prod.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = prod.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                    this.pastDeadline = true;
                  }
                } else if (prod.hasOwnProperty('soldOut')) {
                  if (prod.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophies.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.showSpinner2 = true;
    this.api.get('/products?category=38&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner2 = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophyNonRegularItems = res.products;
                const prod = res.products[0];
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner2 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophy products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.showSpinner3 = true;
    this.api.get('/products?category=39&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner3 = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophySweepsItems = res.products;
                const prod = res.products[0];
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner3 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophy products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.showSpinner4 = true;
    this.api.get('/products?category=40&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner4 = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophyBeginnerPuppyItems = res.products;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner4 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophy products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.showSpinner5 = true;
    this.api.get('/products?category=41&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner5 = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophyObedienceItems = res.products;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner5 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophy products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.showSpinner6 = true;
    this.api.get('/products?category=42&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner6 = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophyRallyItems = res.products;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner6 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophy products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.showSpinner7 = true;
    this.api.get('/products?category=43&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner7 = false;
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.trophyJuniorsItems = res.products;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner7 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of trophy products.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/48')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  isSoldOut(inItem: any) {
    if (this.pastDeadline) {
      return true;
    }
    if (inItem.variants.length === 0) {
      return true;
    } else {
      return inItem.variants[0].soldOut === 1;
    }
  }
  getPrice(inItem: any) {
    let basePrice = 0.0;

    const curtime = new Date().getTime();
    inItem.base_prices.forEach((aPrice) => {
      if (((aPrice.endDate === null) || (aPrice.endDate > curtime)) && (curtime >= aPrice.startDate)) {
        basePrice = parseFloat(aPrice.basePrice);
      }
    });
    return basePrice;
  }
  addToCart(inItem: any) {
    const modalRef = this.modalService.open(SponsorModalComponent, {size: 'lg'});
    modalRef.componentInstance.sponsorship = '';
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.finishCheckout(inItem, receivedEntry);
      modalRef.close();
    });
  }
  finishCheckout(inItem: any, sponsorship: string) {
    const body = {items: []};
    if (inItem.variants.length > 0) {
      const prodVarId = inItem.variants[0].id;
      let sponsorString = '{}';
      if (sponsorship.length > 0) {
        sponsorString = JSON.stringify({dedication: sponsorship});
      }
      const product = {
        productId: inItem.id,
        productVariantId: prodVarId,
        quantity: 1,
        dataPoints: sponsorString
      };
      this.myProductIsChanging = true;
      this.addingSpinner = true;
      this.cartService.addItemToCart(product);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', inItem.name + ':' + prodVarId, 1);
    }
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
