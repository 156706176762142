import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopComponent } from './shop/shop.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { MainComponent } from './shopping/main/main.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'shop', pathMatch: 'full'
  },
  {
    path: 'shop', component: ShopComponent,
    children: [
      {
        path: '', component: MainComponent
      }
    ]
  },
  {
    path: 'thanks', component: ThankyouComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
