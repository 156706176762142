import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { NatSpecCommittee } from '../models/committees';

@Injectable()
export class CommitteeService {
  constructor(private api: ApiService,
              private http: HttpClient) {
  }

  public getCommittees(): Observable<any> {
    return this.api.get('/committees');
  }
  public updateCommittee(body: NatSpecCommittee, committeeId: number): Observable<any> {
    return this.api.put('/committees/' + committeeId, body);
  }
  public newCommittee(body: any): Observable<any> {
    return this.api.post('/committees', body);
  }

}
