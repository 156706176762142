import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-top20',
  templateUrl: './top20.component.html',
  styleUrls: ['./top20.component.scss']
})
export class Top20Component implements OnInit, OnDestroy {

  @ViewChild('modalImageBrowser', { static: true }) modalImageBrowser?: NgbModal;
  @ViewChild('lf', {static: true}) myForm: NgForm;
  items: any[];
  celebItems: any[];
  private unsubscribe$ = new Subject<void>();
  private unsubscribe2$ = new Subject<void>();
  showSpinner: boolean;
  showSpinner2: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  celebrationCommitteeChairEmail = 'dan@dogfishsoftware.com';
  celebrationCommitteeChairName = 'Dan Zimmerman'
  deadline = '';
  deadline2 = '';
  deadlineTime = 0;
  deadlineTime2 = 0;
  productNotAvailable = true;
  productNotAvailable2 = true;
  noProductsAvailable = false;
  soldOut = false;
  soldOut2 = false;
  comingSoon = false;
  comingSoon2 = false;
  availabilityDate = '';
  availabilityDate2 = '';
  availabilityDateTime = 0;
  availabilityDateTime2 = 0;
  images = [];
  images2 = [];
  formDisabled: boolean;
  topdogForm: FormGroup;
  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ]
  };

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private fb: FormBuilder,
              private api: ApiService,
              private cartService: CartService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.topdogForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]]
    });
    this.formDisabled = true;
    this.showSpinner = true;
    this.api.get('/products?category=3&variants=true')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.items = res.products;
                const prod = res.products[0];
                this.showSpinner = false;
                if (prod.hasOwnProperty('deadline')) {
                  this.deadlineTime = prod.deadline;
                  this.deadline = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (prod.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime = prod.availabilityDate;
                  this.availabilityDate = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable = false;
                  } else {
                    this.comingSoon = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable = false;
                    this.comingSoon = false;
                  } else {
                    this.comingSoon = false;
                  }
                } else if (prod.hasOwnProperty('soldOut')) {
                  if (prod.soldOut) {
                    this.soldOut = true;
                  } else {
                    this.productNotAvailable = false;
                  }
                } else {
                  this.productNotAvailable = false;
                }
                this.noProductsAvailable = false;
              } else {
                // no products to show:
                this.showSpinner = false;
                this.noProductsAvailable = true;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of Top 20 merchandise.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/42')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.showSpinner2 = true;

    this.api.get('/products?category=34&variants=true&orderBy=baseSkuNumber')
      .pipe(takeUntil(this.unsubscribe2$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            if (res.hasOwnProperty('products')) {
              if (res.products.length > 0) {
                this.celebItems = res.products;
                const prod = res.products[0];
                this.showSpinner2 = false;
                if (prod.hasOwnProperty('deadline')) {
                  this.deadlineTime2 = prod.deadline;
                  this.deadline2 = moment(this.deadlineTime).format('dddd, MMMM Do YYYY');
                }
                if (prod.hasOwnProperty('availabilityDate')) {
                  this.availabilityDateTime2 = prod.availabilityDate;
                  this.availabilityDate2 = moment(this.availabilityDateTime).format('dddd, MMMM Do YYYY h:mm A z');
                }
                // now check availability
                if (moment().isBefore(moment(this.availabilityDateTime))) {
                  // the product is not available yet
                  // check if the environment allows early ordering - testing
                  if (environment.testProductMode) {
                    this.productNotAvailable2 = false;
                  } else {
                    this.comingSoon2 = true;
                  }
                } else if (moment().isAfter(moment(this.deadlineTime))) {
                  // the product is no longer available
                  if (this.cartService.isMaintainer) {
                    this.productNotAvailable2 = false;
                    this.comingSoon2 = false;
                  } else {
                    this.comingSoon2 = false;
                  }
                } else if (prod.hasOwnProperty('soldOut')) {
                  if (prod.soldOut) {
                    this.soldOut2 = true;
                  } else {
                    this.productNotAvailable2 = false;
                  }
                } else {
                  this.productNotAvailable2 = false;
                }
              } else {
                // no products to show:
                this.showSpinner2 = false;
                this.noProductsAvailable = true;
              }
            }
          });
        },
        () => {
          this.ngZone.run(() => {
            this.showSpinner2 = false;
            setTimeout(() => {
              this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
              this.errorModalRef.componentInstance.errorMessage = 'read the list of Top 20 celebration items.';
              this.errorModalRef.componentInstance.longErrorMessage = '';
            }, 500);
          });
        });
    this.api.get('/committees/43')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.celebrationCommitteeChairEmail = res.contactEmail;
              this.celebrationCommitteeChairName = res.contactName;
            }
          })
        });
  }

  sendForm() {
    if (this.topdogForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 45,
        formData: {
          fullName: this.topdogForm.controls.name.value,
          emailAddress: this.topdogForm.controls.emailAddress.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.topdogForm.reset();
      this.myForm.resetForm();
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Top Dog Celebration coordinator.' +
        '  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe2$.next();
    this.unsubscribe2$.complete();
  }
  openImageBrowser(variantImageArray) {
    this.images = variantImageArray.map((variantImage) => variantImage.path);
    this.modalService.open(this.modalImageBrowser, { windowClass: 'modal-preview', centered: true });
  }

  trackByIndexItem(index, item): any {
    return index;
  }

  downloadForm() {
  }
}
