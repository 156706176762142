<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025DraftForm.pdf" download="">Download Info Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>DRAFT TESTS</h3>
      <p>The North Central Newfoundland Club will host back-to- back draft tests starting on Monday, May 13th &amp; Tuesday, May 14th.&nbsp;Contacts: Kristin Julcher, Test Chair, or Damian Burke, Test Secretary. DO NOT send draft test entries to Show Superintendent MB-F. </p>
      <p><br>
        Draft test entries will be offered using the Working Dog Online Entry System.  You can download the Premium lists and enter the test by clicking on the button below.</p>
      <p>Entries will open and close as published in the Draft Test Premium Lists.</p>
      <p>To receive a Draft Test Premium List, contact Damian Burke, Secretary • <a href="mailto:smeed1@mac.com">smeed1@mac.com</a> • (815) 703-0036</p>
      <p><a class="btn btn-info quick_links_action ms-1" href="https://workingentry.ncadogs.org/" target="_blank">
        Enter Online</a></p>
      <p><strong> <em>DO NOT send draft test entries to show superintendent.</em></strong></p>
    </div>
  </div>
</div>
