<div class="modal-header">
  <h2>Customer Shipping Information</h2>
  <button style="margin-bottom: 10px;" type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  </button>
</div>
<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <div class="modal-body">
      <form id="initMemberRegForm" [formGroup]="memberForm">
        <ng-container *ngIf="!confirmAddress">
        <h3>NCA Member Login</h3>
        <p>Please enter the Email Address and Password that you use to access the NCA Membership Portal.
        If you do not remember your login information or have never setup an account on the NCA Membership Portal,
        please click the link below to setup your password.</p>
        <div class="row align-items-center">
          <div class="col-md-5">
            <div class="form-floating">
              <input type="email" class="form-control" id="emailMember" name="emailMember" formControlName="emailMember" placeholder="Email">
              <label for="emailMember">Email Address</label>
              <mat-error *ngFor="let validation of accountValidationMessages.emailMember">
                <mat-error class="mat-error error-message"
                           *ngIf="memberForm.get('emailMember').hasError(validation.type) && (memberForm.get('emailMember').dirty || memberForm.get('emailMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-floating">
              <input type="password" class="form-control" id="pwdMember" name="pwdMember" formControlName="pwdMember" placeholder="Password">
              <label for="pwdMember">Password</label>
              <mat-error *ngFor="let validation of accountValidationMessages.pwdMember">
                <mat-error class="mat-error error-message"
                           *ngIf="memberForm.get('pwdMember').hasError(validation.type) && (memberForm.get('pwdMember').dirty || memberForm.get('pwdMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-2">
            <button type="button" class="btn btn-info quick_links_action" id="loginMemberButton" (click)="loginMember()" [disabled]="!memberForm.valid">Login</button>
          </div>
        </div>
        <div class="form-row mt-1">
          <div class="col-md-11">
            <a style="color: #0000ff;" href="https://members.ncanewfs.org/Sys/ResetPasswordRequest" target="_blank">Forgot your password or need to create one for the first time?</a>
          </div>
        </div>
        </ng-container>

        <ng-container *ngIf="confirmAddress">
          <h4>Please confirm your shipping address is correct, or correct it here</h4>
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="form-floating">
              <input type="text" class="form-control" id="memaddress" name="memaddress" formControlName="memaddress" placeholder="Your shipping address">
              <label for="memaddress">Your shipping address</label>
              <mat-error *ngFor="let validation of accountValidationMessages.memaddress">
                <mat-error class="mat-error error-message"
                           *ngIf="memberForm.get('memaddress').hasError(validation.type) && (memberForm.get('memaddress').dirty || memberForm.get('memaddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="memcity" name="memcity" formControlName="memcity" placeholder="City">
              <label for="memcity">City</label>
              <mat-error *ngFor="let validation of accountValidationMessages.memcity">
                <mat-error class="mat-error error-message"
                           *ngIf="memberForm.get('memcity').hasError(validation.type) && (memberForm.get('memcity').dirty || memberForm.get('memcity').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="memstate" name="memstate" formControlName="memstate" placeholder="State">
              <label for="memstate">State</label>
              <mat-error *ngFor="let validation of accountValidationMessages.memstate">
                <mat-error class="mat-error error-message"
                           *ngIf="memberForm.get('memstate').hasError(validation.type) && (memberForm.get('memstate').dirty || memberForm.get('memstate').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="memzip" name="memzip" formControlName="memzip" placeholder="Zipcode/Postal Code">
              <label for="memzip">Zipcode/Postal Code</label>
              <mat-error *ngFor="let validation of accountValidationMessages.memzip">
                <mat-error class="mat-error error-message"
                           *ngIf="memberForm.get('memzip').hasError(validation.type) && (memberForm.get('memzip').dirty || memberForm.get('memzip').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-floating">
          <select class="form-select" name="memcountry" id="memcountry" formControlName="memcountry">
            <option *ngFor="let country of countryNames" [ngValue]="country">{{country}}</option>
          </select>
          <label for="memcountry">Country</label>
        </div>
          <button type="button" class="btn btn-info quick_links_action mt-2" id="memberConfirm" (click)="confirmAddressAction()" [disabled]="!memberForm.valid">Submit</button>
        </ng-container>
        <hr>
      </form>
      <ng-container *ngIf="!confirmAddress">
      <form id="initRegForm" [formGroup]="nonMemberForm">
        <h3>Guest Shipping Information</h3>
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" id="first" name="first" formControlName="first" placeholder="First Name">
              <label for="first">First Name</label>
              <mat-error *ngFor="let validation of accountValidationMessages.first">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('first').hasError(validation.type) && (nonMemberForm.get('first').dirty || nonMemberForm.get('first').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" id="last" name="last" formControlName="last" placeholder="Last Name">
              <label for="last">Last Name</label>
              <mat-error *ngFor="let validation of accountValidationMessages.last">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('last').hasError(validation.type) && (nonMemberForm.get('last').dirty || nonMemberForm.get('last').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="form-floating">
              <input type="text" class="form-control" id="address" name="address" formControlName="address" placeholder="Your address">
              <label for="address">Your shipping address</label>
              <mat-error *ngFor="let validation of accountValidationMessages.address">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('address').hasError(validation.type) && (nonMemberForm.get('address').dirty || nonMemberForm.get('address').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="city" name="city" formControlName="city" placeholder="City">
              <label for="city">City</label>
              <mat-error *ngFor="let validation of accountValidationMessages.city">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('city').hasError(validation.type) && (nonMemberForm.get('city').dirty || nonMemberForm.get('city').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="state" name="state" formControlName="state" placeholder="State">
              <label for="state">State</label>
              <mat-error *ngFor="let validation of accountValidationMessages.state">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('state').hasError(validation.type) && (nonMemberForm.get('state').dirty || nonMemberForm.get('state').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="zip" name="zip" formControlName="zip" placeholder="Zipcode/Postal Code">
              <label for="zip">Zipcode/Postal Code</label>
              <mat-error *ngFor="let validation of accountValidationMessages.zip">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('zip').hasError(validation.type) && (nonMemberForm.get('zip').dirty || nonMemberForm.get('zip').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" id="phone" name="phone" formControlName="phone" placeholder="Phone Number">
              <label for="phone">Phone Number</label>
              <mat-error *ngFor="let validation of accountValidationMessages.phone">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('phone').hasError(validation.type) && (nonMemberForm.get('phone').dirty || nonMemberForm.get('phone').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="email" class="form-control" id="email" name="email" formControlName="email" placeholder="Email Address">
              <label for="email">Email Address</label>
              <mat-error *ngFor="let validation of accountValidationMessages.email">
                <mat-error class="mat-error error-message"
                           *ngIf="nonMemberForm.get('email').hasError(validation.type) && (nonMemberForm.get('email').dirty || nonMemberForm.get('email').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </div>
          </div>
        </div>
        <div class="form-floating">
          <select class="form-select" name="country" id="country" formControlName="country">
            <option *ngFor="let country of countryNames" [ngValue]="country">{{country}}</option>
          </select>
          <label for="country">Country</label>
        </div>
        <button type="button" class="btn btn-info quick_links_action mt-2" id="nonMemberSubmit" (click)="loginNonMember()" [disabled]="!nonMemberForm.valid">Submit</button>
      </form>
      </ng-container>
    </div>

  </div>
</div>
